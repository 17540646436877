import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
  { id: 'Sl No.', label: 'Sl No.', minWidth: 50,span: false },
  { id: 'AG code', label: 'AG code', minWidth: 50,span: false },
  { id: 'Country', label: 'Country', minWidth: 50,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 50,
    span: true
  },
];

function Country() {
  const [btnloader, setBtnloader] = React.useState(false);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [country, setCountry] = React.useState("");
  const [countryid, setCountryid] = React.useState("");
  const [source, setSource] = React.useState([]);

  useEffect(() => {
    getCountry()
  }, [])

const getCountry = () => {
    setLoader(true)
  fetch(global.url + "viewCountry", {
    method: "GET",
    headers: {
        'Content-Type': 'application/json',
    },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      setLoader(false)
      if(responseJson.status == "false") {
        setSource(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
}


const onAdd = () => {
  setModal(true)
  setCountry("")
  setCountryid("")
}

const onSave = () =>{
  setBtnloader(true)
  var data = {
    "country_id" : countryid,
    "country_name" : country
  }
  fetch(global.url + "createCountry", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      if(responseJson.error == false) {
        setModal(false)
        alert(responseJson.message)
        setBtnloader(false)
        getCountry()
      }else {
        alert(responseJson.message)
        setBtnloader(false)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}

const editClick = (
  countryid,
  countryname
) => {
  setModal(true)
  setCountryid(countryid)
  setCountry(countryname)
}

  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Country</p>
               <Button onClick={onAdd} variant="contained">Add</Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                            <TableCell >
                                  {index + 1}
                              </TableCell>
                              <TableCell >
                              </TableCell>
                              <TableCell >
                                  {row.country_name}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => editClick(
                                    row.country_id,
                                    row.country_name,
                                  )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={distlist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>


          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Country Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField onChange={(e) => setCountry(e.target.value)} value={country} id="outlined-basic" label="Country Name *" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      {/* <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button> */}
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default Country;
