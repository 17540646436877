import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const columns = [
    { id: '1', label: 'Type', minWidth: 50,span: false },
    { id: '2', label: 'Invoice From', minWidth: 50,span: false },
    { id: '3', label: 'Invoice To', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 50,
      span: true
    },
  ];

function Settings() {

    const [sofrom, setSofrom] = React.useState("")
    const [soto, setSoto] = React.useState("")

    const soReplaceClick = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var data = {
            "from" : sofrom,
            "to" : soto,
            "userid" : userdata.user_id,
            "freezetype" : "freeze"
        }
        fetch(global.url + "freezeSalesorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            // console.log("responseJson", responseJson)
            if(responseJson.error == false){
                alert(responseJson.message)
                window.location.reload()
            }else{
                alert(responseJson.message)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const soReplaceClick2 = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var data = {
            "from" : sofrom,
            "to" : soto,
            "userid" : userdata.user_id,
            "freezetype" : "unfreeze"
        }
        fetch(global.url + "freezeSalesorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            // console.log("responseJson", responseJson)
            if(responseJson.error == false){
                alert(responseJson.message)
                window.location.reload()
            }else{
                alert(responseJson.message)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Settings</p>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                colSpan={column.span == true ? 2: 1}
                            >
                                {column.label}
                                
                            </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                            <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales Order
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={sofrom} onChange={(e) => setSofrom(e.target.value)} style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={soto} onChange={(e) => setSoto(e.target.value)} style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <Button onClick={soReplaceClick} variant="contained">Freeze</Button> 
                                </TableCell>
                                <TableCell >
                                    <Button onClick={soReplaceClick2} variant="contained">Unfreeze</Button> 
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales Order Replace
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained">Apply</Button> 
                                </TableCell>
                            </TableRow> */}
                            {/* <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained">Apply</Button> 
                                </TableCell>
                            </TableRow> */}
                            {/* <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales Replacement
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained">Apply</Button> 
                                </TableCell>
                            </TableRow> */}
                            {/* <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales Return
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField style={{width: 150}} autoComplete="off" type="number"  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained">Apply</Button> 
                                </TableCell>
                            </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>

          </Paper> 
      </div>
    )
}
export default Settings;