import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
  { id: '1', label: 'Sl#', minWidth: 170,span: false },
  { id: '1', label: 'Campaign Number', minWidth: 170,span: false },
  { id: '1', label: 'Campaign Master', minWidth: 170,span: false },
  { id: '2', label: 'AG code', minWidth: 170,span: false },
  { id: '2', label: 'Description', minWidth: 170,span: false },
  { id: '4', label: 'Blocked', minWidth: 170,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    span: true
  },
];

function CampaignNumber(props) {
  const [btnloader, setBtnloader] = React.useState(false);
  const [usertype, setUsertype] = useState("")
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [userId, setUserid] = React.useState("");
  const [source, setSource] = React.useState([]);

  const [campaignNumberid, setCampaignNumberid] = React.useState("");
  const [campaignnumber, setCampaignNumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);
  const [master, setMaster] = React.useState("");
  const [masterlist, setMasterlist] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const masterChange = (event) => {
    setMaster(event.target.value)
  }

  useEffect(() => {
    getData()
    getCampaignMaster()
  }, []);

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsertype(userdata.user_type)
    const data = {
      "companyid": company,
      "branchid": branch
    }
    fetch(global.url + "viewCampaignNumber", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.error == false) {
            setSource(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getCampaignMaster = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "branchid":  branch,
      "companyid": company
    }
    fetch(global.url + "viewCampaignList", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
    
        if(responseJson.error == false){
          setMasterlist(responseJson.data)
        } 
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const editClick = (
    campaignid, 
    campaignnumber,
    description,
    blocked,
    master
    ) => {
      setModal(true)
      setCampaignNumberid(campaignid)
      setCampaignNumber(campaignnumber)
      setDescription(description)
      setBlocked(blocked)
      setMaster(master)
  }

  const onAdd = () => {
    setModal(true)
    setCampaignNumberid("")
    setCampaignNumber("")
    setDescription("")
    setBlocked(0)
  }

  const blockChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }


  

  const onSave = () => {
    setBtnloader(true)
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "companyid" : company,
      "branchid" : branch,
      "campaign_id" : master,
      "campaign_number" : campaignnumber,
      "description" : description,
      "value" : blocked,
      "cd_id" : campaignNumberid
    }
        fetch(global.url + "createCampaignNumber", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {
                getData()
                setModal(false)
                alert(responseJson.message)
                setBtnloader(false)
            }else{
                alert(responseJson.message)
                setBtnloader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }



  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Online Campaign Number</p>
               {
                  usertype == "superadmin" ? 
                  null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell >
                                  {index + 1}
                              </TableCell>
                              <TableCell >
                                  {row.cd_number}
                              </TableCell>
                              <TableCell >
                                  {row.campaign_name}
                              </TableCell>
                              <TableCell >
                                  {row.cd_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.cd_description}
                              </TableCell>
                              <TableCell >
                              {row.cd_blocked == "0" ? "No" : "Yes"}
                              </TableCell>
                              <TableCell >
                              {
                                    usertype == "superadmin" ? 
                                    null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                      editClick(row.cd_id, 
                                        row.cd_number,
                                        row.cd_description,
                                        row.cd_blocked,
                                        row.cd_campaign_fk
                                      )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.size_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Online Campaign Number
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} md={6} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Online Campaign Master</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={master}
                                    label="Age"
                                    onChange={masterChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {masterlist.map((item, index) => (
                                    <MenuItem value={item.campaign_id}>{item.campaign_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setCampaignNumber(e.target.value)} value={campaignnumber} id="outlined-basic" label="Online Campaign Number" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setDescription(e.target.value)} value={description} id="outlined-basic" label="Online Campaign Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => blockChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>

                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button  onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button  onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default CampaignNumber;
