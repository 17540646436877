import '../Tax-Settings/TaxSettings.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as GI from "react-icons/gi";
import { Modal , Row, Col, Table } from "react-bootstrap";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { HiSave } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import { usePreviousMonthDisabled } from '@mui/lab/internal/pickers/hooks/date-helpers-hooks';
import Loader1 from '../../loader/loader';
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Salesreplacement from '../Sales/Salesreplacement';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";


function State(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [btnhide, setbtnhide] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [tableRow,setTableRow] = React.useState([{key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "",color: "", size: "",colorsize: [],addnew: true, fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}] }])
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [arraydel, setArraydel] = React.useState([])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [editmode, setEditmode] = React.useState(false);
    const [addnew, setAddnew] = React.useState(false);

    const [replacertrn, setReplacertrn] = React.useState("");
    const [imageurl, setImageurl] = React.useState("");

    const [findHide, setFindhide] = React.useState(false);

    const [loader, setLoader] = React.useState(false);
    const [btnloader, setBtnloader] = React.useState(false);
    const [replaceorder, setReplaceorder] = React.useState(0);
    // const [productname, setProductname] = React.useState("")
    // const [selectedIndex, setSelectedindex] = React.useState("")

    // api values
    const [amount, setAmount] = React.useState(0);
    const [customerList, setCustomerlist] = React.useState([])
    const [customer, setCustomer] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');
    const [salesid, setSalesid] = React.useState('');
    const [grandtotal, setGrandtotal] = React.useState(0);
    const [grandtotalex, setGrandtotalex] = React.useState(0);
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");
    const [customerpricename, setCustomerpricename] = React.useState("");

    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState(10);
    const [advance, setAdvance] = React.useState('');
    const [total12, setTotal12] = React.useState('');

    const [payable, setPayable] = React.useState(0);
    const [roundoff, setRoundoff] = React.useState(0);
    const [salesorderid, setSalesorderid] = React.useState('');

    const [deliverycharge, setDeliverycharge] = React.useState(0);
    const [variationamount, setVariationamount] = React.useState(0);
    const [totalaftervariation, setTotalaftervariation] = React.useState(0);
    const [deletedAmount, setDeletedamount] = React.useState(0);

    const [replacedamt, setReplacedamt] = React.useState(0);

    const [diffamount, setDiffamount] = React.useState(0);

    const [imgPopup, setImgpopup] = React.useState(false);

    const [customername, setCustomername] = React.useState('');
    const [building, setBuilding] = React.useState('');
    const [area, setArea] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [ordertype, setOrdertype] = React.useState('');
    const [street, setStreet] = React.useState('');

    const [errormsg, seterrormsg] = React.useState('');
    const [productlist, setProductlist] = React.useState([]);
    const [productsid, setProductid] = React.useState("");
    const [colorlist, setColorlist] = React.useState([]);
    const [color, setColor] = React.useState("")
    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const {id} = useParams()
    const [reference, setReference] = React.useState("");
    const [zone, setZone] = React.useState("");
    const [salesorderlist, setSalesorderlist] = React.useState([]);
    const [areashow, setAreashow] = React.useState(false);
    const [arealist, setArealist] = React.useState([]);
    const [ordertypelist, setOrdertypelist] = React.useState([]);

    // const results = people.filter(({ name }) => query && name.toLowerCase().includes(query.toLowerCase()) );
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();

    // comboproductadd

    const [combotable, setCombotable] = React.useState([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: ""}]);
    const [combotable2, setCombotable2] = React.useState([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: ""}]);
    const [comboproductlist, setComboproductlist] = React.useState([]);
    const [comboproductlist2, setComboproductlist2] = React.useState([]);
    const [combobatchlist, setCombobatchlist] = React.useState([{key: "", value: []}]);
    const [combobatchlist2, setCombobatchlist2] = React.useState([]);
    const [cbproduct, setCbproduct] = React.useState("");
    const [popupindex, setPopupindex] = React.useState("");
    const [combopopupid, setCombopopupid] = React.useState("");


    const [currentcash, setCurrentcash] = React.useState("");
    const [isReplace, setIsreplace] = React.useState("");
    const [isInvoice, setIsinvoice] = React.useState(null);

    useEffect(() => {
     var total = parseFloat(amount) - parseFloat(discount) + parseFloat(otherCharges) 
     setCurrentcash(total);
    }, [amount , grandtotalex])

    const [ledgerName, setLedgerName] = React.useState("");
    const [ledgerList, setLedgerlist] = React.useState([]);

    const [begindate, setBegindate] = React.useState("");
    const [combomodal, setCombomodal] = React.useState(false); 
    const [comboaddmodal, setComboaddmodal] = React.useState(false); 
    const [combolist, setCombolist] = React.useState([]);
    const [sodidn, setSodidn] = React.useState("");
    const [delarr, setDelarr] = React.useState([]);
    const [addarr, setAddarr] = React.useState([]);
    const [delindex, setDelindex] = React.useState("");
    const [delproid, setDelproid] = React.useState("");
    const [totmodal, setTotmodal] = React.useState(false);

    const [comboqty, setComboqty ] = React.useState("");
    const [combostock, setCombostock ] = React.useState("");
    const [comboarr, setComboarr ] = React.useState([]);
    const [comboarrlist, setComboarrlist ] = React.useState({});
    const [proname1, setProname ] = React.useState("");

    const [balTotal, setBaltotal] = React.useState("");
    const [balTotallist, setBaltotallist] = React.useState([]);

    const [prodid, setProdid] = React.useState(""); 

    // const setChangecomboqty = (e) => {
    //     setComboqty(e.target.value)
    // }

    let history = useHistory();

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  const billtypeChange = (event) => {
    setOrdertype(event.target.value);
  };

  useEffect(() => {
    getOrdertype()
    beginDate()
  }, [])

    const beginDate = () => {
      var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company
      }
      fetch(global.url + "beginDate", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.error == false){
            setBegindate(responseJson.data.company_begindate)
           
          }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getOrdertype = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
        "companyid" : company,
    }
    fetch(global.url + "showOrderTypelist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        setOrdertypelist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const cbproductChange = (event, index) => {
      const input = [...combotable]
      input[index].productid = event.target.value
      setCombotable(input)
      setProdid(event.target.value)
      getBatchlist(event.target.value,index)
  }

  const cbproductChange2 = (event) => {
    setProdid(event.target.value)
    getBatchlist2(event.target.value)
}

  const cbbatchChange = (event, index) => {
    const input = [...combotable]
    input[index].fetch_id = event.target.value
    setCombotable(input)
    getProductbybatch(event.target.value, index)
  }

  const [forhide, setForhide] = useState("")

  const cbbatchChange2 = (event) => {
    getProductbybatch2(event.target.value)
    setForhide(event.target.value)
  }

  const colorChange = (event) => {
      var input=[...tableRow]
      input[selectedIndex].product_batch_id = event.target.value
      setTableRow(input)
      
      getProductbybatch1(event.target.value, selectedIndex)
  }

  const getProductbybatch = (id, index) => {
      var data = {
          "pb_id" : id,
          "products_id_master" : combopopupid,
          "products_id" : prodid
      }
      fetch(global.url + "getProductBatchById", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        // console.log("comborow batch", responseJson )
          var comborow = [...combotable]
        //   comborow[index].product_name = responseJson.data.combo_details.product_name
          comborow[index].fetch_quantity = 1
          comborow[index].quantity = responseJson.data.combo_details.fc_product_quantity
          comborow[index].color = responseJson.data.color_name
          comborow[index].size = responseJson.data.size_name
          comborow[index].stock = responseJson.data.pb_stock
          setCombotable(comborow)
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const getProductbybatch2 = (id) => {
   
        var data = {
            "pb_id" : id,
            "products_id_master" : delproid,
            "products_id" : prodid
        }
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("responseJson", responseJson)
            setCombostock(responseJson.data.pb_stock)
            setComboarrlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    const editcomboSubmit = () => {
        setForhide("")
        var ar = [...comboarr] 
        ar.push({proname: proname1, colorname: comboarrlist.color_name, sizename: comboarrlist.size_name, index1: delindex })
        setComboarr(ar)
        var arr = [...addarr]
        arr.push({sod_id: sodidn, sod_comboid: comboarrlist.pb_id })
        setAddarr(arr)
        setComboaddmodal(false)
    }

    // const addReplaceCombo = () => {
    //     var input = [...tableRow]
    //     input.fetch_products.push(combotable)
    //     setTableRow(input)
    //     setCombotable([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
    //     console.log("table", tableRow)
    // }

    const getProductbybatch1 = (id, index) => {
   
        var data = {
            "pb_id" : id,
            "products_id_master" : id,
            "products_id" : productsid
        }
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
//   console.log("responseJson", responseJson)
            setDetails(id, index, responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    const setDetails = (id, index, responseJson) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        input[index].pb_stock = responseJson.pb_stock
        input[index].sod_qty = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].pei_image = responseJson.pei_image  
        input[index].pb_inter_barcode = responseJson.pb_inter_barcode
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0
        input[index].pb_batch = responseJson.pb_batch
        input[index].sod_total_amount = responseJson.pb_salesrate 
        setTableRow(input)
    }

  const getBatchlist = (id, index) => {
        var data = {
            "products_id" : id,
            "products_id_master" : combopopupid
        }
        fetch(global.url + "getProductbyIdData", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("comborow product", responseJson )
            var input = [...combobatchlist]
            input[index].value = responseJson.data
            setCombobatchlist(input)
            var comborow = [...combotable]
            comborow[index].product_name = responseJson.master.product_name
            // comborow[index].fetch_quantity = 1
            // comborow[index].quantity = responseJson.master.fc_product_quantity
            setCombotable(comborow)
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const getBatchlist2 = (id) => {
    var data = {
        "products_id" : id,
        "products_id_master" : delproid
    }
    fetch(global.url + "getProductbyIdData", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.error == false){
            setCombobatchlist2(responseJson.data)
            setProname(responseJson.data[0].product_name)
        }
       
    })
    .catch((error)=>{
        console.log(error)
    })
}

  const userChange = (event) => {
    setSalesman(event.target.value);
  };

  const ledgerChange = (event) => {
      setLedgerName(event.target.value)
  }

    const addRow = () => {
        setAddnew(true)
        setTableRow([...tableRow, {key:"",product_batch_id:"", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "",color: "", size: "",colorsize: [],addnew: true, fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}]}])
    }

    const customerChange = (event) => {
        setCustomer(event.target.value);
        getCustomerbyid(event.target.value)
        getCustomerbalance(event.target.value)
    };

    const getCustomerbalance = (id) => {
        var data = {
            "customer_id":  id
        }
        fetch(global.url + "viewCustomerBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {

                setBalance(responseJson.opening_balance)
                setLimitbalance(responseJson.remain_balance)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getCustomerbyid = (id) => {
        var data = {
            "customer_id":  id
        }
        fetch(global.url + "getCustomerbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{

        })
        .catch((error)=>{
            console.log(error)
        })
    }

    function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
    
        if ( isDown ) {
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
      }

      useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()

        if(id == "add") {
            getInvoiceno()
            setEditmode(false)
        }
        else{
            setLoader(true)
           setSalesorderid(id)
           getDetailsbyid(id)
           setEditmode(true)
        }
      }, []);

      useEffect(() => {
        getCustomer()
        getLedger()
        
      }, []);

      const getLedger = () => {
          fetch(global.url + "LedgerSelection", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
              if(responseJson.error == false){
                // console.log("resp led", responseJson)
                setLedgerlist(responseJson.data)
                setLedgerName(responseJson.data[3].ledger_id)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      useEffect(() => {
        getTotalamount()
      }, [tableRow, discount, otherCharges, amount]); 

      useEffect(() => {
        if(amount > 200) {
            otherChargeschange(0)
        } 
        else{
            otherChargeschange(10)
        }
      }, [amount]);

     

      const getTotalamount = () => {
        var totalAmount = 0;
        var totalAmount1 = 0;
        for (var i = 0; i < tableRow.length; i++) {
            if(tableRow[i].salesorder_isreplacedone == 1) {
                totalAmount += parseFloat(tableRow[i].sod_total_amount) 
            }
            else if(tableRow[i].sod_iscancel !=1){
                totalAmount += parseFloat(tableRow[i].sod_total_amount) 
            }
          if(tableRow[i].sod_id == ""  && tableRow[i].product_batch_id != "") {
            totalAmount1 += parseFloat(tableRow[i].sod_total_amount)
          }
       }
        var disc = discount
        setGrandtotal(parseFloat(amount)-parseFloat(disc)+parseFloat(otherCharges))
        setGrandtotalex(totalAmount-disc+parseFloat(otherCharges))
        setPayable(totalAmount-disc+parseFloat(otherCharges))
        setVariationamount(totalAmount1 + diffamount)
        setTotalaftervariation(totalAmount1 + diffamount + parseFloat(deliverycharge))
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        // var disc = amount * event
        var result = amount - event
        var grandtotal1  =  parseFloat(result) + parseFloat(otherCharges) 
        setGrandtotal(grandtotal1)
        setGrandtotalex(grandtotal1)
        setPayable(grandtotal1)
    }

    

    const otherChargeschange = (event) => {
        if(event == "") {
            setOthercharge(0)
        }
        else{
            setOthercharge(event)
        }
    }

      const getDetailsbyid = (id) => {
        setbtnhide(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
          var data = {
                "companyid": company,
                "salesorderid": id,
                "type": userdata.user_type,
                "branchid" : branch
            }
        fetch(global.url + "viewSalesOrderShowAlsoCancel", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
    //  console.log("get response",responseJson)
            if(responseJson.status == "false") {
                setFindhide(true)
                setCustomername(responseJson.data.customer_name)
                setBuilding(responseJson.data.customer_building)
                setArea(responseJson.data.customer_area)
                setRemarks(responseJson.data.salesorder_narration)
                setOrdertype(responseJson.data.sales_bill_type)
                setStreet(responseJson.data.customer_street)
                setZone(responseJson.data.sales_zone)
                setMobile(responseJson.data.customer_mobile)
                setInvoiceno(responseJson.data.sales_invoice)
                
                setReplacertrn(responseJson.data.salesorder_replacereturn)
                setTotal12(responseJson.data.sales_total)
                
                setReference(responseJson.data.sales_reference)
                setSalesman(responseJson.data.sales_salesman)
                if(responseJson.data.sales_details != undefined) {
                    setTableRow(responseJson.data.sales_details)
                    var deleteamt = responseJson.data.replaced_amount - responseJson.data.cancelled_products
                    setReplacedamt(responseJson.data.replaced_amount)
                    setDeletedamount(responseJson.data.cancelled_products)
                    setVariationamount(deleteamt)
                    setDiffamount(deleteamt)
                    setDeliverycharge(responseJson.data.salesorder_delivery_charge)

                    setReplaceorder(responseJson.data.salesorder_replacebefore)
                }
                else{
                    setTableRow([])
                    var deleteamt = responseJson.data.replaced_amount_2 - responseJson.data.cancelled_products_2
                    setReplacedamt(responseJson.data.replaced_amount_2)
                    setDeletedamount(responseJson.data.cancelled_products_2)
                    setVariationamount(deleteamt)
                    setDiffamount(deleteamt)
                    setDeliverycharge(responseJson.data.salesorder_delivery_charge)

                    setReplaceorder(responseJson.data.salesorder_replacebefore)
                }
                // setRoundoff(responseJson.data.sales_roundoff)
                setDiscount(responseJson.data.sales_discount)
                setOthercharge(responseJson.data.sales_other_charges)
                setLoader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const getInvoiceno = () => {
        fetch(global.url + "salesorderinvoiceno", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setInvoiceno(responseJson.data.invoiceno)
              setInvoNu(responseJson.data.invoiceno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.userdata)

        var data = {
          "companyid": company,
          "usersType": userdata.userdata,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewBillSetting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setBilltypelist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getCustomer = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type":  userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewCustomer", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.Error == "false") {
                setCustomerlist(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      useEffect(() => {
        if ( hasResults ) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
          document.body.removeEventListener('keydown', onKeyDown1);
        }
      }, [hasResults, hasResultsProduct]);

      const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].pb_stock = stock
            input[index].productname = name
            input[index].sales_iscombo = iscombo
            input[index].sod_qty = qty
            input[index].unit = unit
            input[index].color = color
            input[index].size = size
            input[index].addnew = true
            input[index].fetch_products = [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}]
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            if(discount) {
                var totalcalc =  discount
            }
            else {
                var totalcalc = 0
            }
            input[index].pricelevel = pricelevel
            input[index].sod_total_amount = salesrate - totalcalc
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [], addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            setCode("")
            var total1 = total + totalcalc
            setTotal(total1)
      }

      const listClickrefcode = (index, productid, productrefcode,productname,products,image, iscombo) => {
            setProductid(productid)
            var input = [...tableRow]
            input[index].key = index
            input[index].products_id = productid
            input[index].code = productrefcode
            input[index].sales_iscombo = iscombo
            input[index].productname = productname
            input[index].pei_image = image
            input[index].colorsize = products
            input[index].addnew = true
            setSelectedindex(index)
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            setCode("")
        }

      const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].sod_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            
            input[index].sod_total_amount = salesrate
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sod_id: "",color: "", size: "",colorsize: [],addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            setProductname("")
    }

    // const [delqty, setDelqty] = React.useState('');
    // const [delindex, setDelindex] = React.useState('');
    // const [delprice, setDelprice] = React.useState('');
    // const [delsodid, setDelsodid] = React.useState('');

    //   const DeleteQtyClick = () => {
    //         setTotal(total-delprice)
    //         var input = [...tableRow]
    //         input[delindex].sod_iscancel = 1
    //         setTableRow(input)
    //         var array = [...arraydel]
    //         array[delindex].sod_id = delsodid
    //         array[delindex].sod_qty = delqty
    //         setArraydel(array)
            
    //   }

    const deleteCombo = (index, id, qty) => {
        setCombomodal(false)
        setComboaddmodal(true)
        getComboProd(delproid)
        setComboqty(qty)
        var arr = [...delarr]
        arr.push({sod_id: sodidn, sod_comboid: id })
        setDelarr(arr)
        var array = [...tableRow]
        array[delindex].fetch_products[index].fetch_isdelete = 1
        setTableRow(array)
    }

    const deleteRowcombo = (index, combo, sodid, prodid) => {
        setCombomodal(true)
        setCombolist(combo)
        setSodidn(sodid)
        setDelindex(index)
        setDelproid(prodid)
        setCombotable2(combo)
    }

      const deleteRow = (index, price, sodid, sodqty) => {
        setTotal(total-price)
        var input = [...tableRow]
        input[index].sod_iscancel = 1
        setTableRow(input)
        var array = [...arraydel]
        array.push(sodid)
        setArraydel(array)
        }

        const deletesingle = (index, price, sodid, sodqty) => {
                setTotal(total-price)
                var input = [...tableRow]
                input.splice(index, 1)
                setTableRow(input)
        } 



      const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        if(event.target.value > parseFloat(textArray1[index].pb_stock)){
            seterrormsg("Can't enter qty greater than stock")
        }
        else{
            seterrormsg("")
            var totalprice = textArray1[index].price * event.target.value
            textArray1[index].sod_qty = event.target.value
            textArray1[index].calcprice = totalprice
            textArray1[index].sod_total_amount = totalprice
            setTableRow(textArray1)
        }
    }

    const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        
        fetch(global.url + "getSearchOnlyStockprd", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("responseJson", responseJson)
            setProductlist(responseJson.data)
         
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () => {
        if(mobile.length < 8 ) {
            alert("8 Digit in mobile Number required")
        }
        else{
            var paymentdate;
            var lpodate1;
            // if(editmode == true) {
            //     paymentdate = date.split('T')[0]
            //     lpodate1 = lpodate.split('T')[0]
            // }
            // else{
            //     paymentdate = date.toISOString().split('T')[0]
            //     lpodate1 = lpodate.toISOString().split('T')[0]
            // }
    
            const exampleArray = []
            var exampleSubarray = false
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].product_batch_id == "" || tableRow[i].sod_iscancel == 1) {
                    // tableRow.splice(i, 1)
                }
                else{
                    if(tableRow[i].fetch_products != undefined){
                        for(var j = 0 ; j< tableRow[i].fetch_products.length; j++) {

                            if(tableRow[i].fetch_products[j].fetch_id == "" && tableRow[i].sales_iscombo == 1){
                                alert("Empty Product in Combo product, Cannot Save Order")
                                exampleSubarray = true
                            }
                            else{
                                
                            }
                        }
                    }
                    
                    exampleArray.push(tableRow[i])
                } 
            }

            if(exampleSubarray == false){
                setBtnloader(true)
                var data = {
                    "salesorder_id" : salesorderid,
                    "salesorder_user_id" : userId,
                    "salesorder_branchid":  userbranchid,
                    "salesorder_companyid":  usercompanyid,
                    "salesorder_bill_type":  ordertype,
                    "salesorder_bill_type_id" : ordertype,
                    "salesorder_ref_no":  refno,
                    "salesorder_salesman":  salesman,
                    "salesorder_invoice":  invoiceno,
                    "salesorder_date":  "",
                    "salesorder_customer":  customer,
                    "salesorder_crdt_limit":  "",
                    "salesorder_balance":  "",
                    "salesorder_cash_customer":  "",
                    "salesorder_limit_balance":  "",
                    "salesorder_discount":  "",
                    "salesorder_other_charges":  "",
                    "salesorder_advance":  "",
                    "salesorder_current_bill":  "",
                    "salesorder_due_date":  "",
                    "salesorder_zone":  zone,
                    "salesorder_reference":  reference,
                    "salesorder_narration":  remarks,
                    "salesorder_date": date,
                    "salesorder_total": amount,
                    "salesorder_cashledger": grandtotal,
                    "salesorder_discount": discount,
                    "salesorder_other_charges": otherCharges,
                    "salesorder_subtotal": 0,
                    "salesorder_roundoff": roundoff,
                    "salesorder_ledger_fk" : ledgerName,
                    "customer_name": customername,
                    "customer_building": building,
                    "customer_street": street,
                    "salesorder_zone": zone,
                    "customer_mobile": mobile,
                    // "customer_whatsapp": ordertype,
                    "customer_area": area,
                    "customer_remarks": remarks,
                    "salesorder_details": exampleArray,
                    "variation_amount": variationamount,
                    "delivery_charge": deliverycharge,
                    "salesorder_replacebefore": replaceorder,
                    "salesorder_deleted" : arraydel,
                    "salesorder_combodelete" : delarr,
                    "salesorder_comboadd" : addarr 
                }
                // console.log("console data", data)
                fetch(global.url + "updateSalesorder", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then((response)=> response.json())
                .then((responseJson)=>{
                    // console.log("responseJson save", responseJson)
                    setBtnloader(false)
                    if(responseJson.error == true) {
                        alert(responseJson.message)
                       
                    }
                    else{
                        alert(responseJson.message)
                        window.open('/saleorderinvoice/' + responseJson.data, "_blank")
                        
                        window.location.reload()
                    }
                })
                .catch((error)=>{
                    setBtnloader(false)
             
                    console.log(error)
                })
            }
        }
    }

    const addProduct = (id, index, comborows, comboid) => {
        setModal(true)
        setCombopopupid(comboid)
        setPopupindex(index)
        var data = {
            "products_id" : id,
        }
        fetch(global.url + "getProductbyFetchID", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setComboproductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
        setCombotable(comborows)
    }

    const getComboProd = (id) => {
        var data = {
            "products_id" : id,
        }
        fetch(global.url + "getProductbyFetchID", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setComboproductlist2(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
     
    }

    const addComborow = () => {
        setCombotable([...combotable, {key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
        setCombobatchlist([...combobatchlist, {key: "", value: []}])
    }

   

    const addtoTablerow = () => {
        var counts = [];
        const exampleArray = []
        
            for(var i = 0 ; i < combotable.length; i++) {
                exampleArray.push(combotable[i].productid)
            }
    
            for(var i = 0 ; i < combotable.length; i++) {
                counts.push({value: "", count: 0})
                var input = [...counts]
                input[i].value = combotable[i].productid
                exampleArray.forEach((v) => (v === combotable[i].productid && counts[i].count++ ));
            }
            
            for(var i = 0 ; i < counts.length; i++) {
                for(var j = 0 ; j < combotable.length; j++) {
                    
                    if(counts[i].value == combotable[j].productid){
                        if(counts[i].count > combotable[j].quantity){
                            alert("greater than max quantity - " + combotable[j].product_name)
                            return;
                        }
                        if(counts[i].count < combotable[j].quantity){
                            alert("Lesser than max quantity - " + combotable[j].product_name)
                            return;
                        }
                    }
                    
                }
            }

            if(exampleArray.length == 0){
                alert("No Products added")
            }else{
                var input = [...tableRow]
                input[popupindex].fetch_products = combotable
                setTableRow(input)
                setModal(false)
                setCombotable([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
            }
        
    }



    // const addtoTablerow = () => {
    //     var counts = [];
    //     const exampleArray = []
        
    //         for(var i = 0 ; i < combotable.length; i++) {
    //             exampleArray.push(combotable[i].productid)
    //         }
    
    //         for(var i = 0 ; i < combotable.length; i++) {
    //             counts.push({value: "", count: 0})
    //             var input = [...counts]
    //             input[i].value = combotable[i].productid
    //             exampleArray.forEach((v) => (v === combotable[i].productid && counts[i].count++ ));
    //         }
            
    //         for(var i = 0 ; i < counts.length; i++) {
    //             for(var j = 0 ; j < combotable.length; j++) {
                    
    //                 if(counts[i].value == combotable[j].productid){
    //                     if(counts[i].count > combotable[j].quantity){
    //                         alert("greater than max quantity - " + combotable[j].product_name)
    //                         return;
    //                     }
    //                     if(counts[i].count < combotable[j].quantity){
    //                         alert("Lesser than max quantity - " + combotable[j].product_name)
    //                         return;
    //                     }
    //                 }
                    
    //             }
    //         }

    //         if(exampleArray.length == 0){
    //             alert("No Products added")
    //         }else{
    //             var input = [...tableRow]
    //             input[popupindex].fetch_products = combotable
    //             setTableRow(input)
    //             setModal(false)
    //             setCombotable([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
    //         }
        
    // }

    const deletePopupitem = (index) => {
        var input = [...combotable]
        input.splice(index, 1)
        setCombotable(input)
    }
    const deletePopupitem2 = (index) => {
        var input = [...combotable2]
        input.splice(index, 1)
        setCombotable2(input)
    }

    const onPopup = () => {
        setModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "type":  userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewPurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.status == "true") {
                setSalesorderlist([])
            }
            else{
                setSalesorderlist(responseJson.data)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const clear = () => {
        history.push('/transaction/Salesorder/add')
        window.location.reload()
    }

    const areaChange = (e) => {
        setArea(e.target.value)
    }

    const setFields = (e) => {
        setZone(e)
        switch (e) {
        case "1":
            setArea("Doha - Al Jasra")
            break;
        case '2':
            setArea("Doha - Al Bidda")
            break;
        case '3':
            setArea("Doha - Mushaireb")
            break;
        case '4':
            setArea("Doha - Mushaireb")
            break;
        case '5':
            setArea("Doha -Fereej Al Asmakh")
            break;
        case '6':
            setArea("Doha - Old Al Ghanim")
            break;
        case "7":
            setArea("Doha - Al Souq (Souq Waqif)")
            break;
        case '12':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Bidda"},
                {"name": "Rumaila"},
                {"name": "Wadi Al Sail"}]
            setArealist(array)
            break;
       
        case '13':
            setArea("Doha - Mushaireb")
            break;
        case '14':
            setArea("Doha - Fereej Abdel Aziz")
            break;
        case '15':
            setArea("Doha - Doha Al Jadeeda")
            break;
        case "16":
            setArea("Doha - Old Al Ghanim")
            break;
        case '17':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Doha - Al Ruffaa"},
                {"name": "Al Hitmi"}]
            setArealist(array)
            break;

        case '18':
            setArea("Doha - Al Mirqab")
            break;
        case '19':
            setArea("Doha - Doha Port")
            break;
        case '20':
            setArea("Doha - Wadi Al Sail")
            break;
        case '21':
            setArea("Doha - Al Rumaila")
            break;
        case '22':
            setArea("Bin Mahmoud")
            break;
        case '23':
            setArea("Bin Mahmoud")
            break;
        case '24':
            setAreashow(true)
             setArea("")
            var array = [
                {"name": "Doha"},
                {"name": "Rawdat Al Khail"},
                {"name": "Al Muntazah"}]
            setArealist(array)
            break;
        case '25':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Doha"},
                {"name": "Mansoura"},
                {"name": "Bin Dirhem"}]
            setArealist(array)
            break;
        
        case '26':
            setArea("Doha - Najma")
            break;
        case '27':
            setArea("Doha - Umm Ghuwailina")
            break;
        case '28':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Doha"},
                {"name": "Al Khulaifat"},
                {"name": "Ras Bu Abboud"}]
            setArealist(array)
            break;
        case '29':
            setArea("Doha - Ras Bu Abboud")
            break;
        case '30':
            setArea("Duhail")
            break;
        case "31":
            setArea("Umm Lekhba")
            break;
        case '32':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Madinat Khalifa North"},
                {"name": "Dahl Al Hamam"},]
            setArealist(array)
            break;
        case '33':
            setArea("Markhiya")
            break;
        case '34':
            setArea("Madinat Khalifa South")
            break;
        case '35':
            setArea("Fereej Kulaib")
            break;
        case '36':
            setArea("Al Messila")
            break;
        case "37":
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "New Al Hitmi"},
                {"name": "Bin Omran"},
                {"name": "Hamad Medical City"}]
            setArealist(array)
            break;

        case '38':
            setArea("Al Sadd")
            break;
        case '39':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Al Nasr"},
                {"name": "Al Sadd"},
                {"name": "Al Mirqab Al Jadeed"}]
            setArealist(array)
            break;
        case '40':
            setArea("New Slata")
            break;
        case '41':
            setArea("Nuaija")
            break;
        case '42':
            setArea("Hilal")
            break;
        case '43':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Maamoura"},
                {"name": "Nuaija"},
                {"name": "Fereej Al Ali"},
                {"name": "Fereej Al Asiri"},
                {"name": "New Fereej Al Khulaifat"},]
            setArealist(array)
            break;
        case '44':
            setArea("Nuaija")
            break;
        case '45':
            setArea("Old Airport")
            break;
        case "46":
            setArea("Thumama")
            break;
        case '47':
            setArea("Thumama")
            break;
        case '48':
            setArea("Doha International Airport")
            break;
        case '49':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Hamad International Airport"},
                {"name": "Banana Island"},
                {"name": "Ras Bu Fontas"},]
            setArealist(array)
            break;
        case '50':
            setArea("Thumama")
            break;
        case '51':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Bani Hajer"},
                {"name": "Al Seej"},
                {"name": "Al Themaid"},
                {"name": "Rawdat Al Jahhaniya"},
                {"name": "Rawdat Egdaim"},
                {"name": "Shariya"},
                {"name": "Gharaffat Al Rayan"},
                {"name": "Izghawa"},]
            setArealist(array)
            break;
        case "52":
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Luqta"},
                {"name": "Old Al Rayyan"},
                {"name": "Al Shaqab"},
                {"name": "Lebday"},
                {"name": "Education City"},]
            setArealist(array)
            break;
        case '53':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "New Al Rayyan"},
                {"name": "Muaither"},
                {"name": "Wajba"},
                {"name": "Rawdat Abal Heeran"},
                {"name": "Umm Al Juwashen"},]
            setArealist(array)
            break;
        case '54':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Baaya"},
                {"name": "Muraikh"},
                {"name": "Mehairja"},
                {"name": "Fereej Al Amir"},
                {"name": "Luaib"},
                {"name": "Aspire Zone"},
                {"name": "Fereej Al Soudan"},]
            setArealist(array)
            break;
        case '55':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Fereej Al Soudan"},
                {"name": "Al Waab"},
                {"name": "Al Aziziya"},
                {"name": "Al Ghanim Al Jadeed"},
                {"name": "Al Mearad"},
                {"name": "Al Sailiya"},
                {"name": "Bu Sidra"},
                {"name": "Fereej Al Manaseer"},
                {"name": "Murra"},
                {"name": "Muaither"},
                {"name": "Umm Al Seneem"}]
            setArealist(array)
            break;
        case '56':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Bu Hamour"},
                {"name": "Ain Khaled"},
                {"name": "Mesaimeer"},
                {"name": "Maamoura"},
                {"name": "Barwa City"},
                {"name": "Umm Al Seneem"}]
            setArealist(array)
            break;
        case '57':
            setArea("Industrial Area")
            break;
        case '58':
            setArea("Wholesale Market")
            break;
        case '60':
            setArea("Dafna")
            break;
        case '61':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Dafna"},
                {"name": "Al Gassar"},]
            setArealist(array)
            break;
        case "62":
            setArea("Lekhwair")
            break;
        case '63':
            setArea("Onaiza")
            break;
        case '64':
            setArea("Lejbailat")
            break;
        case '65':
            setArea("Onaiza")
            break;
        case '66':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Legtaifiya"},
                {"name": "Onaiza"},
                {"name": "The Pearl"},
                {"name": "Al Gassar"},
                {"name": "Katara"}]
            setArealist(array)
            break;
        case '67':
            setArea("Hazm Al Markhiya")
            break;
        case '68':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Jelaiah"},
                {"name": "Al Tarfa"},
                {"name": "Jeryan Nejaima"},]
            setArealist(array)
            break;
        case '69':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Al Daayen"},
                {"name": "Al Egla"},
                {"name": "Lusail"},
                {"name": "Wadi Al Banat"},]
            setArealist(array)
            break;
        case '70':       
          setAreashow(true)
          setArea("")
            var array = [
                {"name": "Al Daayen"},
                {"name": "Leabaib"},
                {"name": "Al Ebb"},
                {"name": "Al Kheesa"},
                {"name": "Rawdat Al Hamama"},
                {"name": "Jeryan Jenaihat"},
                {"name": "Al Sakhama"},
                {"name": "Jery Al Matrooshi"},
                {"name": "Semaisma"},
                {"name": "Tenbek"},
                {"name": "Umm Garn"},
                {"name": "Al Jeryan"},
                {"name": "Al Kheesa"}]
            setArealist(array)
            break;
        case '71':            
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Umm Slal"},
                {"name": "Al Froosh"},
                {"name": "Al Kharaitiyat"},
                {"name": "Bu Fessela"},
                {"name": "Bu Garn"},
                {"name": "Bu Glaila"},
                {"name": "Izghawa"},
                {"name": "Umm Al Amad"},
                {"name": "Umm Ebairiya"},
                {"name": "Umm Slal Ali"},
                {"name": "Umm Slal Mohammed"}]
            setArealist(array)
            break;
        case "72":
            setArea("Doha - Al Jasra")
            break;
        case '73':
            setArea("Doha - Al Bidda")
            break;
        case '74':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Al Khor"},
                {"name": "Al Thakira"},]
            setArealist(array)
            break;
        case '75':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Al Khor"},
                {"name": "Al Thakira"},
                {"name": "Ras Laffan"}]
            setArealist(array)
            break;
        case '76':
            setArea("Al Khor")
            break;
        case '80':
            setArea("Sheehaniya")
            break;
        case '81':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Mebaireek"},
                {"name": "New Industrial Area"},
                {"name": "Mekaines"},]
            setArealist(array)
            break;
        case "82":
            setArea("Sheehaniya")
            break;
        case '83':
            setArea("Doha - Al Bidda")
            break;
        case '90':
            setArea("Wakra")
            break;
        case '91':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Wakra"},
                {"name": "Mashaf"},
                {"name": "Al Wukair"},
                {"name": "Muaither Al Wukair"},
                {"name": "Wadi Aba Seleel"},]
            setArealist(array)
            break;
        case '92':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Wakra"},
                {"name": "Mesaieed"},
                {"name": "Mesaieed Free Zone"},]
            setArealist(array)
            break;
        case '93':
            setAreashow(true)
            setArea("")
            var array = [
                {"name": "Al Wakra"},
                {"name": "Mesaieed Industrial Area"},
                {"name": "Sealine"},]
            setArealist(array)
            break;
        default:
            setAreashow(false)
            setArea("")
            break
        }
    }

    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
            setReplaceorder(1)
        }
        else {
            setReplaceorder(0)
        }
    }

    const deliveryChargechange = (event) => {
        setDeliverycharge(event)
    }

    const calculateAmt = () => {
        var totalAmount = 0;
        var totalAmount1 = 0;
        for (var i = 0; i < tableRow.length; i++) {
          totalAmount += parseFloat(tableRow[i].sod_total_amount) 
          if(tableRow[i].sod_id == "" && tableRow[i].product_batch_id != "") {
            totalAmount1 += parseFloat(tableRow[i].sod_total_amount)
          }
       }
        setVariationamount(totalAmount1 + diffamount)
        setTotalaftervariation(totalAmount1 + diffamount + parseFloat(deliverycharge))
    }

    const imgPopupdisplay = (image) => {
        setImgpopup(true)
        setImageurl(image)
    }


    const mobChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMobile(value);
        getBaltotal(value)
      };

    const getBaltotal = (mobile1) => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
           "companyid": company,
           "mobile": mobile1,
           "type" : userdata.user_type, 
           "branchid" : branch
        }

        fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson) => {
            // console.log("responseJson mobile", responseJson)
            if(responseJson.error == false){
                // setBaltotal(responseJson.grandtotal)
                setBaltotallist(responseJson.data)
            }else{
                // setBaltotal("")
                setBaltotallist([])
            }
         
        })
        .catch((error)=>{
            console.log(error)
        })
    }

      const upClick = () => {
        setInvoiceno(preCount => preCount + 1)
    }
    const downClick = () => {
        if(invoiceno > 1){
            setInvoiceno(preCount => preCount - 1)
        }
    }


    const salesOrderDetails = (id) => {
        var data = {
            'invoice_no' : invoiceno
          }
          fetch(global.url + "SalesorderdetailByInvoiceno", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson) => {
                // console.log("find respo", responseJson)
              if(responseJson.error == false){
                setFindhide(true)
                setIsreplace(responseJson.data.salesorder_isreplacebill)
                setIsinvoice(responseJson.data.salesorder_replace_invoiceno)
                setSalesorderid(responseJson.data.salesorder_id)
                setDate(responseJson.data.date)
                setBuilding(responseJson.data.buildingno)
                setRemarks(responseJson.data.remarks)
                setOrdertype(responseJson.data.ordertype)
                setStreet(responseJson.data.street)
                setReference(responseJson.data.reference)
                setZone(responseJson.data.zone)
                setMobile(responseJson.data.limitbalance)
                setArea(responseJson.data.area)
                setMobile(responseJson.data.mobno)
                setCustomername(responseJson.data.customer)
                setSalesman(responseJson.data.salesman)
                // setDiscount(responseJson.data.discount)
                // setRoundoff(responseJson.data.roundoff)
                // setOthercharge(responseJson.data.othercharges)
                // setAmount(responseJson.data.total)
                // setGrandtotal(responseJson.data.grandtotal)
                // setPayable(responseJson.data.grandtotal)
                if(responseJson.data.details){
                    setTableRow(responseJson.data.details)
                }
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const handleChange12 = event => {
        var result = event.target.value.replace(/[^a-z]/gi, '');
        setArea(result)
      };

    useEffect(() => {
        var totalamount = 0;
        var totalamount2 = 0;
        for(var i=0; i<tableRow.length; i++){
            if(tableRow[i].sod_iscancel == 1){
                totalamount += parseFloat(tableRow[i].sod_total_amount) 
            }
        }
        for(var i=0; i<tableRow.length; i++){
            if(tableRow[i].sod_total_amount){
                totalamount2 += parseFloat(tableRow[i].sod_total_amount) 
            }
        }
        var tot = parseFloat(totalamount2) - parseFloat(totalamount)
        setAmount(tot)
    }, [tableRow])

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
                    <div style={{margin: 20,}}>
                        <Row>
                            <Col md={2}>
                                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order</p>
                            </Col>
                            <Col md={2}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Invoice" value={invoiceno} onChange={(e) => setInvoiceno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col md={1}>
                                <div style={{marginTop: -8}}>
                                    <div>
                                        {invoNum == invoiceno ?
                                        <Button style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>:
                                        <Button onClick={upClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>}
                                    </div>
                                    <div>
                                        <Button onClick={downClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleDown  color="White" fontSize={15} /></Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={2}>
                                <Button onClick={salesOrderDetails} style={{backgroundColor: '#6d37b9', border: 'none', color: '#fff', height: 30}} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button>
                            </Col>
                            <Col md={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={date} 
                                        minDate={new Date(begindate)}
                                        onChange={(newValue) => {
                                        setDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            {balTotallist.length == 0 ? null :
                            <Col md={3}>
                                <Button onClick={() => setTotmodal(true)} style={{float: 'right'}} variant="contained">Total Orders</Button>
                            </Col>}
                        </Row>
                    </div>
               <div>
                        {loader ? <Loader1 /> :
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    {/* <Col xs={12} md={1} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Invoice" value={invoiceno} onChange={(e) => setInvoiceno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1} style={{marginTop: 20}}>
                                        <div style={{marginTop: -8}}>
                                            <div>
                                                {invoNum == invoiceno ?
                                                <Button style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>:
                                                <Button onClick={upClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>}
                                            </div>
                                            <div>
                                                <Button onClick={downClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleDown  color="White" fontSize={15} /></Button>
                                            </div>
                                        </div>
                                       
                                    </Col> */}
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Order Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={ordertype}
                                                        label="Age"
                                                        onChange={billtypeChange}
                                                        size="small"
                                                    >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {ordertypelist.map((row, index) => {
                                                        return (
                                                        <MenuItem value={row.ordertype_id}>{row.ordertype_name}</MenuItem>
                                                    )})}
                                                    </Select>
                                            </FormControl>
                                    </Col>
                                    {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                setDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Col> */}
                                    
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Customer" autoComplete="off" onChange={(e) => setCustomername(e.target.value)} value={customername} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: 20}} className="spclinput">
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Phone"  onChange={mobChange} value={mobile} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Building No."  onChange={(e) => setBuilding(e.target.value)} value={building} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Street" onChange={(e) => setStreet(e.target.value)} value={street} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Zone" autoComplete='off' onChange={(e) => setFields(e.target.value)} value={zone} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    {
                                        areashow ? 
                                        <Col xs={12} md={2} style={{marginTop: 20}}>
                                            <FormControl sx={{width: '100%' }}>
                                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Area</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={area}
                                                        label="Age"
                                                        onChange={areaChange}
                                                        size="small"
                                                    >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {arealist.map((row, index) => {
                                                        return (
                                                        <MenuItem value={row.name}>{row.name}</MenuItem>
                                                    )})}
                                                    </Select>
                                            </FormControl>
                                        </Col> :
                                        <Col xs={12} md={2} style={{marginTop: 20}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField label="Area" value={area} onChange={(e) => handleChange12(e) }  id="outlined-basic" variant="outlined"  size="small"/>
                                            </FormControl>
                                        </Col>
                                    }
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={salesman}
                                                    label="Age"
                                                    onChange={userChange}
                                                    size="small"
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {userList.map((row, index) => {
                                                    return (
                                                    <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                                )})}
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)}  id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Reference" onChange={(e) => setReference(e.target.value)} value={reference} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Ledger</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={ledgerName}
                                                    disabled
                                                    label="Age"
                                                    onChange={ledgerChange}
                                                    size="small"
                                                >
                                                
                                                {ledgerList.map((row, index) => {
                                                    return (
                                                    <MenuItem value={row.ledger_id}>{row.ledger_name}</MenuItem>
                                                )})}
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: 20}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField label="Supplier Type" disabled  value="Supplier" id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col> 
                                </Row>
                            </Col>
                    </Row>

                    }
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th style={{width: 0}}>Ref Code</th>
                                        <th>color-size</th>
                                        <th>Barcode</th>
                                        <th>Batch Code</th>
                                        <th>Product name</th>
                                        <th style={{width: 0}}>Qty</th>
                                        <th>Image</th>
                                        {/* <th>Unit</th> */}
                                        <th>Price</th>
                                        {/* <th>Calc Price</th> */}
                                        {/* <th>Discount(%)</th> */}
                                        <th>Amount</th>
                                          {/* <th>Display Zone</th> */}
                                        <th style={{width: 0}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow?.map((item, index) => {
                                        return (
                                            <tr  style={item.sod_iscancel == 1 ? {background: 'red'}: null}>
                                                <td >{index+1}</td>
                                                <td>     
                                                {
                                                    item.addnew == false ?
                                                    <input autocomplete="off" style={{width: 115}} disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                    <input autocomplete="off" style={{width: 115}} ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                }
                                                
                                                    {hasResults && selectedIndex == index && (
                                                        <div className="autocomplete">
                                                            <ul ref={resultsRef} className="people scrolling-div">
                                                                {productlist.map(item => {
                                                                    return (
                                                                        item.product_fetchiscombo == 0 ? 
                                                                        item.products?.map(result => {
                                                                            return (
                                                                            // <li key={result.url}>
                                                                            //     <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                            //         index, 
                                                                            //         result.pb_id,
                                                                            //         result.product_refcode,
                                                                            //         result.product_name,
                                                                            //         result.pricelevel_name,
                                                                            //         "1",
                                                                            //         result.unit_name,
                                                                            //         result.pb_salesrate,
                                                                            //         result.pb_salesrate,
                                                                            //         0,
                                                                            //         result.pb_salesrate,
                                                                            //         result.pricelevel_percentage,
                                                                            //         result.pb_stock,
                                                                            //         0,
                                                                            //         result.color_name,
                                                                            //         result.size_name,
                                                                            //         result
                                                                            //         )}>
                                                                            //     {result.product_refcode} - { result.product_name } - {result.color_name} - {result.size_name}
                                                                            //     </a>
                                                                            // </li>
                                                                            <li key={item.url}>
                                                                                <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                    index, 
                                                                                    item.products_id,
                                                                                    item.product_refcode,
                                                                                    item.product_name,
                                                                                    item.products,
                                                                                    item.pei_image,
                                                                                    0
                                                                                    )}>
                                                                                {item.product_refcode} - { item.product_name }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                        }) : 
                                                                        <li key={item.url}>
                                                                            <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                index, 
                                                                                item.products_id,
                                                                                item.product_refcode,
                                                                                item.product_name,
                                                                                item.pricelevel_name,
                                                                                "1",
                                                                                item.unit_name,
                                                                                item.product_comboprice,
                                                                                item.product_comboprice,
                                                                                0,
                                                                                item.product_comboprice,
                                                                                item.pricelevel_percentage,
                                                                                item.product_combostock,
                                                                                1
                                                                                )}>
                                                                            {item.product_refcode} - { item.product_name }
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    </td>
                                                   
                                                    {
                                                        
                                                  item.addnew == true ? 
                                                            <td>
                                                                { item.sales_iscombo == 1 ? null :
                                                            <FormControl sx={{width: '100%' }}>
                                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-helper-label"
                                                                            id="demo-simple-select-helper"
                                                                            value={item.product_batch_id}
                                                                            label="Age"
                                                                            onChange={colorChange}
                                                                            size="small"
                                                                        >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {item.colorsize?.map((row, index) => {
                                                                            return (
                                                                            <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                        )})}
                                                                        </Select>
                                                                </FormControl>}
                                                                </td>
                                                            : 
                                                            findHide == true ?
                                                            <td>
                                                                {item.fetch_products ? null :
                                                                <p>{item.color} - {item.size}</p>}
                                                            </td>:
                                                            <td></td>
                                                    }
                                                    <td>
                                                    {item.sales_iscombo == 0 || item.sales_iscombo == null   ?
                                                      item.pb_inter_barcode : null}
                                                    </td>
                                                    <td>
                                                    {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                    item.pb_batch : null}
                                                    </td>
                                                    <td style={{fontSize: 10}}>

                                                        {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                            <p> {item.productname} - {item.color} - {item.size} </p>:
                                                                item.sales_iscombo == 1 ?
                                                                <div>
                                                                    {
                                                                item.addnew == false ? null :
                                                                <Button style={{height: 30, fontSize: 12}} variant="contained" onClick={() => addProduct(item.product_batch_id, index, item.fetch_products, item.product_batch_id)}>Add Product</Button>
                                                                    }
                                                                    {
                                
                                                                        item.fetch_products?.map((result, k) => {
                                                                        return(
                                                                            <p style={result.fetch_isdelete == 1 ? {fontSize: 10, color: 'red'} : {fontSize: 10}}>
                                                                                {result.product_name} - {result.color} - {result.size} - (Qty {result.quantity})
                                                                            </p>
                                                                            )
                                                                        })
                                                                    }
                                                                  
                                                                    <div>
                                                                        {comboarr?.map((item) => (
                                                                            (item.index1 == index ?
                                                                            <p style={{fontSize: 10, color: 'green'}}>{item.proname} - {item.colorname} - {item.sizename}</p> : null)
                                                                        ))}
                                                                    </div> 
                                                                </div> : 
                                                                    null
                                                            }
                                                    </td>
                                                <td>
                                                    {item.sales_iscombo == 0 ||  item.sales_iscombo == null   ?
                                                        <div>  
                                                            {item.addnew == false ?
                                                            <input min="1" style={{width: 75}} disabled  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />: 
                                                            <input min="1" style={{width: 75}}  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />
                                                            }
                                                            
                                                                <label style={{fontSize: 12, fontWeight: 'bold', color: 'red'}}>stock: {item.pb_stock}</label>
                                                            
                                                        </div>: 
                                                        null
                                                    }
                                                </td>
                                                <td>
                                               
                                                {/* <img onClick={() => imgPopupdisplay()} src="https://t4.ftcdn.net/jpg/00/53/45/31/360_F_53453175_hVgYVz0WmvOXPd9CNzaUcwcibiGao3CL.jpg" style={{width: 60, marginLeft: 10}}/> */}
                                                    <img onClick={() => imgPopupdisplay(item.pei_image)} src={global.images + 'ecommerce_photos/' + item.pei_image} style={{width: 60, marginLeft: 10}}/>
                                                    </td>
                                                {/* <td>{item.unit}</td> */}
                                                <td>{item.price}</td>
                                                {/* {item.sales_iscombo == 0 ?
                                                    <td>{item.calcprice}</td> :
                                                    <td>{item.price}</td>
                                                } */}
                                                
                                                {/* <td>
                                                {item.addnew == false ?
                                                <input autocomplete="off" disabled  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                                :<input autocomplete="off"  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                                 }
                                                </td> */}
                                                <td>{item.sod_total_amount}</td>
                                                {/* <td></td> */}
                                                {findHide == true || btnhide == true ?
                                                <td>
                                                {item.sod_iscancel == 1 ? null: 
                                                item.sales_iscombo == 1 ? 
                                                    <Button onClick={() => deleteRowcombo(index, item.fetch_products, item.sod_id, item.product_batch_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button> : 
                                                    <Button onClick={() => deleteRow(index, item.amount, item.sod_id, item.sod_qty)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}
                                                </td>:
                                                 <td>
                                                 {item.sod_iscancel == 1 ? null : <Button onClick={() => deletesingle(index, item.amount, item.sod_id, item.sod_qty)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}
                                                 </td>}
                                            </tr>
                                        )
                                    })}
                                
                                </tbody>
                            </Table>
                            {/* {findHide == true ? null :
                            btnhide == true ? null: */}
                            <Row>
                        
                                <Col md={12}>
                                    <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                                </Col>
                            </Row>
                           
                        </Col>
                    </Row> 
                    <Row>
                        <Col xs ={12} md={4}>
                        {isReplace == 1 || isInvoice != null ? null :
                            <div>
                               {
                                    btnloader ? 
                                    <Loader
                                        type="Oval"
                                        color="#38B0DE"
                                        height={30}
                                        width={30}
                                        style={{float: 'left', marginTop: 32}}
                                    />:
                                    <Button onClick={onSave} style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><HiSave  color="White" fontSize={20} style={{marginRight: 10}} />Save</Button>
                                }
                            </div>}
                        </Col>
                        <Col  xs={12} md={8}>
                            <Row>                                
                                <Col xs={12} md={3} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled label="Total" value={amount.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Discount" autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Delivery Charges"  autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} value={otherCharges} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                 <Col xs={12} md={3} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                       <TextField label="Cash Ledger" disabled  value={grandtotal.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    {/* {
                        replacertrn == 2 ? 
                        <Row>
                            <Col xs={12} md={1} style={{marginTop: 20}}>
                                <p>Total
                                </p>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField disabled value={total12}  id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                        </Row> : 
                    
                    <Row>
                        <Col  xs={12}>
                            <Row>                                
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled label="Total" value={amount.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Discount" autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Delivery Charges"  autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} value={otherCharges} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                 <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                       <TextField label="Cash Ledger" disabled  value={grandtotal.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>
                            
                        </Col>
                        
                    </Row>  
                    } */}
                </div>
            </div>
          </Paper> 
          <Modal
            size="lg"
            show={modal}
            // onHide={() => setModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header >
                <Modal.Title id="example-modal-sizes-title-lg">
               Select Combo product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {combotable?.map((item, index) => {
                    return (
                        <Row style={{marginTop: 20}}>
                            <Col xs={12} md={5}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Product</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.productid}
                                            label="Age"
                                            onChange={(e) => cbproductChange(e,index)}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {comboproductlist?.map((row, index) => {
                                            return (
                                            <MenuItem value={row.products_id}>{row.product_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Color and size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.fetch_id}
                                            label="Age"
                                            onChange={(e) => cbbatchChange(e,index)}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {combobatchlist[index].value.map((row, index) => {
                                            return (
                                            <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2}>
                               <label style={{fontSize: 10, color: 'red'}}>Max Quantiy = {item.quantity}</label>
                               <label style={{fontSize: 10, color: 'red'}}>stock = {item.stock}</label>
                            </Col>
                            <Col xs={12} md={2}>
                                <a href="javascript:void(0)" onClick={() => deletePopupitem(index)}>
                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                </a> 
                            </Col>
                            
                        </Row>
                    )
                })
            }
            <Row style={{marginTop: 20}}>
                <Col xs={12} md={2}>
                    <Button style={{height: 30}} variant="contained" onClick={() => addComborow()}>Add</Button>
                </Col>
            </Row>
            {combotable.length >= comboproductlist.length ?
            <Row style={{marginTop: 20}}>
                <Col xs={12} md={2}>
                    <Button style={{height: 30}} variant="contained" onClick={() => addtoTablerow()}>Submit</Button>
                </Col>
            </Row>: null}
            </Modal.Body>
        </Modal> 

        <Modal
            size="sm"
            show={imgPopup}
            onHide={() => setImgpopup(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
               Image
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <img  src={global.images + 'ecommerce_photos/' + imageurl} style={{width: 180, height: 180}}/>
            </Modal.Body>
        </Modal>

        <Modal
            size="lg"
            show={combomodal}
            onHide={() => setCombomodal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Combo Product Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Table responsive id="table-to-xls" bordered className="section-to-print">
                    <thead>
                        <tr>
                            <th>Sl#</th>
                            <th>Product Name</th>
                            <th>Color</th>
                            <th>Size</th>
                            <th>Qty</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {combolist.map((item, index) => {
                        return (
                            
                            <tr>
                            <td>
                                {index+1}
                            </td>
                            <td>
                                {item.product_name}
                            </td>
                            <td>
                                {item.color}
                            </td>
                            <td>
                                {item.size}
                            </td>
                            <td>
                                {item.quantity}
                            </td>
                            <td>
                                {item.fetch_isdelete == 0 ?
                                <a href="javascript:void(0)" onClick={() => deleteCombo(index, item.fetch_id, item.quantity)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                </a> : null}
                            </td>
                            </tr>
                        )
                        })}
                    </tbody>
                </Table>
                </div>
            </Modal.Body>
        </Modal>
        <Modal
            size="lg"
            show={comboaddmodal}
            // onHide={() => setComboaddmodal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Combo Product Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{marginTop: 20}}>
                    <Col xs={12} md={5}>
                        <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Product</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                   
                                    label="Age"
                                    onChange={(e) => cbproductChange2(e)}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {comboproductlist2.map((row, index) => {
                                    return (
                                    <MenuItem value={row.products_id}>{row.product_name}</MenuItem>
                                )})}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Color and size</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    // value={combotable2[0].pb_id}
                                    label="Age"
                                    onChange={(e) => cbbatchChange2(e)}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {combobatchlist2.map((row, index) => {
                                    return (
                                    <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                )})}
                                </Select>
                        </FormControl>
                    </Col>
                    {/* <Col xs={12} md={2}>
                    <label style={{fontSize: 10, color: 'red'}}>Max Quantiy = {combotable2[0].quantity}</label>
                    <label style={{fontSize: 10, color: 'red'}}>stock = {combotable2[0].stock}</label>
                    </Col>
                    <Col xs={12} md={2}>
                            <a href="javascript:void(0)" onClick={() => deletePopupitem2(0)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                            </a> 
                    </Col> */}
                    <Col xs={12} md={3}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={comboqty} label="Qty" disabled  id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                        <p style={{color: 'red', fontSize: 12}}>Stock : {combostock} </p>
                    </Col>

                </Row>
                {forhide != "" ?
                <Row style={{marginTop: 20}}>
                    <Col xs={12} md={2}>
                        <Button onClick={editcomboSubmit} style={{height: 30}} variant="contained">Submit</Button>
                    </Col>
                </Row> : null}
            </Modal.Body>
        </Modal>
        <Modal
            size="lg"
            show={totmodal}
            onHide={() => setTotmodal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Table responsive id="table-to-xls" bordered className="section-to-print">
                    <thead>
                        <tr>
                            <th>SL#</th>
                            <th>Date</th>
                            <th>Order Type</th>
                            <th>SO Invoice</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                    {balTotallist.map((item, index) => {
                        return (
                            <tr>
                                <td>
                                    {index+1}
                                </td>
                                <td>
                                    {item.salesorder_date}
                                </td>
                                <td>
                                    {item.ordertype_name}
                                </td>
                                <td>
                                    {item.salesorder_invoice}
                                    {item.sales_return == 1 || item.sales_return == 2 ? 
                                     <p>Replaced Bill ({item.salesorder_replace_invoiceno})</p> : null}
                                </td>
                                <td>
                                    {item.sales_return == 1 || item.sales_return == 2 ? item.salesorder_balancereceivable : item.salesorder_cashledger }
                                </td>
                            </tr>
                        )
                        })}
                        {/* <tr>
                            <td colSpan={2} style={{fontWeight: 'bold'}}>
                                Total
                            </td>
                            <td style={{fontWeight: 'bold'}}>
                                {balTotal}
                            </td>
                        </tr> */}
                    </tbody>
                </Table>
                </div>
            </Modal.Body>
        </Modal>
      </div>
    )
}
export default State;