
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";


const columns = [
    { id: '2', label: 'Quotation Number', minWidth: 50,span: false },
    { id: '3', label: 'Client.', minWidth: 50,span: false },
    { id: '4', label: 'Project', minWidth: 50,span: false },
    { id: '5', label: 'Date', minWidth: 50,span: false },
    { id: '7', label: 'Price', minWidth: 50,span: false },
    { id: '8', label: 'Action', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Quotationlist(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    
    const [value, setValue] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const [userId, setUserid] = React.useState("");
    const [modal, setModal] = React.useState(false);

    const [billDetails, setBillsetails] = React.useState([]);

    
    

  const SelectChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
      getData()
  }, [])

  const getData = () => {

    fetch(global.url + "QuotationList", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
    })
    .then((response) => response.json())
    .then((responseJson) => {
        setSource(responseJson.data)
    })
  }

    const deleteClick = (id) =>  {
      var data = {
        "quotation_id": id,
      }
      fetch(global.url + "QuotationDelete", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.message)
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Quotation List</p>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
           
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} >
                              <TableCell >
                                  {row.quotation_number}
                              </TableCell>
                              <TableCell >
                                  {row.quotation_client_name}
                              </TableCell>
                              <TableCell >
                                  {row.quotation_project_name}
                              </TableCell>
                              <TableCell >
                                  {row.quotation_date}
                              </TableCell>
                              <TableCell >
                                  {row.quotation_grandtotal}
                              </TableCell>
                              <TableCell >
                                    <a  href={'/transaction/Quotation/' + row.quotation_id} style={{color:'unset', textDecoration: 'none'}}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              </TableCell>
                               <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.quotation_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
           
           
          </Paper> 
      </div>
    )
}
export default Quotationlist;