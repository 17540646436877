import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';

const columns = [
    { id: '2', label: 'Date', minWidth: 50,span: false },
    { id: '3', label: 'Bill No.', minWidth: 50,span: false },
    { id: '4', label: 'Supplier', minWidth: 50,span: false },
    // { id: '5', label: 'Discount', minWidth: 50,span: false },
    { id: '7', label: 'Net Total', minWidth: 50,span: false },
    // { id: '8', label: 'File', minWidth: 50,span: false },
    { id: '9', label: 'Action', minWidth: 50,span: true },
  ];

function Purchasereport(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [date1, setDate1] = React.useState(new Date);
    const [date2, setDate2] = React.useState(new Date);
    const [supplier, setSupplier] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
      getData()
  }, [])

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company,
        "branchid" : branch,
        "from_date" : date1.toISOString().split('T')[0],
        "to_date" : date2.toISOString().split('T')[0],
        "supplier": supplier
      }
    fetch(global.url + "purchaseSingleReturnReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      // console.log("res rep", responseJson)
      if(responseJson.error == false){
        setSource(responseJson.data)
      }else{
        setSource([])
      }
    })
    .catch((error)=>{
      console.log(error)
  })
  }
  const filterDate = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var branch = JSON.parse(localStorage.getItem("branch"))
      var data = {
        "companyid": company,
        "branchid" : branch,
        "from_date" : date1.toISOString().split('T')[0],
        "to_date" : date2.toISOString().split('T')[0],
        "supplier": supplier
      }
    fetch(global.url + "purchaseSingleReturnReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      if(responseJson.error == false){
        setSource(responseJson.data)
      }else{
        setSource([])
      }
    })
    .catch((error)=>{
      console.log(error)
  })
  }

  const viewBillclick = (id) => {
    const win = window.open('/purchasereturnprint/'+ id, "_blank");
    win.focus();
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Purchase Return Report</p>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Row>
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="From"
                              value={date1}
                              onChange={(newValue) => {
                              setDate1(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="To"
                              value={date2}
                              onChange={(newValue) => {
                              setDate2(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={6} lg={3} style={{marginTop: 20}}>
                        <Button onClick={filterDate} style={{width: '50%', height: 40}} variant="primary">Filter</Button>
                    </Col>
                </Row>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} >
                              <TableCell >
                                  {row.purchase_date}
                              </TableCell>
                              <TableCell >
                                  {row.purchase_invoice}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_name}
                              </TableCell>
                              <TableCell >
                              {parseFloat(row.purchase_total)}
                              </TableCell>
                              <TableCell>
                                  <Button onClick={() => viewBillclick(row.purchase_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
      </div>
    )
}
export default Purchasereport;