
import React from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col } from "react-bootstrap";
import Button from '@mui/material/Button';
import BtnLoader from '../loader/btnLoader';


function Filemanager(props) {
  const [downloadlink, setDownloadlink] = React.useState("");
  const [bulkmodal, setBulkmodal] = React.useState(false);
  const [bulkfile, setBulkfile] = React.useState();
  const [bulkloader, setBulkloader] = React.useState(false);

  const changeBulkfile =(e) => {
    setBulkfile(e.target.files[0])
  }

  const onExport = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      companyid: company
    }
      fetch(global.url + "excelExportCurrentStock", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.error == false){
            setDownloadlink(responseJson.data)
        }
                
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const bulkClick = () => {
    setBulkloader(true)
    const formData = new FormData();
    formData.append('upload_file', bulkfile);
    fetch(global.url + "oldToNewImage", {
      method: "POST",
      body: formData,
    })
    .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.error == false) {
            setBulkloader(false)
            alert(responseJson.message)
            setBulkmodal(false)
        }
        else{
          setBulkloader(false)
          alert(responseJson.message)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            
            <div style={{margin: 40}}>
              <Row style={{marginTop: 20}}>
                    <Col md={6} >
                    <div>
                      <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Productlist Download</p>
                    </div> 
                    <div style={{marginTop: 20}}>
                      {
                            downloadlink == "" ? 
                            <Button style={{float: "left"}}  variant="contained">Start Excell Export</Button>:
                            <a style={{float: "left"}} href={global.excell + downloadlink}  target="_blank" download>
                                <Button  style={{float: 'right'}} variant="contained">Download File</Button>
                            </a>
                        }
                    </div>
                    </Col>
                    <Col md={6} >
                    <div>
                      <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Stock Adjustment Download</p>
                    </div> 
                    <div style={{marginTop: 20}}>
                        {
                            downloadlink == "" ? 
                            <Button style={{float: "left"}}  variant="contained">Start Excell Export</Button>:
                            <a style={{float: "left"}} href={global.excell + downloadlink}  target="_blank" download>
                                <Button  style={{float: 'right'}} variant="contained">Download File</Button>
                            </a>
                        }
                      </div>  
                    </Col>
                    <Col md={6} style={{marginTop: 40}}>
                    <div>
                      <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Bulk Image Upload</p>
                    </div> 
                    <div style={{marginTop: 20}}>
                        <Button style={{float: "left"}} onClick={() => setBulkmodal(true)} variant="contained">Upload</Button>
                      </div>  
                    </Col>
                 </Row>   
            </div>        
        </Paper> 
        <Modal
              size="lg"
              show={bulkmodal}
              onHide={() => setBulkmodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                     <p>Image Bulk Upload</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                          <label style={{width: '100%'}}>Select File</label>
                          <input type="file" onChange={(e) => changeBulkfile(e)}  style={{marginTop: 10}}/>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      {bulkloader ? 
                          <BtnLoader />:
                          <Button onClick={() => bulkClick()}  style={{float: 'right'}} variant="contained">Submit</Button>
                        }
                      <Button onClick={() => setBulkmodal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Filemanager;