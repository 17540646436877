import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


const columns = [
    { id: '1', label: 'Date', minWidth: 50, span: false },
    { id: '2', label: 'Order No#', minWidth: 50, span: false },
    { id: '3', label: 'Item No#', minWidth: 50, span: false },
    { id: '4', label: 'Delivered', minWidth: 50, span: false },
    { id: '5', label: 'Returned', minWidth: 50, span: false },    
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function OrderDelivery(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [source, setSource] = React.useState([]);
  const [userlist, setUserlist] = useState([])
  const [total, setTotal] = React.useState()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalOrder, setTotalOrder] = React.useState('');
  const [totalItems, setTotalItems] = React.useState('');
  const [totalDelivery, setTotalDelivery] = React.useState('');
  const [totalReturn, setTotalReturn] = React.useState('');
  const [salesmanid, setSalesmanid] = useState("")
  const [input, setInput] = useState(0);
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  

  const [loader, setLoader] = React.useState(false);

  useEffect(() => {
      getData()
      getUser()
    }, [input]);
    const userChange = (event) => {
      setSalesmanid(event.target.value)
      getData(event.target.value)
    }
    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        setUsercompanyid(company)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "branchid" : branch,
          'deliver_boy' : salesmanid,
          "page" : input*10,
          "limit": 10,

        }
        fetch(global.url + "OrderDeliveryReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setLoader(false)
              if(responseJson.error == false){
                setSource(responseJson.data.report)
                setTotalOrder(responseJson.data.total.total_orders)
                setTotalItems(responseJson.data.total.total_items)
                setTotalDelivery(responseJson.data.total.total_delivery)
                setTotalReturn(responseJson.data.total.total_return)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }
    const decrement = () => {
      setInput(prevCount => prevCount - 1);
      
    }

   const increment = () => {
      setInput(prevCount => prevCount + 1);
    
    }
    const getUser = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var company = JSON.parse(localStorage.getItem("company"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var data = {
        "companyid": company,
        "branchid" : branch
      }
      fetch(global.url + "viewDeliveryboy", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setUserlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    return (
        <div className="main-content">

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20,}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Order Delivery Report</p>
               <Row>
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              label="Age"
                              value={salesmanid}
                              onChange={userChange}
                              size="small"
                              
                            >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {userlist.map((item, index) => (
                                <MenuItem value={item.user_id}>{item.user_name}</MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                </Col>
              </Row>
            </div>
            <div style={{margin: 20,}}>
              <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {
                  source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.salesorder_date}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.oder_number}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.items_number}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.delivery_number}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.return_products}
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <Table  bordered responsive >
            <thead>
              <tr>
                <th>Total Order</th>
                <th>Total Items</th>
                <th>Total Delivery</th>
                <th>Total Return</th>
              </tr>
            </thead>
              <tbody>
                  <tr>
                    <td>{totalOrder}</td>
                    <td>{totalItems}</td>
                    <td>{totalDelivery}</td>
                    <td>{totalReturn}</td>
                  </tr>
              </tbody>
            </Table>
            </div>
          </Paper> 
       
        <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
           
      </div>
    )
}
export default OrderDelivery;