import React, {Component, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Row, Col, Form, Table, Modal  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as RiIcons from "react-icons/ri";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import {  RiBillLine } from "react-icons/ri";
import * as RI from "react-icons/ri";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import './payment-voucher.css'
import { getDateRangePickerDayUtilityClass } from '@mui/lab';
import { useParams, useHistory } from 'react-router-dom';
import { setDate } from 'date-fns';
import Loader2 from "react-loader-spinner";
import Autocomplete from '@mui/material/Autocomplete';
import { Typeahead } from 'react-bootstrap-typeahead';

// import Select from "react-select";

const top100Films = [
    { "label": 'The Shawshank Redemption', "year": 1994 },
    { "label": 'The Godfather', "year": 1972 },
    { "label": 'The Godfather: Part II', "year": 1974 },
    { "label": 'The Dark Knight', "year": 2008 },
    { "label": '12 Angry Men', "year": 1957 },
    { "label": "Schindler's List", "year": 1993 },
  ];

function Paymentvoucher(props) {
    const [btnloader, setBtnloader] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState('')
    const [userbranchid, setUserbranchid] = useState()
    const [usertype, setUsertype] = useState("")

    const [registerDate, setRegisterDate] = React.useState(new Date);
    const [refDate, setRefdate] = React.useState(new Date);
    const [refno, setRefno] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [billType, setBilltype] = React.useState('');
    const [invoice, setInvoice] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [paymode, setPaymode] = React.useState('');
    const [userId, setUserid] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const [allmodal, setAllmodal] = React.useState(false);
    const [againstmodal, setAgainstmodal] = React.useState(false);

    const [ledgercode, setLedgercode] = React.useState("");
    const [ledgername, setLedgername] = React.useState("");
    const [ledgerid, setLedgerid] = React.useState("");

    const [paymentcode, setPaymentcode] = React.useState("");
    const [paymentname, setPaymentname] = React.useState("");
    const [paymenttype, setPaymenttype] = React.useState("");
    const [paymentamount, setPaymentamount] = React.useState("");
    const [paymentaccid, setPaymentaccid] = React.useState('');

    const [accountlist, setAccountlist] = React.useState([]);
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [allaccountlist, setAllaccountlist] = React.useState([]);
    const [purchaselist, setPurchaselist] = React.useState([]);
    const {id} = useParams()
    const [paymentId, setPaymentid] = React.useState('');
    const [editmode, setEditmode] = React.useState(false);
    const [amount, setAmount] = React.useState("");
    const [tableRow, setTablerow] = React.useState([{key: "", pay_code: "", pay_account_id: "", pay_amount: "0", transaction_remarks:"", pay_account_name: "", pay_type: "", transaction_type: "other", pay_type: 'Cash', pay_purchase_fk: ""}])
    const [total, setTotal] = React.useState("");
    const [purchaseid, setPurchaseid] = React.useState("");
    const [billinvoice, setBillinvoice] = React.useState("");

    const [bpaymode, setBpaymode] = React.useState("");
    const [bank, setBank] = React.useState("");
    const [pdcamount, setPdcamount] = React.useState("");
    const [banklist, setBanklist] = React.useState([]);

    const [chequeno, setChequeno] = React.useState("");
    const [entrydate, setEntrydate] = React.useState(new Date);
    const [instrudate, setInstrudate] = React.useState(new Date);
    const [postdate, setPostdate] = React.useState(new Date);
    const [accbalance, setAccbalance] = React.useState("");
    let history = useHistory();
    const [wordEntered, setWordEntered] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const [type, setType] = React.useState("");

    const [pdcamt, setPdcamt] = useState("");
    const [pdcamt2, setPdcamt2] = useState("");

    const [selectedIndex, setSelectedindex] = React.useState("")
    const [debitTotal, setDebittotal] = useState(0);
    const [creditTotal, setCredittotal] = useState(0);
    const [cashamt, setCashamt] = useState(0);

    const [shownw, setShownw] = React.useState(false);
    const [detls, setDetls] = React.useState(false);

    const [selected, setSelected] = useState([]);
    const [begindate, setBegindate] = React.useState("");
    const [balance, setBalance] = React.useState("0");
    const [accnt, setAccount] = React.useState("");
    const [findedit, setFindedit] = React.useState(false);
    const [findedit2, setFindedit2] = React.useState(false);

    const [toDate, setTodate] = React.useState(new Date);
    const [fromDate, setFromdate] = React.useState(new Date);
    

    // const [selectedOptions, setSelectedOptions] = useState();


    //   const accountChange = (event, index) => {
    //     setSelectedOptions(event)
    //     var input = [...tableRow]
    //     input[index].pay_code = event[0].common_code
    //     input[index].pay_account_name = event[0].label
    //     input[index].pay_account_id = event[0].common_id
    //     input[index].pay_type = event[0].type
    // }

    useEffect(() => {
        beginDate()
      }, [])
    
    const beginDate = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid": company
        }
        fetch(global.url + "beginDate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false){
            setBegindate(responseJson.data.company_begindate)
        
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()  => {
        if(selected.length == 0){
            setLedgerid("")
            setLedgername("")
            setLedgercode("")
            setBalance("")
        } else {
            setLedgerid(selected[0].ledger_id)
            setLedgername(selected[0].label)
            setLedgercode(selected[0].ledger_code)
        }

    }, [selected])

    useEffect(() => {
        ledgerFilter()
    }, [ledgerid])

    const ledgerFilter = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
          "companyid" : company,
          "ledger_id":  ledgerid,
          "voicherfrom": fromDate.toISOString().split('T')[0],
          "voicherto": toDate.toISOString().split('T')[0]
        }
        fetch(global.url + "viewLedgerbillReportAgainstBalance", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == "false"){
                setBalance(responseJson.total_data.closingbalance)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    const accountChange = (event, index) => {
        var input = [...tableRow]
        input[index].pay_code = event[0].common_code
        input[index].pay_account_name = event[0].label
        input[index].pay_account_id = event[0].common_id
        input[index].pay_type = event[0].type
    }

    const pdcamtChange = (e) => {
        setPdcamt(e.target.value)
    }

    const pdcamtChange2 = (e) => {
        setPdcamt2(e.target.value)
    }

    useEffect(() => {
        calcTotal()
    }, [tableRow]);

    useEffect(() => {
        var data = parseFloat(pdcamount) - parseFloat(pdcamt)
        setPdcamt2(data)
    }, [pdcamt, pdcamt2])

    const pdcamountChange = (e) => {
        setPdcamount(e.target.value)
        setPdcamt(e.target.value)
    }

    useEffect(() => {

        if(parseFloat(pdcamount) < parseFloat(pdcamt)){
            alert("Exceed")
        }
    
    }, [pdcamt, pdcamount])
   
    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        setType(event.target.value)
        // getInvoiceno(event.target.value)
      };

      const debitChange = (event, key) => {
        var input = [...tableRow]
        input[key].pay_amount = event.target.value
        setTablerow(input)
    }

    const narrationChange = (event, key) => {
        var input = [...tableRow]
        input[key].transaction_remarks = event.target.value
        setTablerow(input)
    }

    const calcTotal = () => {
        var debitTotal = 0;
        var creditTotal = 0
        for(var i=0; i<tableRow.length; i++) {
              debitTotal += parseInt(tableRow[i].pay_amount)
              creditTotal += parseInt(tableRow[i].pay_amountcredit) 
        }
        setDebittotal(debitTotal)
        setCredittotal(creditTotal)
    }

      const paymodeChange = (event) => {
        setPaymode(event.target.value);
        setBpaymode("")
      };

      const bpaymodeChange = (event) => {
        setBpaymode(event.target.value);
      };

      const getBalance = (id) => {
        const data = {
            "ledgerid": id,
          }
          fetch(global.url + "accountBalanceByBank", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setAccbalance(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
              
          })
      }

      const bankChange =(event) => {
        setBank(event.target.value)
        const data = {
            "bankmasterid": event.target.value,
          }
          fetch(global.url + "accountByBankMaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setLedgerid(responseJson.data.bankmaster_ledgername)
              setLedgercode(responseJson.data.bankmaster_bankcode)
              setLedgername(responseJson.data.bankmaster_bankcode)
              getBalance(responseJson.data.bankmaster_ledgername)
          })
          .catch((error)=>{
              console.log(error)
              
          })
      }

      const selectAccount = () => {
        setModal(true)
        getAccountlist()
      }

      const getAccountlist = () => {
        const data = {
          "payment_mode": paymode,
        }
        fetch(global.url + "viewPaymentByMode", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setAccountlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
            
        })
      }
      useEffect(() => {
        getTotalammount()
      },[tableRow])

      useEffect(() => {
        getBilltype()
        if(id == "add") {
            getInvoiceno()
            setEditmode(false)
        }
        else{
           getDetailsbyid(id)
           setEditmode(true)
        }
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        getbankdata()
      }, []);


      const getbankdata =() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
          "usertype": userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewBankmaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.status == "false") {
                setBanklist(responseJson.data)
              }
              else{
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getTotalammount = () => {
          var totalAmount = 0;
          for (var i = 0; i < tableRow.length; i++) {
            totalAmount += parseInt(tableRow[i].pay_amount)
         }
            setAmount(totalAmount)
      }

      const getInvoiceno = () => {
        fetch(global.url + "paymentinvoiceno", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
                setInvoice(responseJson.data.invoiceno)
                setInvoNu(responseJson.data.invoiceno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getDetailsbyid = (id) => {
            const data = {
                "payment_id": id,
            }
            fetch(global.url + "viewPayment", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                if(responseJson.status == "false") {
                    setFindedit(true)
                    setFindedit2(true)
                    setInvoice(responseJson.data.slno)
                    setRegisterDate(responseJson.data.date)
                    setPaymode(responseJson.data.payment_mode)
                    setLedgercode(responseJson.data.payment_account_code)
                    setAccount(responseJson.data.account)
                    setLedgername(responseJson.data.account)
                    setBalance(responseJson.data.balance1)
                    if(responseJson.data.details){
                        setTablerow(responseJson.data.details)
                    }
                    setRemarks(responseJson.data.payment_remarks)
                    setPaymentid(responseJson.data.payment_id)
                    setLedgerid(responseJson.data.payment_account_id_fk)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
        }
        fetch(global.url + "viewBillSetting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setBilltypelist(responseJson.data)              
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      
      const selectedAcc = (name, code, id) => {
            setLedgercode(code)
            setLedgername(name)
            setLedgerid(id)
            setModal(false)
      }

    const tableselectedAccount = (index, code, name, id, type) => {

        var input = [...tableRow]

        input[index].key = index
        input[index].pay_code = code
        input[index].pay_account_name = name
        input[index].pay_type = type
        input[index].pay_account_id = id

        setTablerow(input) 
        setAllmodal(false)       
        addRow()
        // setPaymentcode(code)
        // setPaymentname(name)
        // setPaymenttype(type)
        // setPaymentaccid(id)
   
        // setPaymentamount("")
        // setPurchaseid("")

        // setTablerow([{pay_code: paymentcode, pay_account_id: paymentaccid, pay_amount: paymentamount, pay_account_name: paymentname, pay_type: paymenttype, pay_purchase_fk: purchaseid}])
        // setPaymentcode("")
        // setPaymentname("")
        // setPaymenttype("")
        // setPaymentaccid("")
        // setPaymentamount("")
        // setPurchaseid("")
        // setTotal(paymentamount)
        // getPayment()
        
    }

    const addRow = () => {
        setFindedit(false)
        setTablerow([...tableRow, {key: "", pay_code: "", pay_account_id: "", pay_amount: "0", transaction_remarks:"", pay_account_name: "", pay_type: "", transaction_type: "other", pay_purchase_fk: ""}])
    }

    const addCode = (index) => {
        setSelectedindex(index)
        getAllaccountlist()
        setAllmodal(true)
    }

    useEffect(() => {
        getAllaccountlist()
    }, [])

    const getAllaccountlist = () => {
        
        fetch(global.url + "allTransctionList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setAllaccountlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
              
          })
    }

    const onSave = () =>{
        // var paymentdate;
        // var refdate;
        // if(editmode == true) {
        //     paymentdate = registerDate.split('T')[0]
        //     refdate = refDate.split('T')[0]
        // }
        // else{
        //     paymentdate = registerDate.toISOString().split('T')[0]
        //     refdate = refDate.toISOString().split('T')[0]
        // }
        setBtnloader(true)
        const data = {
            "payment_id": paymentId,
            "payment_user_id":userId,
            "invoice_no": invoice,
            "payment_date": registerDate,
            "reference_date" : registerDate,
            "bill_type": billType,
            "payment_mode": paymode,
            "payment_remarks": remarks,
            "pay_table": tableRow,
            "account_code": ledgercode,
            "account_name": ledgername,
            "payment_account_id_fk": ledgerid,
            "payment_branchid" : userbranchid,
            "payment_companyid" : usercompanyid,
            "bank_type" : bpaymode,
            "post_date": registerDate, 
            "chequeno": chequeno, 
            "cheque_date": registerDate, 
            "pdc_amount": pdcamount,
            "pdc_ledgerid": tableRow[0].pay_account_id,
            "type": tableRow[0].pay_type
        }
        fetch(global.url + "updatePayment", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setBtnloader(false)
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
            else{
                alert(responseJson.message)
                window.location.reload();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const deleteClick = (index, id) => {
        if(findedit == true){
            var input = [...tableRow]
            input.splice(index, 1)
            setTablerow(input)
            deleteFromApi(id)
        }else{
               var input = [...tableRow]
            input.splice(index, 1)
            setTablerow(input)
        }
      
    }

    const deleteFromApi = (id) => {
        if(window.confirm('Are you Want to delete this ? It will be deleted from your database')){
        var userdata = JSON.parse(localStorage.getItem("user"))
        const data = {
            "pay_detail_id" : id,
            "invoice": invoice,
            "userid": userdata.user_id
        }
        fetch(global.url + "deletepaymentinEdit", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        }else{
            window.location.reload()
        }
    }
    

    const getBill = () => {
        setAgainstmodal(true)

        const data = {
            "supplier_id": paymentaccid,
            "type": "supplier",
        }
        fetch(global.url + "againstTransaction", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false){
                setPurchaselist(responseJson.data)
            }
            else{
                alert("No Record Found")
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const billSelect = (total, id, bill) => {
        setPaymentamount(total)
        setPurchaseid(id)
        setBillinvoice(bill)
        setAgainstmodal(false)
    }

    const upClick = () => {
        setInvoice(preCount => preCount + 1)
    }

    const downClick = () => {
        if(invoice > 1){
            setInvoice(preCount => preCount - 1)
        }
    }

    const purchaseDetails = () => {
        var data = {
            'invoice_no' : invoice
          }
          fetch(global.url + "PaymentdetailByInvoiceno", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson) => {
              if(responseJson.error == false){
                setFindedit(true)
                setFindedit2(true)
                setInvoice(responseJson.data.slno)
                setRegisterDate(responseJson.data.date)
                setPaymode(responseJson.data.paymode)
                setLedgercode(responseJson.data.code)
                setLedgername(responseJson.data.account)
                setLedgerid(responseJson.data.accountid)
                setAccount(responseJson.data.account)
                setBalance(responseJson.data.balance1)
                setPaymentid(responseJson.data.payment_id)
                if(responseJson.data.details){
                    setTablerow(responseJson.data.details)
                }
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    useEffect(() => {
        seleteAccount()
    }, [selected])

    const seleteAccount = () => {
        fetch(global.url + "SelectAccount",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {

            if(responseJson.error == false){
                setFilteredData(responseJson.data)
            } else {
                setFilteredData([])
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleSearch = (event) => {
        const searchWord = event.target.value;
        setLedgername(searchWord);
        var data = {
            "name" : ledgername,
        }
        fetch(global.url + "SelectAccount",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.error == false){
                setFilteredData(responseJson.data)
            } else {
                setFilteredData([])
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const selectAccounts = (idCode, idName, idId) => {
        setLedgercode(idCode)
        setLedgername(idName)
        setLedgerid(idId)
        setFilteredData([])
    }

    const valueEdit = (id, index) => {
        var input  = [...tableRow]
        input[index].pay_amount = id
        setTablerow(input)
    }

    const modalClick = () => {
        setShownw(true)
    }

    const modalClick2 = () => {
        setDetls(true)
    }


  return (
        <div className="main-content">
            {/* <Button variant="contained" style={{float: 'right', marginBottom: 20}}  onClick={() => history.push("/Reports/PaymentReport")}>Report</Button> */}
            <div style={{background: '#6d37b9', width: '100%', padding: 10, display: 'flex'}}>
                <img src={require("../../../assets/images/icons/payment-voucher.svg").default} />
                <p style={{marginBottom: 'unset', color: '#fff', fontWeight: 'bold', marginLeft: 10}}>Payment Voucher</p>
            </div>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={1} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={invoice} onChange={(e) => setInvoice(e.target.value)} id="outlined-basic" label="SL No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={1}>
                            <div style={{marginTop: 22}}>
                            {invoNum == invoice ?
                                <Button style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>:
                                <Button onClick={upClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>}
                                <Button onClick={downClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleDown  color="White" fontSize={15} /></Button>
                            </div>
                        </Col>
                        <Col md={1}>
                            <Button onClick={purchaseDetails} style={{marginLeft: -26, marginTop: 28, backgroundColor: '#6d37b9', border: 'none', color: '#fff', height: 30}} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button>
                        </Col>
                        <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="Date"
                                        value={registerDate}
                                        minDate={new Date(begindate)}
                                        onChange={(newValue) => {
                                        setRegisterDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        {/* <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Pay Mode</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={paymode}
                                        label="Age"
                                        onChange={paymodeChange}
                                        size="small"  
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        <MenuItem value={"Cash"}>Cash Account</MenuItem>
                                        <MenuItem value={"Bank"}>Bank Account</MenuItem>
                                    </Select>
                            </FormControl>
                        </Col> */}
                        <Col md={5}>
                        {paymode == "Bank" ? 
                            <Row>
                                <Col md={4} style={{marginTop:30}}>
                                    <FormControl sx={{  width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bank Account</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={bank}
                                                label="Age"
                                                onChange={bankChange}
                                                size="small"  
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {banklist.map((row, index) => {
                                                return (
                                                        <MenuItem value={row.bankmaster_id}>{row.bankmaster_bankcode}</MenuItem>
                                                )})
                                            }
                                            </Select>
                                    </FormControl>
                                    <p style={{color: 'red'}}>Balance: {accbalance}</p>
                                </Col>
                                <Col md={6} style={{marginTop:30}}>
                                    <FormControl component="fieldset">
                                        <TextField autoComplete='off' type="number"  onChange={(e) => pdcamountChange(e)} value={pdcamount} id="outlined-basic" label="Amount" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>:
                            <Row>
                                {/* <Col md={3} style={{marginTop:30}}>
                                    <FormControl component="fieldset">
                                        <TextField disabled value={ledgercode} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col> */}
                                <Col md={8} style={{marginTop:30}}>
                                    {/* <div style={{position: 'relative'}}>
                                        <FormControl style={{width: '100%'}} component="fieldset">
                                            <TextField autoComplete='off' value={ledgername} onChange={handleSearch} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                                        </FormControl>
                                        {filteredData.length == 0 ? null:
                                            <div className="dataResult">
                                                {filteredData.map((value, key) => {
                                                    return (
                                                    <a onClick={() => selectAccounts(value.ledger_code, value.ledger_name, value.ledger_id)} className="searchItem" target="_blank">
                                                        <div style={{marginTop:10, display: 'flex'}}> 
                                                            <p style={{fontSize: 12, marginLeft: 10}}>{value.ledger_name}</p>
                                                        </div>
                                                        <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                                    </a>
                                                    );
                                                })}
                                            </div>
                                        }
                                    </div> */}
                                    {findedit2 == true ?
                                      <FormControl component="fieldset" onClick={() => setFindedit2(false)}>
                                            <TextField autoComplete='off' disabled value={accnt} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                                        </FormControl>:
                                        <Typeahead
                                            style={{width: '100%'}}
                                            id="basic-example"
                                            onChange={setSelected}
                                            options={filteredData}
                                            placeholder="Account"
                                            selected={selected}
                                        />}
                                </Col>
                                <Col md={4} style={{marginTop:30}}>
                                    <FormControl component="fieldset">
                                        <TextField autoComplete='off' disabled value={balance} id="outlined-basic" label="Balance" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row> }
                        </Col>
                    </Row>
                </Col>
            </Row>
            {paymode == "Bank" ? 
                    <Row>
                            <div>
                                <Row>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Transaction Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={bpaymode}
                                                    label="Age"
                                                    onChange={bpaymodeChange}
                                                    size="small"  
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                    <MenuItem value={"transfer"}>Transfer</MenuItem>
                                                    <MenuItem value={"pdc"}>PDC</MenuItem>
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    { bpaymode == "pdc" ? 
                                    <Col md={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="PDC Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>:
                                    <Col md={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>}
                                    <Col md={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Instrument Date"
                                                        value={postdate}
                                                        onChange={(newValue) => {
                                                        setPostdate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'  onChange={(e) => setChequeno(e.target.value)} value={chequeno} id="outlined-basic" label="Instrument No." variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" value={pdcamt} onChange={(e) => pdcamtChange(e)} label="Amount" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" label="PDC Note" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                </Row>
                                { parseFloat(pdcamount) > parseFloat(pdcamt) ?
                                <Row style={{marginTop: 20}}>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Transaction Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={bpaymode}
                                                    label="Age"
                                                    onChange={bpaymodeChange}
                                                    size="small"  
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                    <MenuItem value={"transfer"}>Transfer</MenuItem>
                                                    <MenuItem value={"pdc"}>PDC</MenuItem>
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    { bpaymode == "pdc" ? 
                                    <Col md={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="PDC Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>:
                                    <Col md={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>}
                                    <Col md={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Instrument Date"
                                                        value={postdate}
                                                        onChange={(newValue) => {
                                                        setPostdate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'  onChange={(e) => setChequeno(e.target.value)} value={chequeno} id="outlined-basic" label="Instrument No." variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" value={pdcamt2} onChange={(e) => pdcamtChange2(e)}  label="Amount" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" label="PDC Note" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                </Row>: 
                                null}
                            </div>
                    </Row>: 
                    null
                }
            <Row style={{marginTop: 30}}>
                {purchaseid != "" ? 
                    <p style={{fontSize: 10, color: 'green'}}>Against bill - {billinvoice}</p> : null
                    }
            </Row>
            {/* <Row >
                <Col sm={2} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField onClick={addCode} value={paymentcode} disabled id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={3} style={{marginTop:20}}>
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <TextField id="outlined-basic" disabled  value={paymentname} label="Account" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={2} style={{marginTop:20}}>
                    
                    <FormControl component="fieldset">
                        <TextField id="outlined-basic" type="number" value={paymentamount} onChange={(e) => setPaymentamount(e.target.value)} label="Amount" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                {bpaymode == "pdc" && tableRow.length >= 1 ?
                    null : 

                    <Col sm={2} style={{marginTop:20}}>
                        <Button onClick={addRow} variant="outlined">ADD ACCOUNT</Button>
                    </Col>
                }
                
            </Row> */}
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    {/* <Table bordered>
                        <thead>
                            <tr>
                            <th>SlNo</th>
                            <th style={{width: '20%'}}>Account Code</th>
                            <th style={{width: '50%'}}>Account</th>
                            <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((row, index) => {
                                        return (
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{row.pay_code}</td>
                                                <td>{row.pay_account_name}</td>
                                                <td><TextField id="outlined-basic" type="number" value={row.pay_amount} onChange={(e) => valueEdit(e.target.value, index)} variant="outlined"  size="small"/></td>
                                                <td >
                                                    <a href="javascript:void(0)" onClick={() => deleteClick(index, row.pay_detail_id, row.payment_invoice_no)}>
                                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                                    </a>
                                                </td>
                                            </tr>
                                        )}
                                    )}
                                    <tr>
                                        <td colSpan="3" style={{textAlign: 'right', fontWeight: 'bold'}}>Total</td>
                                        <td>{amount}</td>
                                    </tr>
                        </tbody>
                    </Table> */}
                     <Table bordered>
                                <thead>
                                    <tr>
                                        {/* <th style={{width: 100}}>SlNo</th> */}
                                        {/* <th style={{width: 150}}>Code</th> */}
                                        <th style={{width: "0%"}}>Account</th>
                                        <th style={{width: '0%'}}>Amount</th>
                                        <th style={{width: '0%'}}>Transaction Type</th>
                                        <th style={{width: '0%'}}>Narration</th>
                                        <th style={{width: "0%"}}></th>
                                  
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr>
                                                {/* <td>{index+1}</td> */}
                                                {/* <td>
                                                    <div onClick={() => addCode(index)} className='contrabox'>
                                                        {item.pay_code}
                                                    </div>
                                                </td> */}
                                                <td style={{position: 'relative'}}>
                                                {/* <div className='contrabox'>{item.pay_account_name}</div> */}
                                                     {/* <input style={{width: 200}}  value={ledgername} onChange={handleSearch} autocomplete="off"  type="text"  />
                                                    {allaccountlist.length == 0 ? null:
                                                        <div className="dataResult" style={{width: '97%'}}>
                                                            {filteredData.map((value, key) => {
                                                                return (
                                                                <a className="searchItem" target="_blank">
                                                                    <div style={{marginTop:10, display: 'flex'}}> 
                                                                        <p style={{fontSize: 12, marginLeft: 10}}>{value.common_name}</p>
                                                                    </div>
                                                                    <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                                                </a>
                                                                );
                                                            })}
                                                        </div>
                                                    } */}
                                                    {/* <Autocomplete
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        options={filteredData.map((option) => option.ledger_name)}
                                                        renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Code"
                                                            InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            }}
                                                        />
                                                        )}
                                                    /> */}
                                                    {item.pay_account_name == "" ?
                                                     <Typeahead
                                                        style={{width: 220, boxShadow: 'none'}}
                                                        id="basic-example"
                                                        onChange={(e) => accountChange(e, index)}
                                                        options={allaccountlist}
                                           
                                                        />:
                                                        <input autocomplete="off" disabled  value={item.pay_account_name} />
                                                    }

                                                        {/* <Select
                                                            options={allaccountlist}
                                                            placeholder="Select color"
                                                            value={selectedOptions}
                                                            onChange={(e) => accountChange(e, index)}
                                                            isSearchable={true}
                                                        
                                                        /> */}
                                                </td>

                                                <td>
                                                    <div style={{display: 'flex'}}>
                                                    <input autocomplete="off"  type="number" value={item.pay_amount} onChange={(event) => debitChange(event, index)} />
                                                        {/* <Button onClick={modalClick} >< RiBillLine color="#000" fontSize={20} style={{marginRight: 10}} /></Button> */}
                                                        {/* <Button onClick={getBill} >< RiBillLine color="#6d37b9" fontSize={20} /></Button> */}
                                                    </div>
                                                
                                                </td>
                                                <td style={{width :0}}>
                                                    <div style={{display: 'flex'}}>
                                                        <FormControl sx={{  width: 200 }}>
                                                            {/* <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Trasaction Type</InputLabel> */}
                                                            <Select
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                value="other"
                                                                label="Age"
                                                                onChange={paymodeChange}
                                                                size="small"  
                                                            >
                                                            {/* <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem> */}
                                                            {/* <MenuItem value={"transfer"}>Transfer</MenuItem>
                                                            <MenuItem value={"pdc"}>PDC</MenuItem>
                                                            <MenuItem value={"cdc"}>CDC</MenuItem> */}
                                                            <MenuItem value={"other"}>Other</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* <Button onClick={modalClick2} >< RiBillLine color="#000" fontSize={20} style={{marginRight: 10}} /></Button> */}
                                                    </div>
                                                </td>
                                                <td><input autocomplete="off" style={{width: 250}}  type="text" value={item.transaction_remarks} onChange={(event) => narrationChange(event, index)} /></td>
                                                <td>
                                                    <a href="javascript:void(0)" onClick={() => deleteClick(index, item.pay_detail_id)}>
                                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td >Total</td>
                                        <td>
                                        <input autocomplete="off"  type="number" disabled value={parseFloat(debitTotal)?.toFixed(2)}/>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            {/* {parseFloat(creditTotal)?.toFixed(2)} */}
                                        </td>
                                        <td> 
                                     
                                            <ul className="list-inline" style={{marginBottom: 'unset'}}>
                                                <li>
                                                {
                                                    btnloader ? 
                                                    <Loader2
                                                        type="Oval"
                                                        color="#38B0DE"
                                                        height={30}
                                                        width={30}
                                                        style={{float: 'left', marginTop:20}}
                                                    />:
                                                    <Button onClick={onSave} style={{height: 25, fontSize: 12}} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button>}
                                                </li>
                                        
                                                <li>
                                                    <Button onClick={addRow} style={{height: 25, fontSize: 12}} variant="outlined">Add</Button>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                </Col>
            </Row>
            {/* <div>
            {bpaymode == "pdc" && tableRow.length >= 1 ?
                    null : 

                    <Col sm={2} style={{marginTop:20}}>
                        <Button onClick={addRow} variant="outlined">ADD ACCOUNT</Button>
                    </Col>
                }
            </div> */}
            {/* <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <TextareaAutosize 
                    value={remarks}  onChange={(e) => setRemarks(e.target.value)}
                        minRows={3}
                        placeholder="Remarks"
                        style={{background: 'transparent', width: '100%'}}
                    />
                </Col>
            </Row> */}
            
            {/* <Row style={{marginTop: 20}}>
                <Col xs={12} >
                    <ul className="list-inline">
                        <li>
                        {
                            btnloader ? 
                            <Loader2
                                type="Oval"
                                color="#38B0DE"
                                height={30}
                                width={30}
                                style={{float: 'left', marginTop:20}}
                            />:
                            <Button onClick={onSave} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button>}
                        </li>
                        <li><Button onClick={purchaseDetails} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                        <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                        <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                        <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li>
                    </ul>
                </Col>
            </Row> */}

            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {accountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>selectedAcc(row.ledger_name, row.ledger_code, row.ledger_id)}>
                                        <li className="pay-lists" style={{color: '#000',  marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.ledger_name}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={allmodal}
                onHide={() => setAllmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <FormControl sx={{ mt: 1, width: '100%' }}>
                                <TextField  id="outlined-basic" label="Search" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12}>
                            <ul>
                            {allaccountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>tableselectedAccount(selectedIndex, row.common_code, row.common_name, row.common_id, row.type)}>
                                        <li className="pay-lists" style={{color: '#000', marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.common_name} - {row.type}</li>
                                    </a>
                                )})
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={againstmodal}
                onHide={() => setAgainstmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {purchaselist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => billSelect(row.purchase_total, row.purchase_id, row.purchase_invoice)}>
                                        <li className="pay-lists" style={{color: '#000',  marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.purchase_invoice} - {row.purchase_date} - {row.purchase_total} - {row.status}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={shownw}
                onHide={() => setShownw(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Allocate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <FormControl component="fieldset">
                                <TextField id="outlined-basic" type="number" label="Inv No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={4}>
                            <FormControl component="fieldset">
                                <TextField id="outlined-basic" type="number" label="Sup.Inv No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={4}>
                            <FormControl component="fieldset">
                                <TextField id="outlined-basic" disabled type="number" label="Allocated Amt" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Inv Date</th>
                                        <th>Inv No.</th>
                                        <th>Sup.Inv No</th>
                                        <th>Inv Amt</th>
                                        <th>Paid Amt</th>
                                        <th>Balance</th>
                                        <th>Current Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button style={{backgroundColor: '#6d37b9', float: 'right', color: '#fff'}} variant="primary">Save</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={detls}
                onHide={() => setDetls(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <FormControl component="fieldset">
                                <TextField id="outlined-basic" type="number" label="Instrument Num" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={4}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Instrument Date"
                                            value={instrudate}
                                            onChange={(newValue) => {
                                                setInstrudate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col md={4}>
                            <FormControl component="fieldset">
                                <TextField id="outlined-basic" disabled type="number" label="Instrument Note" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button style={{backgroundColor: '#6d37b9', float: 'right', color: '#fff', marginTop: 20}} variant="primary">Save</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
  );
}

export default Paymentvoucher;
