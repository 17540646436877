import React, {Component, useEffect, useState, useRef} from 'react';
import './InvoiceBill.css';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";


function Purchaseorderinvoice() {

    const [tabledata, setTabledata]=useState([])
    const [tabledetails, setTabledetails]=useState({})



    useEffect(() => {
        getInvoice()
    },[])

    const getInvoice = () => {
        var data={
            "purchaseorder_id" : 2
        }
        fetch(global.url + 'PurchaseorderPrint', {
            method:"POST",
            headers:{
                'Content-Type':'application/json',
                'APPKEY':'erp6129'
            },
            body:JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            // console.log('respon',responseJson )
            if(responseJson.error == false){
                setTabledata(responseJson.data.details)
                setTabledetails(responseJson.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    

  return (
    <div>
         <Container fluid >
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        
                        <div className="invoice-box ">
                            <div className="invoice-inside">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>{global.proname}</p>
                                <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Purchase Order Invoice</p>
                                <Row>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>معاملة المورد</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Supplier: {tabledetails.purchaseorder_supplier}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رقم الفاتورة</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Invoice NO: {tabledetails.purchaseorder_invoice}</p>
                                </Col>
                                
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Date: {tabledetails.purchaseorder_date}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Mode/Term of Payment: Cash</p>
                                </Col>
                               
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>من خلال إيفاد</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Despatch through: Qatar</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>شروط التوصيل</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Terms of Delivery: Qatar</p>
                                </Col>
                                </Row> 
                                <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div>
                                <div>
                                    <Table bordered className='invoicetable'>
                                        <thead>
                                            <tr>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>SLNo.</p>
                                            </th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Product</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Qty</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Price</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Total</p></th>
                                            </tr>
                                        </thead>
                                      
                                            
                                                
                                
                                                <tbody>
                                                    {
                                                        tabledata.map((value,index) => {
                                                            return(
                                                                <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{value.productname}</td>
                                                                    <td>{value.qty}</td>
                                                                    <td>QAR {value.price}</td>
                                                                    <td>QAR {value.pqd_total_amount}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                 
                                                              
                                                    <tr>
                                                            <td colSpan={3}></td>
                                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Sub Total</p></th>
                                                            <td>{tabledetails.subtotal}</td>
                                                    </tr>          
                                                         
                                                     <tr>
                                                            <td colSpan={3}></td>
                                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رسوم التوصيل الإضافية</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Extra Delivery Charges</p></th>
                                                            <td>QAR {tabledetails.othercharges}</td>
                                                    </tr>
                                                    
                                                    <tr>
                                                            <td colSpan={3}></td>
                                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المبلغ الإجمالي</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Total Amount</p></th>
                                                            <td>QAR {tabledetails.total}</td>
                                                            
                                                    </tr>
                                                </tbody> 
                                               
                                            
                                       
                                    </Table>
                                </div>
                                <div style={{marginTop: 30}} className="invoicebill">
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>{global.proname}</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Doha, Qatar</p>
                                </div>   
                                
                            </div>
                        </div>
                    </Col>
                    </Row>
            </Container>
    </div>
  )
}

export default Purchaseorderinvoice