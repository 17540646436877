import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col,} from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';

const columns = [
    { id: '1', label: 'Sl#', minWidth: 50,span: false },
    { id: '1', label: 'Voucher No', minWidth: 50,span: false },
    { id: '1', label: 'Transaction Name', minWidth: 50,span: false },
    // { id: '1', label: 'Batch code', minWidth: 50,span: false },
    { id: '1', label: 'Bar code', minWidth: 50,span: false },
    { id: '1', label: 'Ref code', minWidth: 50,span: false },
    { id: '2', label: 'Product Name', minWidth: 100,span: false },
    { id: '3', label: 'Color', minWidth: 50,span: false },
    { id: '4', label: 'Size', minWidth: 50,span: false },
    { id: '5', label: 'Stock In', minWidth: 50,span: false },
    { id: '5', label: 'Stock Out', minWidth: 50,span: false },
    { id: '5', label: 'Current Stock', minWidth: 50,span: false },
  ];

  const rows = [
    {
      demo: 'test',
    }
  ];

function StockInOut() {
    const [source, setSource] = useState([]) 
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [transaction, setTransaction] = React.useState("");
    const [transactionlist, setTransactionlist] = React.useState([]);
    const [transactionno, setTransactionno] = React.useState("");
    const [loader, setLoader] = React.useState(false);

    const [stockintotal, setStockintotal] = React.useState("");
    const [stockouttotal, setStockouttotal] = React.useState("");
    const [currenttotal, setCurrenttotal] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const transactionChange = (event) => {
    setTransaction(event.target.value)
  }

  useEffect(() => {
    getTransactionlist()
  }, []);

  const getTransactionlist = () => {
    fetch(global.url + "viewAllTransactions", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setTransactionlist(responseJson.data)
    })
  }

  const transactionFilter = () => {
    setLoader(true)
    var data = {
      "voucherno":  transactionno,
      "transaction": transaction,     
    }
    fetch(global.url + "stockINOUTSearch", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        setLoader(false)
          setSource(responseJson.data)
          setStockintotal(responseJson.total.stockin)
          setStockouttotal(responseJson.total.stockout)
          setCurrenttotal(responseJson.total.currentstock)
      })
      .catch((error)=>{
          console.log(error)
      })
}

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Stock In And Out Report</p>
               
            </div>
      
            <Row style={{margin: 20}}>
              <Col md={3}>
                  <FormControl sx={{ mt: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-helper-label">Transaction</InputLabel>
                    <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={transaction}
                          label="Age"
                          onChange={transactionChange}
                          size="small"
                      >
                      <MenuItem value="">
                          <em>None</em>
                      </MenuItem>
                      {transactionlist.map((row, index) => {
                          return (
                          <MenuItem value={row.tran_id}>{row.tran_name}</MenuItem>
                      )})}
                    </Select>
                </FormControl>
              </Col>
              <Col xs={12} md={2}>
                    <FormControl sx={{mt:1, width: '100%'}}>
                        <TextField name="Zone"
                        onChange={(e) => setTransactionno(e.target.value)}
                        value={transactionno}
                        autoComplete="off" id="outlined-basic" label="Invoice" variant="outlined"  size="small"/>
                    </FormControl>
              </Col>
              <Col xs={12} md={2}>
                  <Button onClick={transactionFilter} style={{width: '100%', height: 45, marginTop: 3}} variant="contained">Filter</Button>
              </Col>
            </Row>
    
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                <TableContainer sx={{ maxHeight: 440, mt: 2, }}>
              {loader ? <Loader /> :
                <Table id="table-to-xls" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                          colSpan={column.span == true ? 2: 1}
                        >
                          {column.label}
                          
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {
                    source
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                            <TableRow  tabIndex={-1} key={row.code}>
                            <TableCell >
                                {index + 1}
                            </TableCell>
                            <TableCell >
                                {row.vouchernumber}
                            </TableCell>
                            <TableCell >
                                {row.tran_name}
                            </TableCell>
                            {/* <TableCell >
                                {row.batchcode}
                            </TableCell> */}
                            <TableCell >
                                {row.pb_inter_barcode}
                            </TableCell>
                            <TableCell >
                                {row.product_refcode}
                            </TableCell>
                            <TableCell >
                                {row.product_name}
                            </TableCell>
                            <TableCell >
                                {row.color_name}
                            </TableCell>
                            <TableCell >
                                {row.size_name}
                            </TableCell>
                            <TableCell >
                                {row.stockin}
                            </TableCell>
                            <TableCell >
                                {row.stockout}
                            </TableCell>
                            <TableCell >
                                {row.currentstock}
                            </TableCell>
                        </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableHead>
                      <TableRow>
                      <TableCell colSpan={8}>Total</TableCell>
                        <TableCell >
                            {stockintotal}
                        </TableCell>
                        <TableCell >
                        {stockouttotal}
                        </TableCell>
                        <TableCell >
                        {currenttotal}
                        </TableCell>
                    </TableRow>
                  </TableHead>
         
                </Table>
              }
              </TableContainer>
                        {/* <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={source.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                </Col>
            </Row>
          </Paper> 
      </div>
    )
}
export default StockInOut;