import '../Dashboard/dashboard.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import { Row, Col, Table, Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

function Allinone(props) {
    let history = useHistory();
    const [modal, setModal] = useState(false);
  const [source, setSource] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  return (
        <div className="main-content">
            <div sx={{ width: '100%', overflow: 'hidden' }}>
                <p style={{fontSize: 23, fontWeight: 'bold'}}>All In One Report</p>
                <Row>
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/StockReport/CurrentStockReport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginRight: 10, marginLeft: 10,  }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Current Stock</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col>
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/StockEntries/Historystock")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginRight: 10, marginLeft: 10,  }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>History Stock</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col>  
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/stockvalue")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginRight: 10, marginLeft: 10,  }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Stock Value</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/stockinout")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginRight: 10, marginLeft: 10,  }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Stock In And Out</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/detailedstock")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginRight: 10, marginLeft: 10,  }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Detailed Stock</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/digitalwarehousereport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginRight: 10, marginLeft: 10,  }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Replaced Items</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/Salesorderreport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginRight: 10, marginLeft: 10,  }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Sales Order</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/SalesReport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Sales</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/userwisesalesorder")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Userwise Sale</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/salesreturn")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Sales Return</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/orderdelivery")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Order Delivery</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/deliveryexecutive")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Delivery Executive</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/cancelorder")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Cancel Order</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/cancelproduct")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Cancel Product</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/transaction/Quotationlist")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Quatation List</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/Purchaseorderreport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Purchase Order</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/PurchaseReport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 8}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Purchase</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/JournalReport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Journal</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/PaymentReport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Payment</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/ReceiptReport")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Receipt</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/cashbook")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Cash Book</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/bankbook")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Bank Book</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/ledgersummary")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Ledger</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/ledgerdetails")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Ledger Details</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/daybookdetails")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 12}}>Daybook</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/profitandloss")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Profit And Loss</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/trialbalance")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Trial Balance</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                    <Col sm={2} style={{marginTop: 10}}>
                        <a onClick={() => history.push("/Reports/balancesheet")} style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 175, height: 50, marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body" style={{background: '#bebee0', height: 50, display: 'flex', padding: 4}}>
                                    <Row style={{ width: '114%'}}>
                                        <Col xs={3}>
                                            <div className='allin'>
                                                In
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <h6 style={{color: '#fff', fontSize: 13, marginTop: 5}}>Balance Sheet</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='allout'>
                                                Out
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </a>
                    </Col> 
                </Row>
                    <div>
                        <Row style={{marginTop: 30}}>
                            <Col sm={2}>
                                <div className="card w_data_1" style={{background: '#bdabc7', }}>
                                    <div className="card-body" style={{background: '#bdabc7', padding: 5}}>
                                        <div className='allin2'>
                                            In
                                        </div>
                                        <div>
                                            <span style={{color: '#fff', marginTop: 10}}><strong>DAILY</strong></span>
                                            <h4 style={{color: '#fff'}} class="mt-3 animate-charcter">Qar 0</h4>
                                        </div>
                                        <div className='allout2'>
                                            Out
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2}> 
                            
                                <div className="card w_data_1" style={{background: '#81bce4', }}>
                                    <div className="card-body" style={{background: '#81bce4', padding: 5}}>
                                        <div className='allin2'>
                                            In
                                        </div>
                                        <div>
                                        <span style={{color: '#fff'}}><strong>MONTHLY</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3 animate-charcter">Qar 0</h4>
                                        </div>
                                        <div className='allout2'>
                                            Out
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2}>
                            
                                <div className="card w_data_1" style={{background: '#dcc8c3'}}>
                                    <div className="card-body" style={{background: '#dcc8c3', padding: 5}}>
                                        <div className='allin2'>
                                            In
                                        </div>
                                        <div>
                                            <span style={{color: '#fff'}}><strong>YEARLY</strong></span>
                                            <h4 style={{color: '#fff'}} class="mt-3 animate-charcter">Qar 0</h4>
                                        </div>
                                        <div className='allout2'>
                                            Out
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="card w_data_1" style={{background: '#bebee0', }}>
                                    <div className="card-body" style={{background: '#bebee0', padding: 5}}>
                                        <div className='allin2'>
                                            In
                                        </div>
                                        <div>
                                            <span style={{color: '#fff', marginTop: 10}}><strong>DAILY QTY</strong></span>
                                            <h4 style={{color: '#fff'}} class="mt-3">0</h4>
                                        </div>
                                        <div className='allout2'>
                                            Out
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2}> 
                                <div className="card w_data_1" style={{background: '#6f9a9b', }}>
                                    <div className="card-body" style={{background: '#6f9a9b', padding: 5}}>
                                        <div className='allin2'>
                                            In
                                        </div>
                                        <div>
                                            <span style={{color: '#fff'}}><strong>MONTHLY QTY</strong></span>
                                            <h4 style={{color: '#fff'}} class="mt-3">0</h4>
                                        </div>
                                        <div className='allout2'>
                                            Out
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="card w_data_1" style={{background: '#9acf9b'}}>
                                    <div className="card-body" style={{background: '#9acf9b', padding: 5}}>
                                        <div className='allin2'>
                                            In
                                        </div>
                                        <div>
                                            <span style={{color: '#fff'}}><strong>YEARLY QTY</strong></span>
                                            <h4 style={{color: '#fff'}} class="mt-3">0</h4>
                                        </div>
                                        <div className='allout2'>
                                            Out
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                    </div>
            </div>
        </div>
  );
}

export default Allinone;
