import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../../loader/loader';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const columns = [
    { id: '1', label: 'Voucher Date', minWidth: 50,span: false },
    { id: '2', label: 'Voucher No', minWidth: 50,span: false },
    { id: '3', label: 'Voucher Type', minWidth: 50,span: false },
    { id: '3', label: 'Code', minWidth: 50,span: false },
    { id: '3', label: 'Accounts', minWidth: 50,span: false },
    { id: '3', label: 'Debit', minWidth: 50,span: false },
    { id: '3', label: 'Credit', minWidth: 50,span: false },
    
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Daybook(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

    const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');

  const [source, setSource] = React.useState([]);
  

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)

  const [dateFrom, setDatefrom] = React.useState(new Date)
  const [dateTo, setDateTo] = React.useState(new Date)

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
      getData()
    }, []);

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          // "voicher_from": dateFrom.toISOString().split('T')[0],
          // "voicher_to": dateTo.toISOString().split('T')[0]
        }
        fetch(global.url + "dayBookReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setLoader(false)
              if(responseJson.error == false){
                setSource(responseJson.data)
              }
              else{
                setSource([])
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const filter = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "voicher_from": dateFrom.toISOString().split('T')[0],
          "voicher_to": dateTo.toISOString().split('T')[0]
        }
        fetch(global.url + "dayBookReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{

              setLoader(false)
              if(responseJson.error == false){
              
                setSource(responseJson.data)
                
              }
              else{
                setSource([])
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Daybook Details</p>
               <Row>
                      <Col xs={12} md={5} style={{marginTop: 25}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="From"
                                  value={dateFrom}
                                  onChange={(newValue) => {
                                      setDatefrom(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={5} style={{marginTop: 25}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="To"
                                  value={dateTo}
                                  onChange={(newValue) => {
                                      setDateTo(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                            <Button onClick={filter}  style={{width: '50%', height: 45}} variant="contained">Filter</Button>
                      </Col>
                </Row>
            </div>
            <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table" id="table-to-xls">
                <thead className='headerpos1'>
                  <tr>
                    {columns.map((column) => (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </td>
                    ))}
                  </tr>
                </thead>
               
                    {source.map((row, key) => {
                          return (
                            <tbody>
                              {
                            row.multiple.map((r, k) => {
                                  return(
                                    k == 0 ? 
                                    <>
                                   {r.cb_debit == 0 && r.cb_credit == 0 ? null :
                                     <tr >
                                          <td rowSpan={row.multiple.length} style={{padding: 10}}>{row.voucherdate}</td>
                                          <td rowSpan={row.multiple.length} style={{padding: 10}}>{row.voucherno}</td>
                                          <td rowSpan={row.multiple.length} style={{padding: 10}}>{row.vouchertype}</td>
                                          <td  style={{padding: 10}}>{r.ledger_code}</td>
                                          <td  style={{padding: 10}}>{r.ledger_name}</td>
                                          <td  style={{padding: 10}}>{r.cb_debit == null ? null : parseFloat(r.cb_debit)?.toFixed(2)}</td>
                                          <td  style={{padding: 10}}>{r.cb_credit == null ? null : parseFloat(r.cb_credit)?.toFixed(2)}</td>

                                      </tr> }
                                    </>
                                     :
                                      <>
                                      {/* {r.cb_debit == 0 && r.cb_credit == 0 ? null : */}
                                        <tr >
                                            <td  style={{padding: 10}}>{r.ledger_code}</td>
                                            <td  style={{padding: 10}}>{r.ledger_name}</td>
                                            <td  style={{padding: 10}}>{parseFloat(r.cb_debit)?.toFixed(2)}</td>
                                            <td  style={{padding: 10}}>{parseFloat(r.cb_credit)?.toFixed(2)}</td>
                                        </tr>
                                      </>
                                      
                                      
                                  )
                                }) 
                              }
                              
                           
                            <tr>
                                <td colSpan={5} style={{padding: 10}}></td>
                                <td  style={{padding: 10, fontWeight: 'bold', fontSize: 20, borderTopWidth: 1}}>{(row.debitsum).toFixed(2)}</td>
                                <td  style={{padding: 10, fontWeight: 'bold', fontSize: 20, borderTopWidth: 1}}>{(row.creditsum).toFixed(2)}</td>
                            </tr>
                            </tbody>
                             )
                          }
                        )}
                  
              </Table>
            }
            </TableContainer>
           
          </Paper> 
        
      </div>
    )
}
export default Daybook;