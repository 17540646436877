
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';




function WizardFormFirstPage(props) {
    const childvalue = (latvalue, longvalue) => {
      props.mapValues(latvalue, longvalue)
    }
   
  return (
        <div>
            <Row>
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <TextField name="suppliercode"
                        onChange={props.updateform}
                        value={props.form.suppliercode} disabled id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <TextField name="suppliername"
                        onChange={props.updateform}
                        value={props.form.suppliername} id="outlined-basic" label="Supplier name" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>
                <Col xs={12} md={3}> 
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sub Group</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.ledger}
                                label="Age"
                                onChange={props.ledgerChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.ledgerList.map((item, index) => (
                                  <MenuItem value={item.group_id}>{item.group_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>   
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Supplier-Company or Third party</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.suppliertype}
                                label="Age"
                                onChange={props.suppliertypeChange}
                                size="small"
                                
                                >
                                <MenuItem value="2">Company Supplier</MenuItem>
                                <MenuItem value="1">Outside Supplier</MenuItem>
                            </Select>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>   
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <TextField name="address1"
                        onChange={props.updateform}
                        value={props.form.address1} id="outlined-basic" label="Address1" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                            <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={props.country}
                            label="Age"
                            onChange={props.countryChange}
                            size="small"
                            
                            >
                            <MenuItem value="">
                            <em>None</em>
                            </MenuItem>
                            {props.countryList.map((item, index) => (
                                <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>  
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <TextField name="gst"
                    onChange={props.updateform}
                    value={props.form.gst} id="outlined-basic" label="GSTIN" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <TextField name="mobile"
                    onChange={props.updateform}
                    value={props.form.mobile} id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField name="email"
                        onChange={props.updateform}
                        value={props.form.email} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                </Col>
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <TextField name="openbalance"
                    onChange={props.updateform}
                    value={props.form.openbalance} type="number" id="outlined-basic" label="Opening Balance" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                </Col>   
            </Row>
            <Row>   
                <Col xs={12} md={3}>
                    <FormControl sx={{ mt: 3}} component="fieldset">
                        <RadioGroup
                            defaultValue="credit"
                            name="radio-buttons-group"
                            onClick={props.radioChange}
                        >
                            <FormControlLabel value="credit"  control={<Radio checked={props.decr == 0?  false: true}/>} label="Credit" color = '#999'  />
                            <FormControlLabel value="debit" color= '#999' control={<Radio checked={props.decr == 0?  true: false}/>} label="Debit" />
                        </RadioGroup>
                    </FormControl>
                </Col>      
            </Row>
        </div>
  );
}

export default WizardFormFirstPage;
