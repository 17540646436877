
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

function WizardFormFirstPage(props) {
  const [bank, setBank] = useState("")

  const bankChange = (event) => {
    setBank(event.target.value)
  }
   
  return (
        <div>
            <Row>
                <Col md={6} >
                  <Row>
                    <Col md={6}>
                      <FormControl sx={{ mt: 2, width: '100%' }}>
                          <TextField name="customername"
                        onChange={(e) => props.setCustomerregno(e.target.value)}
                        value={props.customerregno}
                          id="outlined-basic" label="Customer Reg No." variant="outlined"  size="small"/>
                      </FormControl>
                    </Col>
                    <Col md={6} style={{marginTop: 17}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Valid Upto"
                                value={props.dateTo}
                                onChange={(newValue) => {
                                    props.setDateTo(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col md={12}>
                      <div style={{marginTop: 30}}>
                        <label>Document Upload</label>
                        <input type="file" onChange={(e) => props.changefile(e)}  style={{marginLeft: 20}}/>
                      </div>
                    </Col>
                      <Col md={6}>
                      <p style={{fontSize: 20, fontWeight: 500, marginTop: 20}}>Tax Registration Details</p>
                        <FormControl sx={{ mt: 1, width: '100%' }}>
                            <TextField name="gst"
                          onChange={(e) => props.setTaxgstin(e.target.value)}
                          value={props.taxgstin}
                          id="outlined-basic" label="Tax/GSTIN" variant="outlined"  size="small"/>
                          </FormControl>
                          <label style={{fontSize: 10}}>Required</label>
                      </Col>
                      <Col md={6}>
                        <p style={{fontSize: 20, fontWeight: 500, marginTop: 20}}>Bank Details</p>
                        <FormControl sx={{ mt: 1, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Provide Bank Details</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                onChange={bankChange}
                                size="small"
                                
                              >
                              <MenuItem value="no">No</MenuItem>
                              <MenuItem value="yes">Yes</MenuItem>
                            </Select>
                        </FormControl>
                        {bank == "yes" ?
                        <div>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField name="customercode"
                                  onChange={(e) => props.setAccntno(e.target.value)}
                                  value={props.accntno}
                                 id="outlined-basic" label="Account No." variant="outlined"  size="small"/>
                            </FormControl>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField name="customercode"
                                  onChange={(e) => props.setIfsc(e.target.value)}
                                  value={props.ifsc}
                                 id="outlined-basic" label="IFSC" variant="outlined"  size="small"/>
                            </FormControl>
                        </div> : null}
                      </Col>
                  </Row>
                </Col>
                <Col md={6}>
                    <p style={{fontSize: 20, fontWeight: 500}}>Mailing Details</p>
                    <Row>
                        <Col  md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                              <TextField name="mobile"
                              onChange={(e) => props.setCustoname(e.target.value)}
                              value={props.custoname}
                                id="outlined-basic" label="Name" variant="outlined"  size="small"/>
                              </FormControl>
                              <label style={{fontSize: 10}}>Required</label>
                          </Col>
                          <Col  md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                              <TextField name="mobile"
                             onChange={(e) => props.setCustomob(e.target.value)}
                             value={props.customob}
                              id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                              </FormControl>
                              <label style={{fontSize: 10}}>Required</label>
                          </Col>
                          <Col md={12}>
                              <FormControl sx={{ mt: 1, width: '100%' }}>
                                <TextareaAutosize 
                                    minRows={3}
                                    placeholder="Adddress"
                                    onChange={(e) => props.setCustoaddrs(e.target.value)}
                                    value={props.custoaddrs}
                                    style={{background: 'transparent', width: '100%'}}
                                />
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                          </Col>
                          
                          <Col md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField name="email"
                            onChange={(e) => props.setCustoemail(e.target.value)}
                            value={props.custoemail}
                            id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                          </Col>
                          <Col md={6}>
                              <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField name="whatsapp"
                            onChange={(e) => props.setCustowhats(e.target.value)}
                            value={props.custowhats}
                            id="outlined-basic" label="Whatsap" variant="outlined"  size="small"/>
                            </FormControl>
                          </Col>
                          <Col md={6}>
                              <FormControl sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.custocountry}
                                    onChange={props.custoCountryChange}
                                    label="Age"
                                    size="small"
                                    
                                  >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {props.countryList.map((item, index) => (
                                      <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <label style={{fontSize: 10}}>Required</label>
                          </Col>
                        </Row>
                    </Col>
            </Row>
        </div>
  );
}

export default WizardFormFirstPage;
