
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const columns = [
    { id: '1', label: 'Delivery Boy', minWidth: 50,span: false },
    { id: '2', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Sales Order No.', minWidth: 50,span: false },
    { id: '3', label: 'Invoice No.', minWidth: 50,span: false },
    { id: '4', label: 'Customer', minWidth: 50,span: false },
    { id: '5', label: 'Mobile number', minWidth: 50,span: false },
    { id: '5', label: 'Subtotal', minWidth: 50,span: false },
    { id: '5', label: 'Delivery Charge', minWidth: 50,span: false },
    { id: '5', label: 'Discount', minWidth: 50,span: false },
    { id: '7', label: 'Net Total', minWidth: 50,span: false },
    // { id: '8', label: 'Action', minWidth: 50,span: true },
  ];

function SalesReport(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [value, setValue] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");
    const [modal, setModal] = React.useState(false);
    const [mobile, setMobile] = React.useState("");
    const [input, setInput] = useState(0);
    const [filt, setFilt] = useState(false)
    const [dateFrom, setDatefrom] = React.useState(new Date);
    const [dateTo, setDateTo] = React.useState(new Date);
    const [billDetails, setBillsetails] = React.useState([]);
    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])
    const [totalsum, setTotalsum] = useState("")

    const [frominvoice, setFrominvoice] = useState("")
    const [toinvoice, setToinvoice] = useState("")

    const [totaldeli, setDeli] = useState("0")
    const [totdisc, setDisc] = useState("0")
    const [totnet, setTotnet] = useState("0")
    const [totsub, setTotsub] = useState("0")

  const userChange = (event) => {
    setSalesmanid(event.target.value)
      getData1(event.target.value)
  }

  useEffect(() => {
      getData()
      getUser()
      // salestotal()
  }, [input])


  const salestotal = () => {
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company,
      }
      
    fetch(global.url + "salesReporttotal", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.error == false){
        setDeli(responseJson.data.deliverycharge)
        setDisc(responseJson.data.discount)
        setTotnet(responseJson.data.nettotal)
        setTotsub(responseJson.data.subtotal)
      }
    })
  }

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  "salesreturn",
        "usertype":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "sales_fromdate": dateFrom.toISOString().split('T')[0],
        "sales_todate": dateTo.toISOString().split('T')[0],
      }
    fetch(global.url + "viewsaleshead", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      // console.log("responseJson", responseJson)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }else{
        setSource([])
   
      }
    })
  }

  const allProducts = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  "salesreturn",
        "usertype":  userdata.user_type,
        "companyid": company,
        "branchid" : branch
      }
    fetch(global.url + "viewsaleshead", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }else{
        setSource([])
   
      }
    })
  }
  const decrement = () => {
    setInput(prevCount => prevCount - 1);
    
  }

 const increment = () => {
    setInput(prevCount => prevCount + 1);
  
  }

  const getData1 = (event) => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  "salesreturn",
        "usertype":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "delivery_id":  event
      }
      
    fetch(global.url + "viewsaleshead", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
      else{
        setSource([])
   
      }
    })
  }

  const viewBillclick = (id) => {
    window.open('/salesreportbill/' + id, "_blank")
  }

  const returnClick = (id , billno) => {
    alert("Can't Return")
    // if (window.confirm('Are you sure you want to return the bill ' + billno + " ?")) {
    //   var data = {
    //     "sales_id":  id,
    //     "branchid": userbranchid,
    //     "companyid": usercompanyid,
    //     "user_id": userId
    //   }
    //   fetch(global.url + "salesReturn", {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type' : 'application/json'
    //       },
    //       body: JSON.stringify(data)
    //   })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if(responseJson.error == false){
    //       getData()
    //       alert(responseJson.message)
    //     }
    //   })
    // } else {
    //   // Do nothing!
    //   console.log('Thing');
    // }
  }

  const productReturnClick = (id, productname, salesid) => {
      if (window.confirm('Are you sure you want to return the Product ' + productname + " ?")) {
        var data = {
          "sd_id":  id,
          "branchid": userbranchid,
          "companyid": usercompanyid,
          "user_id": userId
        }
        fetch(global.url + "salesReturnEach", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error == false){
            // viewBillclick(salesid)
            alert(responseJson.message)
          }
        })
      }
  }

    const getdata2 = (text) => {
      setLoader(true)
      setMobile(text)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
          var data = {
            "type":  "salesreturn",
            "usertype":  userdata.user_type,
            "companyid": company,
            "branchid" : branch,
            "sales_mob": text
          }
          
        fetch(global.url + "viewsaleshead", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          setLoader(false)
          if(responseJson.status == "false"){
            setSource(responseJson.data)
            setDeli(responseJson.total.deliverycharge)
            setDisc(responseJson.total.discount)
            setTotnet(responseJson.total.nettotal)
            setTotsub(responseJson.total.subtotal)
          }
          else{
            setSource([])
      
          }
        })
    }

    const getUser = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      
      var data = {
        "companyid": company,
        "branchid" : branch
      }
      fetch(global.url + "viewDeliveryboy", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setUserlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const filterClick = () => {
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
          var data = {
            "type":  "salesreturn",
            "usertype":  userdata.user_type,
            "companyid": company,
            "branchid" : branch,
            "sales_fromdate": dateFrom.toISOString().split('T')[0],
            "sales_todate": dateTo.toISOString().split('T')[0],
            "delivery_id": salesmanid
          }
        fetch(global.url + "viewsaleshead", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          setLoader(false)
          if(responseJson.status == "false"){
            setSource(responseJson.data)
            setDeli(responseJson.total.deliverycharge)
            setDisc(responseJson.total.discount)
            setTotnet(responseJson.total.nettotal)
            setTotsub(responseJson.total.subtotal)
          }
          else{
            setSource([])
          
          }
        })
    }

    const filterClick2 = () => {
      setLoader(true)
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
        var data = {
          "type":  "salesreturn",
          "usertype":  userdata.user_type,
          "companyid": company,
          "branchid" : branch,
          "invoice_from":frominvoice,
          "invoice_to": toinvoice,
        }
      fetch(global.url + "viewsaleshead", {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false)
        if(responseJson.status == "false"){
          setSource(responseJson.data)
          setDeli(responseJson.total.deliverycharge)
          setDisc(responseJson.total.discount)
          setTotnet(responseJson.total.nettotal)
          setTotsub(responseJson.total.subtotal)
        }
        else{
          setSource([])
  
        }
      })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, marginBottom: 0, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Return Report</p>
            </div>
            <div style={{margin: 20, marginTop: 0}}>
                <Row>
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <FormControl sx={{width: '100%' }}>
                              <TextField onChange={(e) => getdata2(e.target.value)} placeholder="Mobile" value={mobile} id="outlined-basic" variant="outlined"  size="small"/>
                          </FormControl>
                    </Col>
                    <Col md={2} style={{marginTop:20}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesmanid}
                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {userlist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                            </FormControl>
                      </Col>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={dateFrom}
                                    onChange={(newValue) => {
                                        setDatefrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={dateTo}
                                    onChange={(newValue) => {
                                        setDateTo(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={3} lg={2} style={{marginTop: 20}}>
                            <div style={{display: 'flex'}}>
                              <Button variant="primary" onClick={() => filterClick()}>Filter</Button>
                              <Button variant="primary" style={{marginLeft: 20}} onClick={() => allProducts()}>All</Button>
                            </div>
                        </Col>
                        <Col xs={12} md={3} lg={2} style={{marginTop: 20}}>
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Download as XLS"/>
                        </Col>
                </Row>
                <Row>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setFrominvoice(e.target.value)} value={frominvoice} placeholder='Invoice From' id="outlined-basic" variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>

                      <Col xs={12} md={2} style={{marginTop: 20}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setToinvoice(e.target.value)} value={toinvoice} id="outlined-basic" placeholder='Invoice To' variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>
                    
                    <Col xs={12} md={2} lg={3} style={{marginTop: 20}}>
                        <Button style={{width: '50%', height: 40}} variant="primary" onClick={() => filterClick2()}>Filter</Button>
                    </Col>
                </Row>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 1 }}>
            {loader ? <Loader /> :
            
              <Table stickyHeader aria-label="sticky table" id="table-to-xls">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} >
                              <TableCell >
                                  {row.user_name}
                              </TableCell>
                              <TableCell >
                                  {row.sales_date}
                              </TableCell>
                              <TableCell >
                                  {row.sales_lpo_no}
                              </TableCell>
                              <TableCell >
                                  {row.sales_invoice}
                              </TableCell>
                              <TableCell >
                                  {row.customer_name}
                              </TableCell>
                              <TableCell >
                                  {row.sales_mob_no}
                              </TableCell>
                              <TableCell >
                                 {row.sales_total}
                              </TableCell>
                              <TableCell >
                                 {row.sales_other_charges}
                              </TableCell>
                              <TableCell >
                                 {row.sales_discount}
                              </TableCell>
                              <TableCell >
                                  {row.sales_cashledger}
                              </TableCell>
                              {/* <TableCell >
                                  <a href={'/transaction/Sales/' + row.sales_id} >
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              </TableCell> */}
                              {/* <TableCell>
                                  <Button onClick={() => returnClick(row.sales_id, row.sales_invoice)}  variant="primary" style={{padding: 5, fontSize: 10}}>Sales Return</Button>
                              </TableCell> */}
                              {/* <TableCell>
                                  <Button onClick={() => viewBillclick(row.sales_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                    <TableRow>
                          <TableCell colSpan={6} style={{fontWeight: 'bold'}}>
                              Total
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totsub}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                              {totaldeli}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totdisc}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totnet}
                          </TableCell>
                      </TableRow>
                </TableBody>
              </Table>
              }
            </TableContainer>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Barcode</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                          
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.pb_inter_barcode}</td>
                                <td>{item.code}</td>
                                <td>{item.product}</td>
                                <td>{item.qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.amount}</td>
                                <td><Button onClick={() => productReturnClick(item.sd_id, item.product, item.sales_id)} variant="primary" style={{padding: 5, fontSize: 10}}>Return Product</Button></td>
                              </tr>
                            ))}
                           
                        </tbody>
                      </Table>
                </Modal.Body>
            </Modal>
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            {/* <Row>
              <Col xs={12}>
                <div style={{float: 'right', marginRight: 130, marginTop: 20}}>
                    <p style={{fontWeight: "bold"}}>Total: </p>
                    <p  style={{fontWeight: "bold"}}> {totalsum}</p>
                  </div>
              </Col>
            </Row> */}
          </Paper> 
          {/* {filt == true ? null :
          <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
          } */}
      </div>
    )
}
export default SalesReport;