
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import { MultiSelect } from "react-multi-select-component";
import TimePicker from '@mui/lab/TimePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'User Tyep', minWidth: 50,span: false },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 100,
        span: true
      },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const rows = [
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];


  const options = [
    { label: "Grapes", value: "grapes", },
    { label: "Mango", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
  ];

function Userrole(props) {
    const [btnloader, setBtnloader] = React.useState(false);
    const [modal, setModal] = useState(false);
    const [loader, setLoader] = React.useState(false);
    const [source, setSource] = React.useState([]);

    const [usertypeid, setUsertypeid] = React.useState('');
    const [usertypename, setUsertypename] = React.useState('');

    

    const [array, setArray] = React.useState([
        {
            title: "Dashboard",
            path: "/dashboard",
            status: false
        },
        {
            title: "State",
            path: "/master/state",
            status: false,
        },
        {
            title: "District",
            path: "/master/district",
            status: false,
        },
        {
            title: "Company",
            path: "/master/company",
            status: false,
        },
        {
            title: "Branch",
            path: "/master/Branch",
            status: false,
        },
        {
            title: "Godown",
            path: "/master/Godown",
            status: false,
        },
        {
            title: "Counter",
            path: "/master/Counter",
            status: false,
        },
        {
            title: "Route",
            path: "/master/Route",
            status: false,
        },
        {
            title: "Area",
            path: "/master/Area",
            status: false,
        },
        {
            title: "Vehicle Type",
            path: "/master/VehicleType",
            status: false,
        },
        {
            title: "Vehicle",
            path: "/master/Vehicle",
            status: false,
        },
        {
            title: "Discount Master",
            path: "/master/Discount",
            status: false,
        },
        {
            title: "Credit Master",
            path: "/master/Credit",
            status: false,
        },
        {
            title: "Price level",
            path: "/Products/PriceLevel",
            status: false,
        },
        {
            title: "Department",
            path: "/Products/Department",
            status: false,
        },
        {
            title: "Section",
            path: "/Products/Section",
            status: false,
        },
        {
            title: "Category",
            path: "/Products/Category",
            status: false,
        },
        {
            title: "Subcategory",
            path: "/Products/SubCategory",
            status: false,
        },
        {
            title: "Segment",
            path: "/Products/Segment",
            status: false,
        },
        {
            title: "Subsegment",
            path: "/Products/Subsegment",
            status: false,
        },
        {
            title: "Location display",
            path: "/Products/locationdisplay",
            status: false,
        },
        {
            title: "Products",
            path: "/Products/Products",
            status: false,
        },
        {
            title: "Unit",
            path: "/Products/Unit",
            status: false,
        },
        {
            title: "Taxmaster",
            path: "/Products/Tax",
            status: false,
        },
        {
            title: "HSN",
            path: "/Products/Hsn",
            status: false,
        },
        {
            title: "Color",
            path: "/Products/color",
            status: false,
        },
        {
            title: "Size",
            path: "/Products/size",
            status: false,
        },
        {
            title: "Combo Products",
            path: "/Products/comboproducts",
            status: false,
        },
        {
            title: "Sub Group",
            path: "/Accounts/Group",
            status: false,
        },
        {
            title: "Ledger",
            path: "/Accounts/Account",
            status: false,
        },
        {
            title: "Customer",
            path: "/Accounts/Customer",
            status: false,
        },
        {
            title: "Supplier",
            path: "/Accounts/Supplier",
            status: false,
        },
        {
            title: "Designation",
            path: "/Accounts/Designation",
            status: false,
        },
        {
            title: "Employee",
            path: "/Accounts/Employee",
            status: false,
        },
        {
            title: "Bank",
            path: "/Accounts/Bank",
            status: false,
        },
        {
            title: "Bank Master",
            path: "/Accounts/BankMaster",
            status: false,
        },
        {
            title: "Representative",
            path: "/Accounts/Representative",
            status: false,
        },
        {
            title: "Promoter",
            path: "/Accounts/Promoter",
            status: false,
        },
        {
            title: "Payment",
            path: "/transaction/paymentvoucher/:id",
            status: false,
        },
        {
            title: "Salary Payment",
            path: "/transaction/Salary/:id",
            status: false,
        },
        {
            title: "Receipt",
            path: "/transaction/receiptvoucher/:id",
            status: false,
        },
        {
            title: "Contra",
            path: "/transaction/Contra/:id",
            status: false,
        },
        {
            title: "Journal",
            path: "/transaction/journalvoucher/:id",
            status: false,
        },
        {
            title: "Opening PDC",
            path: "/transaction/PDC",
            status: false,
        },
        {
            title: "Purchase Request",
            path: "/transaction/Purchaserequest",
            status: false,
        },
        {
            title: "LPO",
            path: "/transaction/Purchaseorder/:id",
            status: false,
        },
        {
            title: "GRN",
            path: "/Creditors/GRN",
            status: false,
        },
        {
            title: "Purchase Approval",
            path: "/Creditors/PurchaseApproval",
            status: false,
        },
        {
            title: "Purchase",
            path: "/transaction/Purchase",
            status: false,
        },
        {
            title: "Add To Stock",
            path: "",
            status: false,
        },
        {
            title: "Purchase Return",
            path: "/Creditors/PurchasesReturn",
            status: false,
        },
        {
            title: "Debit Note",
            path: "",
            status: false,
        },
        {
            title: "Quotation",
            path: "/Sales/Quatation",
            status: false,
        },
        {
            title: "Sales Order",
            path: "/transaction/Salesorder/:id",
            status: false,
        },
        {
            title: "Sales Approval",
            path: "/Sales/SalesApproval",
            status: false,
        },
        {
            title: "Sales Order Replace List",
            path: "/Sales/Salesorderreplacelist",
            status: false,
        },
    
        {
            title: "Display Warehouse",
            path: "/Sales/displaywarehouse",
            status: false,
        },
        
        {
            title: "Sales",
            path: "/transaction/Sales/:id",
            status: false,
        },
        {
            title: "Delivery Note",
            path: "/Sales/DeliveryNote",
            status: false,
        },
        {
            title: "Sales Replacement",
            path: "/transaction/Salesreplace",
            status: false,
        },
        {
            title: "Delivery Return",
            path: "/Sales/DeliveryReturn",
            status: false,
        },
        {
            title: "Credit Note",
            path: "",
            status: false,
        },
        {
            title: "POS",
            path: "/Sales/pos",
            status: false,
        },
        {
            title: "Stock Adjustment",
            path: "/StockEntries/StockAdjustment",
            status: false,
        },
        {
            title: "Stock Transfer",
            path: "/StockEntries/StockTransfer",
            status: false,
        },
        {
            title: "Stock Receipt",
            path: "/StockEntries/StockReceipt",
            status: false,
        },
        {
            title: "Godown Return",
            path: "/StockEntries/GodownTransfer",
            status: false,
        },
        {
            title: "Godown Receipt",
            path: "/StockEntries/GodownReceipt",
            status: false,
        },
        {
            title: "Packing",
            path: "/StockEntries/Packing",
            status: false,
        },
        {
            title: "Unpacking",
            path: "/StockEntries/Unpacking",
            status: false,
        },
        {
            title: "Damage Entry",
            path: "/StockEntries/DamageEntry",
            status: false,
        },
        {
            title: "Item To Item",
            path: "",
            status: false,
        },
        {
            title: "Batch to Batch",
            path: "",
            status: false,
        },
        {
            title: "User Attendance",
            path: "/User/UserAttendance",
            status: false,
            
        },
        {
            title: "User Management",
            path: "/User/UserManagement",
            status: false,
        },
        {
            title: "Attendance",
            path: "/User/Attendance",
            status: false,
        },
        {
            title: "User log",
            path: "/User/userlog",
            status: false,
        },
        {
            title: "User role",
            path: "/User/userrole",
            status: false,
        },
        {
            title: "Banner/Slider",
            path: "/ecommerce/banner",
            status: false,
            
        },
        {
            title: "Delivery Order",
            path: "/ecommerce/delivery",
            status: false,
            
        },
        {
            title: "Priniting Template",
            path: "",
            status: false,
            
        },
        {
            title: "Bill Settings",
            path: "/transaction/BillSettings",
            status: false,
        },
        {
            title: "System Settings",
            path: "",
            status: false,
        },
        {
            title: "Tax Setttings",
            path: "",
            status: false,
        },
        {
            title: "Coupons",
            path: "",
            status: false,
        },
        {
            title: "Promotions",
            path: "",
            status: false,
        },
        {
            title: "Common Settings",
            path: "/settings/commonsettings",
            status: false,
        },
        {	
            title: "Current Stock",
            path: "/StockReport/CurrentStockReport",
            status: false,
        },
        {
            title: "Stock Value",
            path: "/stockvalue",
            status: false,
        },
        {
            title: "Detailed Stock",
            path: "/detailedstock",
            status: false,
        },
        {
            title: "Sales Order Report",
            path: "/Reports/Salesorderreport",
            status: false,
        },
        {
            title: "Userwise Sales Order",
            path: "/Reports/userwisesalesorder",
            status: false,
        },
        
        {
            title: "Sales Report",
            path: "/Reports/SalesReport",
            status: false,
        },
        
        {
            title: "Sales Return",
            path: "/Reports/salesreturn",
            status: false,
        },
        {
            title: "Order Delivery",
            path: "/Reports/orderdelivery",
            status: false,
        },
        {
            title: "Delivery Executive",
            path: "/Reports/deliveryexecutive",
            status: false,
        },
        {
            title: "Cancel Order Report",
            path: "/Reports/cancelorder",
            status: false,
        },
        {
            title: "Cancel Product Report",
            path: "/Reports/cancelproduct",
            status: false,
        },
        {
            title: "Purchase Order Report",
            path: "/Reports/Purchaseorderreport",
            status: false,
        },
        {
            title: "Purchase Report",
            path: "/Reports/PurchaseReport",
            status: false,
        },
        {
            title: "Billwise Sales Profit",
            path: "",
            status: false,
        },
        {
            title: "Sale Itemwise Profit",
            path: "",
            status: false,
        },
        {
            title: "Sales Return Billwise Profit",
            path: "",
            status: false,
        },
        {
            title: "Sales Return Itemwise Profit",
            path: "",
            status: false,
        },
        {
            title: "Purchase Billwise Profit",
            path: "",
            status: false,
        },
        {
            title: "Purchase Itemwise Profit",
            path: "/master/district",
            status: false,
        },
        {
            title: "Purchase Return Billwise Profit",
            path: "",
            status: false,
        },
        {
            title: "Purchase Return Itemwise Profit",
            path: "",
            status: false,
        },
        {
            title: "Cash Book",
            path: "/Reports/cashbook",
            status: false,
        },
        {
            title: "Bank Book",
            path: "/Reports/bankbook",
            status: false,
        },
        {
            title: "Ledger",
            path: "/Reports/ledgersummary",
            status: false,
        },
        {
            title: "Ledger Detail",
            path: "/Reports/ledgerdetails",
            status: false,
        },
        {
            title: "Daybook",
            path: "/Reports/daybookdetails",
            status: false,
        },
        {
            title: "Profit & Loss",
            path: "/Reports/profitandloss",
            status: false,
        },
        {
            title: "Trial Balance",
            path: "/Reports/trialbalance",
            status: false,
        },
        {
            title: "Balance Sheet",
            path: "/Reports/balancesheet",
            status: false,
        },
        {
            title: "User Logs",
            path: "/Reports/userlogs",
            status: false,
        },
        {
            title: "Filemanager",
            path: "/Filemanager/filemanger",
            status: false,
        },
        {
            title: "Salary",
            path: "",
            status: false,
        },
        {
            title: "Attendance",
            path: "",
            status: false,
        },
        {
            title: "Journal",
            path: "/Reports/JournalReport",
            status: false,
        },
        {
            title: "Contra",
            path: "",
            status: false,
        },
        {
            title: "Payment",
            path: "/Reports/PaymentReport",
            status: false,
        },
        {
            title: "Receipt",
            path: "/Reports/ReceiptReport",
            status: false,
        },
        {
            title: "PDC",
            path: "",
            status: false,
        }
    ])

  

    useEffect (() => {
        getData()
    }, [])

  

  const getData = () => {

    fetch(global.url + "displayUserAccess", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then((response) => response.json())
    .then((responseJson) => {
        setSource(responseJson.data)
    })
    .catch((error) => {
        console.log(error)
    })
  }

    const onSave = () => {
        setBtnloader(true)
      var data = {
          "usertype": usertypename,
          "useraccess": array,
          "usertypeid": usertypeid,
        }
        fetch(global.url + "UserAccessAdd", {
            method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
        }).then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.error == false){
                alert(responseJson.message)
                setModal(false)
                setBtnloader(false)
            }
            else{
                alert(responseJson.message)
                setBtnloader(false)
            }
            
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const editClick = (id, type, access) => {
        setUsertypeid(id)
        setUsertypename(type)
        if(access == null){
            setModal(true)
            setArray([
                {
                    title: "Dashboard",
                    path: "/dashboard",
                    status: false
                },
                {
                    title: "State",
                    path: "/master/state",
                    status: false,
                },
                {
                    title: "District",
                    path: "/master/district",
                    status: false,
                },
                {
                    title: "Company",
                    path: "/master/company",
                    status: false,
                },
                {
                    title: "Branch",
                    path: "/master/Branch",
                    status: false,
                },
                {
                    title: "Godown",
                    path: "/master/Godown",
                    status: false,
                },
                {
                    title: "Counter",
                    path: "/master/Counter",
                    status: false,
                },
                {
                    title: "Route",
                    path: "/master/Route",
                    status: false,
                },
                {
                    title: "Area",
                    path: "/master/Area",
                    status: false,
                },
                {
                    title: "Vehicle Type",
                    path: "/master/VehicleType",
                    status: false,
                },
                {
                    title: "Vehicle",
                    path: "/master/Vehicle",
                    status: false,
                },
                {
                    title: "Discount Master",
                    path: "/master/Discount",
                    status: false,
                },
                {
                    title: "Credit Master",
                    path: "/master/Credit",
                    status: false,
                },
                {
                    title: "Price level",
                    path: "/Products/PriceLevel",
                    status: false,
                },
                {
                    title: "Department",
                    path: "/Products/Department",
                    status: false,
                },
                {
                    title: "Section",
                    path: "/Products/Section",
                    status: false,
                },
                {
                    title: "Category",
                    path: "/Products/Category",
                    status: false,
                },
                {
                    title: "Subcategory",
                    path: "/Products/SubCategory",
                    status: false,
                },
                {
                    title: "Segment",
                    path: "/Products/Segment",
                    status: false,
                },
                {
                    title: "Subsegment",
                    path: "/Products/Subsegment",
                    status: false,
                },
                {
                    title: "Location display",
                    path: "/Products/locationdisplay",
                    status: false,
                },
                {
                    title: "Products",
                    path: "/Products/Products",
                    status: false,
                },
                {
                    title: "Unit",
                    path: "/Products/Unit",
                    status: false,
                },
                {
                    title: "Taxmaster",
                    path: "/Products/Tax",
                    status: false,
                },
                {
                    title: "HSN",
                    path: "/Products/Hsn",
                    status: false,
                },
                {
                    title: "Color",
                    path: "/Products/color",
                    status: false,
                },
                {
                    title: "Size",
                    path: "/Products/size",
                    status: false,
                },
                {
                    title: "Combo Products",
                    path: "/Products/comboproducts",
                    status: false,
                },
                {
                    title: "Sub Group",
                    path: "/Accounts/Group",
                    status: false,
                },
                {
                    title: "Ledger",
                    path: "/Accounts/Account",
                    status: false,
                },
                {
                    title: "Customer",
                    path: "/Accounts/Customer",
                    status: false,
                },
                {
                    title: "Supplier",
                    path: "/Accounts/Supplier",
                    status: false,
                },
                {
                    title: "Designation",
                    path: "/Accounts/Designation",
                    status: false,
                },
                {
                    title: "Employee",
                    path: "/Accounts/Employee",
                    status: false,
                },
                {
                    title: "Bank",
                    path: "/Accounts/Bank",
                    status: false,
                },
                {
                    title: "Bank Master",
                    path: "/Accounts/BankMaster",
                    status: false,
                },
                {
                    title: "Representative",
                    path: "/Accounts/Representative",
                    status: false,
                },
                {
                    title: "Promoter",
                    path: "/Accounts/Promoter",
                    status: false,
                },
                {
                    title: "Payment",
                    path: "/transaction/paymentvoucher/:id",
                    status: false,
                },
                {
                    title: "Salary Payment",
                    path: "/transaction/Salary/:id",
                    status: false,
                },
                {
                    title: "Receipt",
                    path: "/transaction/receiptvoucher/:id",
                    status: false,
                },
                {
                    title: "Contra",
                    path: "/transaction/Contra/:id",
                    status: false,
                },
                {
                    title: "Journal",
                    path: "/transaction/journalvoucher/:id",
                    status: false,
                },
                {
                    title: "Opening PDC",
                    path: "/transaction/PDC",
                    status: false,
                },
                {
                    title: "Purchase Request",
                    path: "/transaction/Purchaserequest",
                    status: false,
                },
                {
                    title: "LPO",
                    path: "/transaction/Purchaseorder/:id",
                    status: false,
                },
                {
                    title: "GRN",
                    path: "/Creditors/GRN",
                    status: false,
                },
                {
                    title: "Purchase Approval",
                    path: "/Creditors/PurchaseApproval",
                    status: false,
                },
                {
                    title: "Purchase",
                    path: "/transaction/Purchase",
                    status: false,
                },
                {
                    title: "Add To Stock",
                    path: "",
                    status: false,
                },
                {
                    title: "Purchase Return",
                    path: "/Creditors/PurchasesReturn",
                    status: false,
                },
                {
                    title: "Debit Note",
                    path: "",
                    status: false,
                },
                {
                    title: "Quotation",
                    path: "/Sales/Quatation",
                    status: false,
                },
                {
                    title: "Sales Order",
                    path: "/transaction/Salesorder/:id",
                    status: false,
                },
                {
                    title: "Sales Approval",
                    path: "/Sales/SalesApproval",
                    status: false,
                },
                {
                    title: "Sales Order Replace List",
                    path: "/Sales/Salesorderreplacelist",
                    status: false,
                },
            
                {
                    title: "Display Warehouse",
                    path: "/Sales/displaywarehouse",
                    status: false,
                },
                
                {
                    title: "Sales",
                    path: "/transaction/Sales/:id",
                    status: false,
                },
                {
                    title: "Delivery Note",
                    path: "/Sales/DeliveryNote",
                    status: false,
                },
                {
                    title: "Sales Replacement",
                    path: "/transaction/Salesreplace",
                    status: false,
                },
                {
                    title: "Delivery Return",
                    path: "/Sales/DeliveryReturn",
                    status: false,
                },
                {
                    title: "Credit Note",
                    path: "",
                    status: false,
                },
                {
                    title: "POS",
                    path: "/Sales/pos",
                    status: false,
                },
                {
                    title: "Stock Adjustment",
                    path: "/StockEntries/StockAdjustment",
                    status: false,
                },
                {
                    title: "Stock Transfer",
                    path: "/StockEntries/StockTransfer",
                    status: false,
                },
                {
                    title: "Stock Receipt",
                    path: "/StockEntries/StockReceipt",
                    status: false,
                },
                {
                    title: "Godown Return",
                    path: "/StockEntries/GodownTransfer",
                    status: false,
                },
                {
                    title: "Godown Receipt",
                    path: "/StockEntries/GodownReceipt",
                    status: false,
                },
                {
                    title: "Packing",
                    path: "/StockEntries/Packing",
                    status: false,
                },
                {
                    title: "Unpacking",
                    path: "/StockEntries/Unpacking",
                    status: false,
                },
                {
                    title: "Damage Entry",
                    path: "/StockEntries/DamageEntry",
                    status: false,
                },
                {
                    title: "Item To Item",
                    path: "",
                    status: false,
                },
                {
                    title: "Batch to Batch",
                    path: "",
                    status: false,
                },
                {
                    title: "User Attendance",
                    path: "/User/UserAttendance",
                    status: false,
                    
                },
                {
                    title: "User Management",
                    path: "/User/UserManagement",
                    status: false,
                },
                {
                    title: "Attendance",
                    path: "/User/Attendance",
                    status: false,
                },
                {
                    title: "User log",
                    path: "/User/userlog",
                    status: false,
                },
                {
                    title: "User role",
                    path: "/User/userrole",
                    status: false,
                },
                {
                    title: "Banner/Slider",
                    path: "/ecommerce/banner",
                    status: false,
                    
                },
                {
                    title: "Delivery Order",
                    path: "/ecommerce/delivery",
                    status: false,
                    
                },
                {
                    title: "Priniting Template",
                    path: "",
                    status: false,
                    
                },
                {
                    title: "Bill Settings",
                    path: "/transaction/BillSettings",
                    status: false,
                },
                {
                    title: "System Settings",
                    path: "",
                    status: false,
                },
                {
                    title: "Tax Setttings",
                    path: "",
                    status: false,
                },
                {
                    title: "Coupons",
                    path: "",
                    status: false,
                },
                {
                    title: "Promotions",
                    path: "",
                    status: false,
                },
                {
                    title: "Common Settings",
                    path: "/settings/commonsettings",
                    status: false,
                },
                {	
                    title: "Current Stock",
                    path: "/StockReport/CurrentStockReport",
                    status: false,
                },
                {
                    title: "Stock Value",
                    path: "/stockvalue",
                    status: false,
                },
                {
                    title: "Detailed Stock",
                    path: "/detailedstock",
                    status: false,
                },
                {
                    title: "Sales Order Report",
                    path: "/Reports/Salesorderreport",
                    status: false,
                },
                {
                    title: "Userwise Sales Order",
                    path: "/Reports/userwisesalesorder",
                    status: false,
                },
                
                {
                    title: "Sales Report",
                    path: "/Reports/SalesReport",
                    status: false,
                },
                
                {
                    title: "Sales Return",
                    path: "/Reports/salesreturn",
                    status: false,
                },
                {
                    title: "Order Delivery",
                    path: "/Reports/orderdelivery",
                    status: false,
                },
                {
                    title: "Delivery Executive",
                    path: "/Reports/deliveryexecutive",
                    status: false,
                },
                {
                    title: "Cancel Order Report",
                    path: "/Reports/cancelorder",
                    status: false,
                },
                {
                    title: "Cancel Product Report",
                    path: "/Reports/cancelproduct",
                    status: false,
                },
                {
                    title: "Purchase Order Report",
                    path: "/Reports/Purchaseorderreport",
                    status: false,
                },
                {
                    title: "Purchase Report",
                    path: "/Reports/PurchaseReport",
                    status: false,
                },
                {
                    title: "Billwise Sales Profit",
                    path: "",
                    status: false,
                },
                {
                    title: "Sale Itemwise Profit",
                    path: "",
                    status: false,
                },
                {
                    title: "Sales Return Billwise Profit",
                    path: "",
                    status: false,
                },
                {
                    title: "Sales Return Itemwise Profit",
                    path: "",
                    status: false,
                },
                {
                    title: "Purchase Billwise Profit",
                    path: "",
                    status: false,
                },
                {
                    title: "Purchase Itemwise Profit",
                    path: "/master/district",
                    status: false,
                },
                {
                    title: "Purchase Return Billwise Profit",
                    path: "",
                    status: false,
                },
                {
                    title: "Purchase Return Itemwise Profit",
                    path: "",
                    status: false,
                },
                {
                    title: "Cash Book",
                    path: "/Reports/cashbook",
                    status: false,
                },
                {
                    title: "Bank Book",
                    path: "/Reports/bankbook",
                    status: false,
                },
                {
                    title: "Ledger",
                    path: "/Reports/ledgersummary",
                    status: false,
                },
                {
                    title: "Ledger Detail",
                    path: "/Reports/ledgerdetails",
                    status: false,
                },
                {
                    title: "Daybook",
                    path: "/Reports/daybookdetails",
                    status: false,
                },
                {
                    title: "Profit & Loss",
                    path: "/Reports/profitandloss",
                    status: false,
                },
                {
                    title: "Trial Balance",
                    path: "/Reports/trialbalance",
                    status: false,
                },
                {
                    title: "Balance Sheet",
                    path: "/Reports/balancesheet",
                    status: false,
                },
                {
                    title: "User Logs",
                    path: "/Reports/userlogs",
                    status: false,
                },
                {
                    title: "Filemanager",
                    path: "/Filemanager/filemanger",
                    status: false,
                },
                {
                    title: "Salary",
                    path: "",
                    status: false,
                },
                {
                    title: "Attendance",
                    path: "",
                    status: false,
                },
                {
                    title: "Journal",
                    path: "/Reports/JournalReport",
                    status: false,
                },
                {
                    title: "Contra",
                    path: "",
                    status: false,
                },
                {
                    title: "Payment",
                    path: "/Reports/PaymentReport",
                    status: false,
                },
                {
                    title: "Receipt",
                    path: "/Reports/ReceiptReport",
                    status: false,
                },
                {
                    title: "PDC",
                    path: "",
                    status: false,
                }
            ])
        }
        else{
            setArray(access)
            setModal(true)
        }
    }

    const blockChange = (e,index) => {
        let isChecked = e.target.checked;
        // console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input[index].status = true
            setArray(input)
        }
        else{
            var input  = [...array]
            input[index].status = false
            setArray(input)
            
        }
    }

    const checkall = (e) => {
        let isChecked = e.target.checked;
        // console.log(isChecked)
        if(isChecked == true) {
            var input1 = [...array]
            for(var i = 0; i<input1.length ; i++) {
                input1[i].status = true
            }
            setArray(input1)
        }
        else{
            var input = [...array]
            for(var i = 0; i<input.length ; i++) {
                input[i].status = false
            }
            setArray(input)
        }
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>User Type Role</p>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
                {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell>
                                  {row.uts_usertype}
                              </TableCell>
                              <TableCell >
                                    <a href="javascript:void(0)" onClick={() => 
                                    editClick(row.user_typeaccess_id,
                                        row.uts_usertype,
                                    row.uts_useraccess)}>
                                      <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                             
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 User Type Access
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                  Check All
                        <FormControl sx={{  width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox onChange={e => checkall(e)} />
                            } label="" />
                        </FormControl>
                  </Row>
                  <Row>
                   
                    { array.map((item, index) => {
                            return(
                                <Col xs={6}>
                                    <FormControl sx={{  width: '100%' }}>
                                        <FormControlLabel control={
                                        <Checkbox checked={item.status == true ? true : false} onChange={e => blockChange(e, index)} />
                                        } label={item.title} />
                                    </FormControl>
                                </Col>
                            )
                        })
                    }
                  
                    
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="primary">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Userrole;