import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import WizardFormFirstPage from './editPage';
import WizardFormSecondPage from './FirstPage';
import Loader from '../../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '1', label: 'AG code', minWidth: 50,span: false },
    { id: '3', label: 'Ledger Name', minWidth: 50,span: false },
    { id: '4', label: 'Name', minWidth: 50,span: false },
    { id: '7', label: 'Address', minWidth: 50,span: false },
    // { id: '8', label: 'GSTIN', minWidth: 50,span: false },
    { id: '10', label: 'Mobile', minWidth: 50,span: false },
    { id: '11', label: 'Email', minWidth: 50,span: false },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

  const dates = [
      {
        company: 'Lorem Ipsum'
      },
      {
        company: 'Lorem Ipsum'
      }
  ]

function Customer(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [btnloader, setBtnloader] = React.useState(false);
    const [btnloader2, setBtnloader2] = React.useState(false);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [ledgerId, setLedgerid] = React.useState('');   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [form, setForm] = useState({
        customercode: "",
        customername: "",
        address1: "",
        address2: "",
        address3: "",
        gst: "",
        mobile: "",
        whatsapp: "",
        email: "",
        website: "",
        creditLimit: 0,
        openbalance: 0,
      })
    const [blocked, setBlocked] = useState(0)
    const [enableBill, setEnablebill] = useState(0)
    const [count, setCount] = useState(1)
    const [customerId, setCustomerid] = useState("")
    const [source, setSource] = React.useState([]);
    const [userId, setUserid] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [state, setState] = React.useState("");
    const [stateList, setStatelist] = React.useState([]);
    const [district, setDistrict] = React.useState("");
    const [districtList, setDistrictlist] = React.useState([]);
    const [ledger, setLedger] = React.useState("");
    const [ledgerList, setledgerlist] = React.useState([]);
    const [country, setCountry] = React.useState('');
    const [countryList, setCountryList] = React.useState([]);
    const [area, setArea] = React.useState("");
    const [areaList, setArealist] = React.useState([]);
    const [route, setRoute] = React.useState("");
    const [routeList, setRoutelist] = React.useState([]);
    const [representative, setRepresentative] = React.useState("");
    const [repList, setReplist] = React.useState([]);
    const [latitude, setLatitude] = React.useState(25.3548);
    const [longitude, setLongitude] = React.useState(51.1839);
    const [decr, setDecr] = React.useState(0);
    const [date, setDate] = React.useState(null);
    const [billNo, setBillno] = React.useState("");
    const [recievable, setRecievable] = React.useState("");
    const [payable, setPayable] = React.useState("");
    const [tableRow, setTableRow] = React.useState([]);

    const [pricelevel, setPricelevel] = React.useState("");
    const [pricelevellist, setPricelevellist] = React.useState([]);

    const [customerregno, setCustomerregno] = React.useState("");
    const [dateTo, setDateTo] = React.useState(new Date);
    const [file, setFile] = React.useState("");
    const [taxgstin, setTaxgstin] = React.useState("");
    const [accntno, setAccntno] = React.useState("");
    const [ifsc, setIfsc] = React.useState("");
    const [custoname, setCustoname] = React.useState("");
    const [customob, setCustomob] = React.useState("");
    const [custoaddrs, setCustoaddrs] = React.useState("");
    const [custoemail, setCustoemail] = React.useState("");
    const [custowhats, setCustowhats] = React.useState("");
    const [custocountry, setCustocountry] = React.useState("");

    const addRow = () => {
      var data = {
        "customer_id":  customerId,
        "bill_date":  date.toISOString().split('T')[0],
        "customer_userid":  userId,
        "reference_number":  billNo,
        "bill_payable":  payable,
        "bill_receivable":  recievable,
      }
      fetch(global.url + "updateCustomerBill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.status == "false") {
              getCustomerbill(customerId)
              setPayable("")
              setRecievable("")
              setBillno("")
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const radioChange = (event) => {
    if(event.target.value == "credit") {
       setDecr(1)
    }
    else {
      setDecr(0)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateForm = (e) => {
    setForm({
    ...form,
    [e.target.name]: e.target.value,
    })
  }

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
        setBlocked(1)
    }
    else {
        setBlocked(0)
    }
  }

  const enableBillchange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
        setEnablebill(1)
    }
    else {
        setEnablebill(0)
    }
  }

  useEffect(() => {
    getData()
    getCountry()
    getRepresentative()
    getLedger()
    getRoute()
    getArea()
    getPricelevel()
    getCustomercode()

  }, []);

  const getCustomercode = () =>{
   
    fetch(global.url + "getCustomercode", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.error == false) {
            const newValues = { ...form }
            newValues['customercode']= responseJson.data.customercode
            newValues['customername']= ""
            newValues['address1']= ""
            newValues['address2']= ""
            newValues['address3']= ""
            newValues['gst']= ""
            newValues['mobile']= ""
            newValues['whatsapp']= ""
            newValues['email']= ""
            newValues['website']= ""
            newValues['creditLimit']= 0
            newValues['openbalance']= 0
            setForm(newValues)
          }
        
      })
      .catch((error)=>{
          console.log(error)
      })
}

  const getPricelevel = () =>{
      setLoader(true)
      var userdata = JSON.parse(localStorage.getItem("user"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUserid(userdata.user_id)
      const data = {
        "companyid": company,
        "type": userdata.user_type
      }
      fetch(global.url + "viewPricelevel", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            if(responseJson.status == "false") {
              setPricelevellist(responseJson.data)
            }
          
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const getLedger = () =>{
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "companyid":  company,
      "type":  userdata.user_type
    }
    fetch(global.url + "viewGroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setledgerlist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getRoute = () => {
    fetch(global.url + "viewRoute", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.status == "true") {
          setRoutelist([])
        }
        else{
          setRoutelist(responseJson.data)
        }
        
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getArea = () => {
    fetch(global.url + "viewArea", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.status == "true") {
          setArealist([])
        }
        else{
          setArealist(responseJson.data)
        }
        
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getRepresentative = () => {
    
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewRepresentative", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setReplist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewCustomer", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.Error == "false") {
            setSource(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const countryChange = (event) => {
      setCountry(event.target.value);
      getState(event.target.value)
    };

    const custoCountryChange = (event) => {
      setCustocountry(event.target.value);
    };

    const ledgerchange = (event) => {
      setLedger(event.target.value);
    };

    const routeChange = (event) => {
      setRoute(event.target.value);
    };

    const areaChange = (event) => {
      setArea(event.target.value);
    };

    const repChange = (event) => {
      setRepresentative(event.target.value);
    };

    const pricelevelChange = (event) => {
      setPricelevel(event.target.value)
    }

    const stateChange = (event) => {
      setState(event.target.value);
      getDistrict(event.target.value)
    };

    const districtChange = (event) => {
      setDistrict(event.target.value);
    };

    const getDistrict = (id) => {
      var data = {
        "countryid": id
      }
      fetch(global.url + "viewDistrict", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          setDistrictlist(responseJson.data)
        }
        
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const getState = (id) => {
      var data = {
        "countryid": id
      }
      fetch(global.url + "viewState", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setStatelist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

  const getCountry = () => {
    fetch(global.url + "viewCountry", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      if(responseJson.status == "false") {
        setCountryList(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onNext = () => {
      setBtnloader(true)
        var data = {
          "user_id": userId,
          "customer_id": customerId,
          "customer_code": form.customercode,
          "ledger_group": ledger,
          "customer_name": form.customername,
          "customer_address1": form.address1,
          "customer_address2": form.address2,
          "customer_address3": form.address3,
          "customer_country": country,
          "customer_state": state,
          "customer_district": district,
          "customer_gstin": form.gst,
          "customer_mobile": form.mobile,
          "customer_email": form.email,
          "customer_website": form.website,
          "customer_whatsapp": form.whatsapp,
          "customer_rep": representative,
          "customer_route": route,
          "customer_area": area,
          "customer_open_balance": form.openbalance,
          "customer_decr": decr,
          "customer_credit_limit": form.creditLimit,
          "customer_isbillwise_entry": enableBill,
          "customer_isactive": blocked,
          "customer_latitude": latitude,
          "customer_longitude": longitude,
          "customer_branchid" : userbranchid,
          "customer_companyid" : usercompanyid,
          "customer_pricelevel": pricelevel
        }
        fetch(global.url + "updateCustomer", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            if(responseJson.error == false) {
              // setCount(count + 1)
              getData()
              setCustomerid(responseJson.data)
              alert("success")
              setModal(false) 
              setModal2(true) 
              setBtnloader(false)
            }else{
              alert(responseJson.message)
              setBtnloader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const onSave = () => {
    setBtnloader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
       "mode" : "customer",
       "sce_regno" : customerregno,
       "sce_validupto" : dateTo,
       "sce_file" : file,
       "sce_taxreg" : taxgstin,
       "sce_bankifsc" : ifsc,
       "sce_bankaccno" : accntno,
       "sce_mailname" : custoname,
       "sce_mailmobile" : customob,
       "sce_mailaddress" : custoaddrs,
       "sce_mailemail" : custoemail,
       "sce_mailwhatsapp" : custowhats,
       "sce_mailcountry" : custocountry,
       "sce_customerid_fk" : customerId,
       "sce_ledgerid_fk" : 0,
       "sce_supplierid_fk" : 0,
       "sce_userid" : userdata.user_id,
       "sce_companyid" : company,
       "sce_branchid" : branch
      }
      fetch(global.url + "updateledgerExtra", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          if(responseJson.error == false) {
            getData()
            alert("success")
            setModal(false) 
            setModal2(false) 
            setBtnloader(false)
            setCustomerregno("")
            setFile("")
            setTaxgstin("")
            setAccntno("")
            setIfsc("")
            setCustoname("")
            setCustomob("")
            setCustoaddrs("")
            setCustoemail("")
            setCustowhats("")
            setCustocountry("")
          }else{
            alert(responseJson.message)
            setBtnloader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
}

  const onCustomersave = () => {
    setBtnloader2(true)
    var data = {
      "user_id": userId,
      "customer_id": customerId,
      "customer_code": form.customercode,
      "customer_ledger": ledger,
      "customer_name": form.customername,
      "customer_address1": form.address1,
      "customer_address2": form.address2,
      "customer_address3": form.address3,
      "customer_country": country,
      "customer_state": state,
      "customer_district": district,
      "customer_gstin": form.gst,
      "customer_mobile": form.mobile,
      "customer_email": form.email,
      "customer_website": form.website,
      "customer_whatsapp": form.whatsapp,
      "customer_rep": representative,
      "customer_route": route,
      "customer_area": area,
      "customer_open_balance": form.openbalance,
      "customer_decr": decr,
      "customer_credit_limit": form.creditLimit,
      "customer_isbillwise_entry": enableBill,
      "customer_isactive": blocked,
      "customer_latitude": latitude,
      "customer_longitude": longitude,
      "customer_branchid" : userbranchid,
      "customer_companyid" : usercompanyid,
      "customer_pricelevel": pricelevel
    }
    fetch(global.url + "updateCustomer", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.Error == "false") {
          alert(responseJson.data)
          getData()
          setBtnloader2(false)
        }else {
          alert(responseJson.message)
          setBtnloader2(false)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  const mapValues = (lat, long) => {
    setLatitude(lat)
    setLongitude(long)
  }
  const editClick = (
    customerid, 
    customercode,
    customerledger,
    customername,
    address1,
    address2,
    address3,
    country,
    state,
    district,
    gst,
    mobile,
    whatsap,
    representattive,
    route,
    area,
    openbalance,
    customertype,
    creditlimit,
    billwise,
    isactive,
    email,
    website,
    latitude,
    longitude,
  ) => {
    setModal(true)
    setCustomerid(customerid)
    const newValues = { ...form }
    newValues['customercode']= customercode
    newValues['customername']= customername
    newValues['address1']= address1
    newValues['address2']= address2
    newValues['address3']= address3
    newValues['gst']= gst
    newValues['mobile']= mobile
    newValues['whatsapp']= whatsap
    newValues['email']= email
    newValues['website']= website
    newValues['creditLimit']= creditlimit
    newValues['openbalance']= openbalance
    getState(country)
    getDistrict(state)
    setForm(newValues)
    setEditmode(true)
    setCountry(country)
    setState(state)
    setDistrict(district)
    setEnablebill(billwise)
    setDecr(customertype)
    setBlocked(isactive)
    setRepresentative(representattive)
    setRoute(route)
    setArea(area)
    setLedger(customerledger)
    getCustomerbill(customerid)
    setLatitude(parseFloat(latitude))
    setLongitude(parseFloat(longitude)) 
  }

  const onAdd = () => {
    setModal(true)
    setCustomerid("")
    const newValues = { ...form }
    newValues['customercode']= ""
    newValues['customername']= ""
    newValues['address1']= ""
    newValues['address2']= ""
    newValues['address3']= ""
    newValues['gst']= ""
    newValues['mobile']= ""
    newValues['whatsapp']= ""
    newValues['email']= ""
    newValues['website']= ""
    newValues['creditLimit']= 0
    newValues['openbalance']= 0
    getState()
    setForm(newValues)
    setEditmode(false)
    setCountry("")
    setState("")
    setDistrict("")
    setEnablebill(0)
    setDecr(0)
    setBlocked(0)
    setRepresentative("")
    setLatitude(25.3548)
    setLongitude(51.1839)
    setRoute("")
    setArea("")
    setLedger("")
    getCustomercode()
  }

  const getCustomerbill = (id) => {
      var data = {
        "customerid":  id
      }
      fetch(global.url + "viewCustomerBill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // setLoader(false)
            if(responseJson.error == "false") {
              setTableRow(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

 

  const onhide = () => {
    setEditmode(false)
    setModal(false)
    setModal2(false) 
    setCount(1)
    setTableRow([])
  }

  const deleteClick = (id) =>  {
    var data = {
      "customer_id": id,
      "user_id": userId
    }
    fetch(global.url + "deleteCustomer", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const deleteBill = (id) => {
      var data = {
        "customerbill_id": id,
        "user_id": userId
      }
      fetch(global.url + "deleteCustomerBill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.data)
          getCustomerbill(customerId)
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const changefile =(e) => {
    setFile(e.target.files[0])
  }

    return(
        <div className="main-content">
        {modal == false && modal2 == false ?
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Customer List</p>
               {
                      usertype == "superadmin" ? 
                      null : 
                    <Button variant="contained"  onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.customer_code}
                              </TableCell>
                              <TableCell >
                                  {row.customer_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.ledger_name}
                              </TableCell>
                              <TableCell >
                                  {row.customer_name}
                              </TableCell>
                              <TableCell >
                                  {row.customer_address1}
                              </TableCell>
                              {/* <TableCell >
                                  {row.customer_gstin}
                              </TableCell> */}
                              <TableCell >
                                  {row.customer_mobile}
                              </TableCell>
                              <TableCell >
                                  {row.customer_email}
                              </TableCell>
                       
                              {/* <TableCell >
                              {
                                usertype == "superadmin" ? 
                                null : 
                                <a href="javascript:void(0)" onClick={() => 
                                    editClick(
                                        row.customer_id, 
                                        row.customer_code, 
                                        row.group_id, 
                                        row.customer_name, 
                                        row.customer_address1,
                                        row.customer_address2, 
                                        row.customer_address3,
                                        row.customer_country_fk,
                                        row.customer_state_fk,
                                        row.customer_district_fk,
                                        row.customer_gstin,
                                        row.customer_mobile,
                                        row.customer_whatsapp,
                                        row.customer_rep_fk,
                                        row.customer_route_fk,
                                        row.customer_area_fk,
                                        row.customer_open_balance,
                                        row.customer_decr,
                                        row.customer_credit_limit,
                                        row.customer_isbillwise_entry,
                                        row.customer_isactive,
                                        row.customer_email,
                                        row.customer_website,
                                        row.customer_latitude,
                                        row.customer_longitude,
                                    )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                                }
                              </TableCell> */}
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.customer_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> :
                <div>
                    <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                        <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Customer Creation</p>
                        <Button variant="contained"  onClick={onhide}>Back</Button>
                    </div>
                    {modal2 == false ?
                    <div>
                      <form
                        className="col-12 form"
                        onSubmit={() =>
                          alert(
                            `Submitted Email: ${form.email} Name: ${form.name} Password: ${form.password}`
                          )
                        }
                      >
                       
                          <WizardFormFirstPage 
                                form={form} 
                                blocked = {blocked} 
                                enableBill = {enableBill}
                                enableBillchange = {enableBillchange}
                                countryChange = {countryChange}
                                stateChange ={stateChange}
                                districtChange ={districtChange}
                                ledgerChange ={ledgerchange}
                                routeChange ={routeChange}
                                areaChange ={areaChange}
                                repChange ={repChange}
                                state ={state}
                                stateList= {stateList}
                                // dateTo = {dateTo}
                                // setDateTo = {setDateTo}
                                district = {district}
                                districtList= {districtList}
                                ledger = {ledger}
                                ledgerList ={ledgerList}
                                representative = {representative}
                                repList ={repList}
                                route = {route}
                                routeList ={routeList}
                                area = {area}
                                areaList ={areaList}
                                country ={country}
                                countryList ={countryList}
                                updateform = {updateForm} 
                                checkchange = {checkChange} 
                                decr = {decr}
                                radioChange = {radioChange}
                                latitude = {latitude}
                                setLatitude = {setLatitude}
                                longitude = {longitude}
                                setLongitude = {setLongitude}
                                mapValues = {mapValues}
                                pricelevellist={pricelevellist}
                                pricelevel={pricelevel}
                                pricelevelChange={pricelevelChange}
                          />
                    </form>
                   </div> : null}
                        {modal2 == true ? (
                          // <WizardFormSecondPage 
                          //       date={date} 
                          //       billNo = {billNo} 
                          //       recievable = {recievable} 
                          //       payable = {payable} 
                          //       tableRow = {tableRow} 
                          //       setDate ={setDate}
                          //       setBillno= {setBillno}
                          //       setRecievable= {setRecievable}
                          //       setPayable={setPayable}
                          //       setTableRow={setTableRow}
                          //       addRow = {addRow}
                          //       deleteBill = {deleteBill}
                          // />
                          <WizardFormSecondPage
                              customerregno = {customerregno}
                              setCustomerregno = {setCustomerregno}
                              dateTo = {dateTo}
                              setDateTo = {setDateTo}
                              changefile = {changefile}
                              taxgstin = {taxgstin}
                              setTaxgstin = {setTaxgstin}
                              accntno = {accntno} 
                              setAccntno = {setAccntno}
                              ifsc = {ifsc}
                              setIfsc = {setIfsc}
                              custoname = {custoname}
                              setCustoname = {setCustoname}
                              customob = {customob}
                              setCustomob = {setCustomob}
                              custoaddrs = {custoaddrs}
                              setCustoaddrs = {setCustoaddrs}
                              custoemail = {custoemail}
                              setCustoemail = {setCustoemail}
                              custowhats = {custowhats}
                              setCustowhats = {setCustowhats}
                              custocountry = {custocountry}
                              setCustocountry = {setCustocountry}
                              countryList = {countryList}
                              custoCountryChange = {custoCountryChange}
                          />
                        ) : null}
                    
                      {/* <Col xs ={12} style={{marginTop:30}}>
                          { count == 1 && editMode == false ? 
                          <div>
                             {
                              btnloader ? 
                              <Loader2
                                  type="Oval"
                                  color="#38B0DE"
                                  height={30}
                                  width={30}
                                  style={{float: 'right'}}
                              />:
                            <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Save</Button>}
                          </div>:
                            count == 1 && editMode == true ?
                            <div>
                                <div>
                                {
                                  btnloader ? 
                                  <Loader2
                                      type="Oval"
                                      color="#38B0DE"
                                      height={30}
                                      width={30}
                                      style={{float: 'right'}}
                                  />:
                                  <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Save</Button>}
                                </div>
                                <div>
                                {
                                btnloader2 ? 
                                <Loader2
                                    type="Oval"
                                    color="#38B0DE"
                                    height={30}
                                    width={30}
                                    style={{float: 'right'}}
                                />:
                                  <Button onClick={onCustomersave}  style={{float: 'right', marginRight: 20}} variant="contained">Next</Button>}
                                </div>
                            </div>
                             : null
                          }
                    </Col> */}
                    {modal == true ?
                    <Col xs ={12} style={{marginTop:30}}>
                      {
                          btnloader ? 
                          <Loader2
                              type="Oval"
                              color="#38B0DE"
                              height={30}
                              width={30}
                              style={{float: 'right'}}
                          />:
                        <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Next</Button>}
                    </Col>:
                     <Col xs ={12} style={{marginTop:30}}>
                     {
                         btnloader ? 
                         <Loader2
                             type="Oval"
                             color="#38B0DE"
                             height={30}
                             width={30}
                             style={{float: 'right'}}
                         />:
                       <Button onClick={onSave}  style={{float: 'right'}} variant="contained">Save</Button>}
                   </Col>}
                </div>}
      </div>
    )
}
export default Customer;