import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

function WizardFormFirstPage(props) {
  return (
        <div>
            <Row>
                <Col xs={12} >
                    <Row>
                        <Col md={2}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="ledgerCode"
                                value={props.ledgerCodenw} disabled id="outlined-basic" label="Code" variant="outlined" autoComplete="off"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={4}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="ledgerName"
                                onChange={props.updateform}
                                value={props.form.ledgerName} id="outlined-basic" autoComplete="off" label="Ledger" variant="outlined"  size="small"/>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                        {/* <Col md={12} >
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextareaAutosize 
                                    minRows={4}
                                    placeholder="Adddress"
                                    style={{background: 'transparent', width: '100%'}}
                                />
                            </FormControl>
                        </Col> */}
                    </Row>
                    <Row>

                        <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Sub Group</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.group}
                                    label="Age"
                                    onChange={props.groupChange}
                                    size="small"
                                    
                                    >
                                    <MenuItem value="">
                                    <em>None</em>
                                    </MenuItem>
                                    {props.groupList.map((item, index) => (
                                        <MenuItem value={item.group_id}>{item.group_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                  
                        {/* <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="address1"
                                onChange={props.updateform}
                                value={props.form.address1} id="outlined-basic" autoComplete="off" label="Address1" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="address2"
                                onChange={props.updateform}
                                value={props.form.address2} id="outlined-basic" autoComplete="off" label="Address2" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="address3"
                                onChange={props.updateform}
                                value={props.form.address3} id="outlined-basic" autoComplete="off" label="Address 3" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        {/* <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.country}
                                    label="Age"
                                    onChange={props.countryChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.countryList.map((item, index) => (
                                    <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col> */}
                        {/* <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.state}
                                    label="Age"
                                    onChange={props.stateChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.stateList.map((item, index) => (
                                    <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                        </Col> */}
                        {/* <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">District</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.district}
                                    label="Age"
                                    onChange={props.districtChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.districtList.map((item, index) => (
                                    <MenuItem value={item.district_id}>{item.district_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col> */}
                        {/* <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="gstin"
                            onChange={props.updateform}
                            value={props.form.gstin} id="outlined-basic" autoComplete="off" label="Tax/GSTIN" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        {/* <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="mobile"
                            onChange={props.updateform}
                            value={props.form.mobile} id="outlined-basic" autoComplete="off" label="Mobile" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="email"
                            onChange={props.updateform}
                            value={props.form.email} id="outlined-basic" autoComplete="off" label="Email" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="website"
                            onChange={props.updateform}
                            value={props.form.website} id="outlined-basic" autoComplete="off" label="Website" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField name="whatsapp"
                            onChange={props.updateform}
                            value={props.form.whatsapp} id="outlined-basic" autoComplete="off" label="Whatsap" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        <Col xs={12} md={3}>
                                {
                                props.form.originalopbalance == 0 ? 
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField name="originalopbalance"
                                onChange={props.updateform}
                                value={props.form.originalopbalance}  type="number" autoComplete="off" id="outlined-basic" label="Opening Balance" variant="outlined"  size="small"/>
                                </FormControl> :
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField name="originalopbalance"
                                onChange={props.updateform}
                                value={props.form.originalopbalance} type="number" autoComplete="off" id="outlined-basic" label="Opening Balance" variant="outlined"  size="small"/>
                                </FormControl>
                            }
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                        <Col xs={12}>
                            <FormControl sx={{ mt: 1}} component="fieldset">
                                <RadioGroup
                                    defaultValue="credit"
                                    name="radio-buttons-group"
                                    onClick={props.radioChange}
                                >
                                    <FormControlLabel value="credit"  control={<Radio checked={props.decr == 0?  false: true}/>} label="Credit" color = '#999'  />
                                    <FormControlLabel value="debit" color= '#999' control={<Radio checked={props.decr == 0?  true: false}/>} label="Debit" />
                                </RadioGroup>
                            </FormControl>
                            {/* <FormControl sx={{ width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={props.enableBill == 0? false : true} onChange={e =>props.enableBillchange(e)} />
                                } label="Enable billwise entry" />
                            </FormControl> */}
                        </Col>
                    </Row>
                </Col>
                
            </Row>
        </div>
  );
}

export default WizardFormFirstPage;
