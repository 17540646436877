import './dashboard.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Table, Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiSave } from "react-icons/hi";
import { BsFillPeopleFill, BsFillEyeFill} from "react-icons/bs";
import { BiPencil} from "react-icons/bi";
import { FiUmbrella, FiShoppingBag } from "react-icons/fi";
import { AiFillDollarCircle, AiFillMoneyCollect, AiOutlineSetting } from "react-icons/ai";
import { MdFindReplace } from "react-icons/md";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import { Doughnut } from 'react-chartjs-2';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import FusionCharts from "fusioncharts";
// import charts from "fusioncharts/fusioncharts.charts";
// import ReactFusioncharts from "react-fusioncharts";
// import ReactApexChart from 'react-apexcharts';
// import ApexCharts from 'apexcharts'

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LineChart,RadialBarChart,RadialBar, Line, Brush, AreaChart, Area, ResponsiveContainer } from 'recharts';

const data = [
{name: 'Jan', uv: 100, pv: 2400, amt: 2400},
{name: 'Feb', uv: 300, pv: 2400, amt: 2400},
{name: 'Mar', uv: 500, pv: 2400, amt: 2400},
{name: 'Apr', uv: 200, pv: 2400, amt: 2400},
{name: 'May', uv: 400, pv: 2400, amt: 2400},
{name: 'Jun', uv: 300, pv: 2400, amt: 2400}];

const items = [
    { name: 'Jan', uv: 10, pv: 50, amt: 10 },
    { name: 'Fab', uv: 50, pv: 60, amt: 50 },
    { name: 'Mar', uv: 100, pv: 10, amt: 100 },
    { name: 'Apr', uv: 80, pv: 90, amt: 80 },
    { name: 'May', uv: 50, pv: 30, amt: 50 },
    { name: 'Jun', uv: 80, pv: 100, amt: 80 },
    { name: 'Jul', uv: 150, pv: 180, amt: 150 },
    { name: 'Aug', uv: 120, pv: 140, amt: 120 },
    { name: 'Sep', uv: 40, pv: 80, amt: 40 },
    { name: 'Oct', uv: 20, pv: 120, amt: 20 },
    { name: 'Nov', uv: 60, pv: 20, amt: 60 },
    { name: 'Dec', uv: 150, pv: 100, amt: 150 },
  ];

  const dataa = [
    {"name": "Page A","uv": 4000,"pv": 2400,"tv":14500,"amt": 2400},
    {"name": "Page B","uv": 3000,"pv": 1398,"tv":2300,"amt": 2210},
    {"name": "Page C","uv": 2000,"pv": 9800,"tv":20560,"amt": 2290},
    {"name": "Page D","uv": 2780,"pv": 3908,"tv":4330,"amt": 2000},
    {"name": "Page E","uv": 1890,"pv": 4800,"tv":8750,"amt": 2181},
    {"name": "Page F","uv": 2390,"pv": 3800,"tv":22010,"amt": 2500},
    {"name": "Page G","uv": 3490,"pv": 4300,"tv":2745,"amt": 2100}
    ];

    const data2 = [
        {
          "name": "18-24",
          "uv": 31.47,
          "pv": 2400,
          "fill": "#8884d8"
        },
        {
          "name": "25-29",
          "uv": 26.69,
          "pv": 4567,
          "fill": "#83a6ed"
        },
        {
          "name": "30-34",
          "uv": -15.69,
          "pv": 1398,
          "fill": "#8dd1e1"
        },
        {
          "name": "35-39",
          "uv": 8.22,
          "pv": 9800,
          "fill": "#82ca9d"
        },
        {
          "name": "40-49",
          "uv": -8.63,
          "pv": 3908,
          "fill": "#a4de6c"
        },
        {
          "name": "50+",
          "uv": -2.63,
          "pv": 4800,
          "fill": "#d0ed57"
        },
        {
          "name": "unknow",
          "uv": 6.67,
          "pv": 4800,
          "fill": "#ffc658"
        }
      ]

const columns = [
  { id: '1', label: 'Company', minWidth: 50,span: false },
  { id: '2', label: 'Ledger', minWidth: 50,span: false },
  { id: '3', label: 'address', minWidth: 50,span: false },
  { id: '4', label: 'GSTIN', minWidth: 50,span: false },
  { id: '5', label: 'Mobile', minWidth: 50,span: false },
  { id: '6', label: 'Email', minWidth: 50,span: false },
  { id: '7', label: 'Website', minWidth: 50,span: false },
  { id: '8', label: 'Whatsap No', minWidth: 50,span: false },
  { id: '9', label: 'Mobile Prefix', minWidth: 50,span: false },
  { id: '10', label: 'Location', minWidth: 50,span: false },
  { id: '11', label: 'Caption', minWidth: 50,span: false },
];

// const items = [
//     { name: 'Jan', purchase: 10, sales: 50, },
//     { name: 'Fab', purchase: 50, sales: 60,  },
//     { name: 'Mar', purchase: 100, sales: 10, },
//     { name: 'Apr', purchase: 80, sales: 90, },
//     { name: 'May', purchase: 50, sales: 30,  },
//     { name: 'Jun', purchase: 80, sales: 100,  },
//     { name: 'Jul', purchase: 150, sales: 180, },
//     { name: 'Aug', purchase: 120, sales: 140, },
//     { name: 'Sep', purchase: 40, sales: 80, },
//     { name: 'Oct', purchase: 20, sales: 120, },
//     { name: 'Nov', purchase: 60, sales: 20, },
//     { name: 'Dec', purchase: 150, sales: 100,  },
//   ];

  const data1 = {
    "labels": ['Salesorder', 'Sales', 'Purchase', 'Payment'],
    "datasets": [
      {
        "label": '# of Votes',
        "data": [7, 15, 2, 3,],
        "backgroundColor" : [
          '#fff700',
          '#00ff33',
          '#80159e',
          '#ff0000',
        ],
        "borderColor" : [
          '#fff700',
          '#00ff33',
          '#80159e',
          '#ff0000',
        ],
        "borderWidth" : 1,
      },
    ],
  };

  const dataSource = {
    chart: {
      caption: "IBRD Subscriptions and Voting Powers",
      subcaption: "For OECD countries",
      showvalues: "0",
      labeldisplay: "ROTATE",
      rotatelabels: "1",
      plothighlighteffect: "fadeout",
      plottooltext: "$seriesName in $label : <b>$dataValue</b>",
      theme: "gammel"
    },
    axis: [
      {
        title: "Subscription Amount",
        titlepos: "left",
        numberprefix: "$",
        divlineisdashed: "1",
        maxvalue: "100000",
        dataset: [
          {
            seriesname: "Subscription Amount",
            linethickness: "3",
            data: [
              {
                value: "38450.2"
              },
              {
                value: "16544.4"
              },
              {
                value: "10659.4"
              },
              {
                value: "9657.4"
              },
              {
                value: "9040.4"
              },
              {
                value: "9040.4"
              },
              {
                value: "6992.3"
              },
              {
                value: "6650.5"
              },
              {
                value: "6650.5"
              },
              {
                value: "6337.2"
              },
              {
                value: "5835.4"
              },
              {
                value: "4582.9"
              }
            ]
          }
        ]
      },
      {
        title: "Subscription %",
        axisonleft: "1",
        titlepos: "left",
        numdivlines: "8",
        divlineisdashed: "1",
        maxvalue: "25",
        numbersuffix: "%",
        dataset: [
          {
            seriesname: "Subscription %",
            dashed: "1",
            data: [
              {
                value: "17.23"
              },
              {
                value: "7.41"
              },
              {
                value: "4.78"
              },
              {
                value: "4.33"
              },
              {
                value: "4.05"
              },
              {
                value: "4.05"
              },
              {
                value: "3.13"
              },
              {
                value: "2.98"
              },
              {
                value: "2.98"
              },
              {
                value: "2.84"
              },
              {
                value: "2.62"
              },
              {
                value: "2.05"
              }
            ]
          }
        ]
      },
      {
        title: "Number of Votes",
        titlepos: "RIGHT",
        axisonleft: "0",
        numdivlines: "5",
        numbersuffix: "",
        divlineisdashed: "1",
        maxvalue: "400000",
        dataset: [
          {
            seriesname: "Number of Votes",
            linethickness: "3",
            data: [
              {
                value: "358196"
              },
              {
                value: "166138"
              },
              {
                value: "107288"
              },
              {
                value: "97268"
              },
              {
                value: "91098"
              },
              {
                value: "91098"
              },
              {
                value: "70617"
              },
              {
                value: "67199"
              },
              {
                value: "67199"
              },
              {
                value: "64066"
              },
              {
                value: "59048"
              },
              {
                value: "46523"
              }
            ]
          }
        ]
      },
      {
        title: "Voting %",
        titlepos: "RIGHT",
        axisonleft: "0",
        numdivlines: "5",
        divlineisdashed: "1",
        maxvalue: "20",
        numbersuffix: "%",
        dataset: [
          {
            seriesname: "Voting %",
            dashed: "1",
            data: [
              {
                value: "16.3"
              },
              {
                value: "7.03"
              },
              {
                value: "4.54"
              },
              {
                value: "4.12"
              },
              {
                value: "3.86"
              },
              {
                value: "3.86"
              },
              {
                value: "2.99"
              },
              {
                value: "2.84"
              },
              {
                value: "2.84"
              },
              {
                value: "2.71"
              },
              {
                value: "2.5"
              },
              {
                value: "1.97"
              }
            ]
          }
        ]
      }
    ],
    categories: [
      {
        category: [
          {
            label: "2006"
          },
          {
            label: "2007"
          },
          {
            label: "2008"
          },
          {
            label: "2009"
          },
          {
            label: "2010"
          },
          {
            label: "2011"
          },
          {
            label: "2012"
          },
          {
            label: "2013"
          },
          {
            label: "2014"
          },
          {
            label: "2015"
          },
          {
            label: "2016"
          },
          {
            label: "2017"
          }
        ]
      }
    ]
  };


function Dashboard(props) {


    const [state, setState] = useState({
        series: [{
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
          }, {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
          }],
          options: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
          },
    })

   
    const [states, setStates] = useState({       
            series: [{
              data: [
                [1327359600000,30.95],
                [1327446000000,31.34],
                [1327532400000,31.18],
                [1327618800000,31.05],
                [1327878000000,31.00],
                [1327964400000,30.95],
                [1328050800000,31.24],
                [1328137200000,31.29],
                [1328223600000,31.85],
                [1328482800000,31.86],
                [1328569200000,32.28],
                [1328655600000,32.10],
                [1328742000000,32.65],
                [1328828400000,32.21],
                [1329087600000,32.35],
                [1329174000000,32.44],
                [1329260400000,32.46],
                [1329346800000,32.86],
                [1329433200000,32.75],
                [1329778800000,32.54],
                [1329865200000,32.33],
                [1329951600000,32.97],
                [1330038000000,33.41],
                [1330297200000,33.27],
                [1330383600000,33.27],
                [1330470000000,32.89],
                [1330556400000,33.10],
                [1330642800000,33.73],
                [1330902000000,33.22],
                [1330988400000,31.99],
                [1331074800000,32.41],
                [1331161200000,33.05],
                [1331247600000,33.64],
                [1331506800000,33.56],
                [1331593200000,34.22],
                [1331679600000,33.77],
                [1331766000000,34.17],
                [1331852400000,33.82],
                [1332111600000,34.51],
                [1332198000000,33.16],
                [1332284400000,33.56],
                [1332370800000,33.71],
                [1332457200000,33.81],
                [1332712800000,34.40],
                [1332799200000,34.63],
                [1332885600000,34.46],
                [1332972000000,34.48],
                [1333058400000,34.31],
                [1333317600000,34.70],
                [1333404000000,34.31],
                [1333490400000,33.46],
                [1333576800000,33.59],
                [1333922400000,33.22],
                [1334008800000,32.61],
                [1334095200000,33.01],
                [1334181600000,33.55],
                [1334268000000,33.18],
                [1334527200000,32.84],
                [1334613600000,33.84],
                [1334700000000,33.39],
                [1334786400000,32.91],
                [1334872800000,33.06],
                [1335132000000,32.62],
                [1335218400000,32.40],
                [1335304800000,33.13],
                [1335391200000,33.26],
                [1335477600000,33.58],
                [1335736800000,33.55],
                [1335823200000,33.77],
                [1335909600000,33.76],
                [1335996000000,33.32],
                [1336082400000,32.61],
                [1336341600000,32.52],
                [1336428000000,32.67],
                [1336514400000,32.52],
                [1336600800000,31.92],
                [1336687200000,32.20],
                [1336946400000,32.23],
                [1337032800000,32.33],
                [1337119200000,32.36],
                [1337205600000,32.01],
                [1337292000000,31.31],
                [1337551200000,32.01],
                [1337637600000,32.01],
                [1337724000000,32.18],
                [1337810400000,31.54],
                [1337896800000,31.60],
                [1338242400000,32.05],
                [1338328800000,31.29],
                [1338415200000,31.05],
                [1338501600000,29.82],
                [1338760800000,30.31],
                [1338847200000,30.70],
                [1338933600000,31.69],
                [1339020000000,31.32],
                [1339106400000,31.65],
                [1339365600000,31.13],
                [1339452000000,31.77],
                [1339538400000,31.79],
                [1339624800000,31.67],
                [1339711200000,32.39],
                [1339970400000,32.63],
                [1340056800000,32.89],
                [1340143200000,31.99],
                [1340229600000,31.23],
                [1340316000000,31.57],
                [1340575200000,30.84],
                [1340661600000,31.07],
                [1340748000000,31.41],
                [1340834400000,31.17],
                [1340920800000,32.37],
                [1341180000000,32.19],
                [1341266400000,32.51],
                [1341439200000,32.53],
                [1341525600000,31.37],
                [1341784800000,30.43],
                [1341871200000,30.44],
                [1341957600000,30.20],
                [1342044000000,30.14],
                [1342130400000,30.65],
                [1342389600000,30.40],
                [1342476000000,30.65],
                [1342562400000,31.43],
                [1342648800000,31.89],
                [1342735200000,31.38],
                [1342994400000,30.64],
                [1343080800000,30.02],
                [1343167200000,30.33],
                [1343253600000,30.95],
                [1343340000000,31.89],
                [1343599200000,31.01],
                [1343685600000,30.88],
                [1343772000000,30.69],
                [1343858400000,30.58],
                [1343944800000,32.02],
                [1344204000000,32.14],
                [1344290400000,32.37],
                [1344376800000,32.51],
                [1344463200000,32.65],
                [1344549600000,32.64],
                [1344808800000,32.27],
                [1344895200000,32.10],
                [1344981600000,32.91],
                [1345068000000,33.65],
                [1345154400000,33.80],
                [1345413600000,33.92],
                [1345500000000,33.75],
                [1345586400000,33.84],
                [1345672800000,33.50],
                [1345759200000,32.26],
                [1346018400000,32.32],
                [1346104800000,32.06],
                [1346191200000,31.96],
                [1346277600000,31.46],
                [1346364000000,31.27],
                [1346709600000,31.43],
                [1346796000000,32.26],
                [1346882400000,32.79],
                [1346968800000,32.46],
                [1347228000000,32.13],
                [1347314400000,32.43],
                [1347400800000,32.42],
                [1347487200000,32.81],
                [1347573600000,33.34],
                [1347832800000,33.41],
                [1347919200000,32.57],
                [1348005600000,33.12],
                [1348092000000,34.53],
                [1348178400000,33.83],
                [1348437600000,33.41],
                [1348524000000,32.90],
                [1348610400000,32.53],
                [1348696800000,32.80],
                [1348783200000,32.44],
                [1349042400000,32.62],
                [1349128800000,32.57],
                [1349215200000,32.60],
                [1349301600000,32.68],
                [1349388000000,32.47],
                [1349647200000,32.23],
                [1349733600000,31.68],
                [1349820000000,31.51],
                [1349906400000,31.78],
                [1349992800000,31.94],
                [1350252000000,32.33],
                [1350338400000,33.24],
                [1350424800000,33.44],
                [1350511200000,33.48],
                [1350597600000,33.24],
                [1350856800000,33.49],
                [1350943200000,33.31],
                [1351029600000,33.36],
                [1351116000000,33.40],
                [1351202400000,34.01],
                [1351638000000,34.02],
                [1351724400000,34.36],
                [1351810800000,34.39],
                [1352070000000,34.24],
                [1352156400000,34.39],
                [1352242800000,33.47],
                [1352329200000,32.98],
                [1352415600000,32.90],
                [1352674800000,32.70],
                [1352761200000,32.54],
                [1352847600000,32.23],
                [1352934000000,32.64],
                [1353020400000,32.65],
                [1353279600000,32.92],
                [1353366000000,32.64],
                [1353452400000,32.84],
                [1353625200000,33.40],
                [1353884400000,33.30],
                [1353970800000,33.18],
                [1354057200000,33.88],
                [1354143600000,34.09],
                [1354230000000,34.61],
                [1354489200000,34.70],
                [1354575600000,35.30],
                [1354662000000,35.40],
                [1354748400000,35.14],
                [1354834800000,35.48],
                [1355094000000,35.75],
                [1355180400000,35.54],
                [1355266800000,35.96],
                [1355353200000,35.53],
                [1355439600000,37.56],
                [1355698800000,37.42],
                [1355785200000,37.49],
                [1355871600000,38.09],
                [1355958000000,37.87],
                [1356044400000,37.71],
                [1356303600000,37.53],
                [1356476400000,37.55],
                [1356562800000,37.30],
                [1356649200000,36.90],
                [1356908400000,37.68],
                [1357081200000,38.34],
                [1357167600000,37.75],
                [1357254000000,38.13],
                [1357513200000,37.94],
                [1357599600000,38.14],
                [1357686000000,38.66],
                [1357772400000,38.62],
                [1357858800000,38.09],
                [1358118000000,38.16],
                [1358204400000,38.15],
                [1358290800000,37.88],
                [1358377200000,37.73],
                [1358463600000,37.98],
                [1358809200000,37.95],
                [1358895600000,38.25],
                [1358982000000,38.10],
                [1359068400000,38.32],
                [1359327600000,38.24],
                [1359414000000,38.52],
                [1359500400000,37.94],
                [1359586800000,37.83],
                [1359673200000,38.34],
                [1359932400000,38.10],
                [1360018800000,38.51],
                [1360105200000,38.40],
                [1360191600000,38.07],
                [1360278000000,39.12],
                [1360537200000,38.64],
                [1360623600000,38.89],
                [1360710000000,38.81],
                [1360796400000,38.61],
                [1360882800000,38.63],
                [1361228400000,38.99],
                [1361314800000,38.77],
                [1361401200000,38.34],
                [1361487600000,38.55],
                [1361746800000,38.11],
                [1361833200000,38.59],
                [1361919600000,39.60],
              ]
            }],
            options: {
              chart: {
                id: 'area-datetime',
                type: 'area',
                height: 350,
                zoom: {
                  autoScaleYaxis: true
                }
              },
              annotations: {
                yaxis: [{
                  y: 30,
                  borderColor: '#999',
                  label: {
                    show: true,
                    text: 'Support',
                    style: {
                      color: "#fff",
                      background: '#00E396'
                    }
                  }
                }],
                xaxis: [{
                  x: new Date('14 Nov 2012').getTime(),
                  borderColor: '#999',
                  yAxisIndex: 0,
                  label: {
                    show: true,
                    text: 'Rally',
                    style: {
                      color: "#fff",
                      background: '#775DD0'
                    }
                  }
                }]
              },
              dataLabels: {
                enabled: false
              },
              markers: {
                size: 0,
                style: 'hollow',
              },
              xaxis: {
                type: 'datetime',
                min: new Date('01 Mar 2012').getTime(),
                tickAmount: 6,
              },
              tooltip: {
                x: {
                  format: 'dd MMM yyyy'
                }
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 100]
                }
              },
            },
          
          
            selection: 'one_year', 
        
    })
       
    // const updateData = (timeline) => {
    //    setStates({
    //         selection: timeline
    //       })
      
    //     switch (timeline) {
    //       case 'one_month':
    //         ApexCharts.exec(
    //           'area-datetime',
    //           'zoomX',
    //           new Date('28 Jan 2013').getTime(),
    //           new Date('27 Feb 2013').getTime()
    //         )
    //         break
    //       case 'six_months':
    //         ApexCharts.exec(
    //           'area-datetime',
    //           'zoomX',
    //           new Date('27 Sep 2012').getTime(),
    //           new Date('27 Feb 2013').getTime()
    //         )
    //         break
    //       case 'one_year':
    //         ApexCharts.exec(
    //           'area-datetime',
    //           'zoomX',
    //           new Date('27 Feb 2012').getTime(),
    //           new Date('27 Feb 2013').getTime()
    //         )
    //         break
    //       case 'ytd':
    //         ApexCharts.exec(
    //           'area-datetime',
    //           'zoomX',
    //           new Date('01 Jan 2013').getTime(),
    //           new Date('27 Feb 2013').getTime()
    //         )
    //         break
    //       case 'all':
    //         ApexCharts.exec(
    //           'area-datetime',
    //           'zoomX',
    //           new Date('23 Jan 2012').getTime(),
    //           new Date('27 Feb 2013').getTime()
    //         )
    //         break
    //       default:
    //     }
    //   }

const [modal, setModal] = useState(false);
  const [source, setSource] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [date, setDate] = React.useState(new Date);
  const [company, setCompany] = React.useState("");

  const [pdclist, setPdclist] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [statusarray, setStatusarray] = React.useState([]);

  const [dashboardobject, setDashboardobject] = React.useState({});
  const [totalorders, setTotalorders] = React.useState([]);
  const [cancelorders, setCancelorders] = React.useState([]);
  const [pendingorders, setPendingorders] = React.useState([]);
  const [totalordersname, setTotalordersname] = React.useState("");
  const [totalorderstotal, setTotalorderstotal] = React.useState("");
  const [cancelordersname, setCancelordersname] = React.useState("");
  const [cancelorderstotal, setCancelorderstotal] = React.useState("");
  const [pendingordersname, setPendingordersname] = React.useState("");
  const [pendingorderstotal, setPendingorderstotal] = React.useState("");

  const [checked1, setChecked1] = React.useState(true);
  const [checked2, setChecked2] = React.useState(true);
  const [checked3, setChecked3] = React.useState(true);
  const [checked4, setChecked4] = React.useState(true);
  const [checked5, setChecked5] = React.useState(true);
  const [checked6, setChecked6] = React.useState(true);
  const [checked7, setChecked7] = React.useState(true);
  const [checked8, setChecked8] = React.useState(true);
  const [checked9, setChecked9] = React.useState(true);
  const [checked10, setChecked10] = React.useState(true);

  const [growthratedata, setGrowthratedata] = React.useState([]);
  const [monthlyorder, setMonthlyorder] = React.useState([]);
  const [donut, setDonut] = React.useState({});

  const handleChange1 = (e) => {
    setChecked1(e.target.checked);
  };
  const handleChange2 = (e) => {
    setChecked2(e.target.checked);
  };
  const handleChange3 = (e) => {
    setChecked3(e.target.checked);

  };
  const handleChange4 = (e) => {
    setChecked4(e.target.checked);

  };
  const handleChange5 = (e) => {
    setChecked5(e.target.checked);
  
  };
  const handleChange6 = (e) => {
    setChecked6(e.target.checked);
  
  };
  const handleChange7 = (e) => {
    setChecked7(e.target.checked);
   
  };
  const handleChange8 = (e) => {
    setChecked8(e.target.checked);
    
  };
  const handleChange9 = (e) => {
    setChecked9(e.target.checked);
   
  };
  const handleChange10 = (e) => {
    setChecked10(e.target.checked);
   
  };

  useEffect(() => {
    dailyDonut()
      getData()
      getDashboarddata()
      growthRate()
      monthlySalesorder()
  }, []);

  const getDashboarddata = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
          "companyid": company
      }
    fetch(global.url + "adminDashboard", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      // console.log("responseJson", responseJson)
      setDashboardobject(responseJson.data)
      setTotalorders(responseJson.data.salesorder.salesorder)
      setCancelorders(responseJson.data.cancelorder.cancelorder)
      setPendingorders(responseJson.data.pendingorder.pendingorder)
        setTotalorderstotal(responseJson.data.salesorder.total)
        setCancelorderstotal(responseJson.data.cancelorder.total)
        setPendingorderstotal(responseJson.data.pendingorder.total)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const growthRate = () => {
    fetch(global.url + "salespurchaseMonthwise", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((response) => response.json())
    .then((responseJson) => {
        setGrowthratedata(responseJson.data)
    })
  }

  const dailyDonut = () => {
    fetch(global.url + "dailyDonut", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((response) => response.json())
    .then((responseJson) => {
        if(responseJson.error == false){
            setDonut(responseJson.data)
        }
        
    })
  }

  const monthlySalesorder = () => {
    fetch(global.url + "salesorderMonthwise", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((response) => response.json())
    .then((responseJson) => {
        setMonthlyorder(responseJson.data)
    })
  }

  const getData = () => {
      setLoader(true)
      fetch(global.url + "viewcompany", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setSource(responseJson.data)
          setLoader(false)
      })
      .catch((error)=>{
          console.log(error)
      })

      getpdc()
  }

  const getpdc = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
        "companyid":  company,
    }
    fetch(global.url + "viewPDCAll", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setPdclist(responseJson.data)
          var array = []
          for(var i=0; i < responseJson.data.length; i++ ){
            array.push(responseJson.data[i].pdc_status)
          }
          setStatusarray(array)
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const statusChange = (event, id) => {

        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid":  company,
            "status": event.target.value,
            "pdc_id":  id
        }
        fetch(global.url + "PDCDone", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.error == false){
                getpdc()
                window.location.reload();
                alert(responseJson.message)
              }
              else{
                alert(responseJson.message)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }



  return (
        <div className="main-content">
            <div sx={{ width: '100%', overflow: 'hidden' }}>
            <Row>
                <div style={{float: 'right'}}>
                    <a type='button' onClick={() => setModal(true)}>
                        <AiOutlineSetting className="spinner" style={{fontSize:40}}/>
                    </a>
                </div>
                </Row>
                {checked1 ?
                    <div>
                        <Row>
                            <span style={{marginTop: 30}}><strong>Sales Order</strong></span>
                        </Row>

                        <Row style={{marginTop: 30}}>
                            <Col sm={3}>
                            
                                <div className="card w_data_1" style={{background: '#bdabc7', }}>
                                    <div className="card-body" style={{background: '#bdabc7'}}>
                                        <div style={{position: 'absolute', right: 0, top: 0, }}>
                                        <a href="reports/Salesorderreport">
                                            <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                        </a>
                                        </div>
                                        <span style={{color: '#fff', marginTop: 10}}><strong>DAILY</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3 animate-charcter">QAR {dashboardobject.todaysalesorder}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}> 
                            
                                <div className="card w_data_1" style={{background: '#81bce4', }}>
                                    <div className="card-body" style={{background: '#81bce4'}}>
                                        <span style={{color: '#fff'}}><strong>THIS MONTH</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3 animate-charcter">QAR {dashboardobject.monthlysalesorder}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                            
                                <div className="card w_data_1" style={{background: '#dcc8c3'}}>
                                    <div className="card-body" style={{background: '#dcc8c3'}}>
                                        <span style={{color: '#fff'}}><strong>THIS YEAR</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3 animate-charcter">QAR {dashboardobject.yearlysalesorder}</h4>
                                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                    </div>: null }
                    {checked2 ?
                    <div>
                        <Row>
                            <span style={{marginTop: 30}}><strong>Sales</strong></span>
                        </Row>

                        <Row style={{marginTop: 30}}>
                            <Col sm={3}>
                            
                                <div className="card w_data_1" style={{background: '#bebee0', }}>
                                    <div className="card-body" style={{background: '#bebee0'}}>
                                        <div style={{position: 'absolute', right: 0, top: 0, }}>
                                        <a href="reports/salesreport">
                                            <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                        </a>
                                        </div>
                                        <span style={{color: '#fff', marginTop: 10}}><strong>DAILY</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.todaysales}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}> 
                            
                                <div className="card w_data_1" style={{background: '#6f9a9b', }}>
                                    <div className="card-body" style={{background: '#6f9a9b'}}>
                                        <span style={{color: '#fff'}}><strong>THIS MONTH</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.monthlysales}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                            
                                <div className="card w_data_1" style={{background: '#9acf9b'}}>
                                    <div className="card-body" style={{background: '#9acf9b'}}>
                                        <span style={{color: '#fff'}}><strong>THIS YEAR</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.yearlysales}</h4>
                                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                    </div>:null}
                {checked3 ?
                <div>
                    <Row>
                        <span style={{marginTop: 30}}><strong>Purchase</strong></span>
                    </Row>
                    <Row style={{marginTop: 30}}>
                        <Col sm={3}>
                        
                            <div className="card w_data_1" style={{background: '#65cbce'}}>
                                <div className="card-body" style={{background: '#65cbce'}}>
                                    <div style={{position: 'absolute', right: 0, top: 0, }}>
                                    <a href="/Reports/PurchaseReport">
                                        <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                    </a>
                                    </div>
                                    <span style={{color: '#fff', marginTop: 10}}><strong>DAILY</strong></span>
                                    <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.todaypurchse}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="card w_data_1" style={{background: '#7B68EE'}}>
                                <div className="card-body" style={{background: '#7B68EE'}}>
                                    <span style={{color: '#fff'}}><strong>THIS MONTH</strong></span>
                                    <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.monthlypurchase}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="card w_data_1" style={{background: '#ADD8E6'}}>
                                <div className="card-body" style={{background: '#ADD8E6'}}>
                                    <span style={{color: '#fff'}}><strong>THIS YEAR</strong></span>
                                    <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.yearlypurchase}</h4>
                                </div>
                            </div>
                        </Col> 
                    </Row>
                </div>:null}
                {checked4 ?
                <div>
                    <Row>
                        <span style={{marginTop: 30}}><strong>Sales Orders</strong></span>
                    </Row>
                    <Row>
                        <Col sm={4} md={2} style={{marginTop: 30}}>
                                <div className="card w_data_1" style={{background: '#65cbce', width: 180, height: 180, borderRadius: '100%'}}>
                                    <div className="card-body" style={{background: '#65cbce', borderRadius: '100%', paddingTop: 50}}>
                                        <div style={{position: 'absolute', right: 20, top: 20 }}>
                                            <a href="/Sales/SalesApproval">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                        </div>
                                        <span style={{color: '#fff'}}><strong>Total Sales Orders</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3">{totalorderstotal}</h4>
                                    </div>
                                </div>
                            </Col>
                        {totalorders?.map((item) => (
                            <Col sm={4} md={2} style={{marginTop: 30}}>
                                <div className="card w_data_1" style={{background: '#65cbce', width: 180, height: 180, borderRadius: '100%'}}>
                                    <div className="card-body" style={{background: '#65cbce', borderRadius: '100%', paddingTop: 50}}>
                                        <div style={{position: 'absolute', right: 20, top: 20 }}>
                                            <a href="/Sales/SalesApproval">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                        </div>
                                        <span style={{color: '#fff'}}><strong>{item.name}</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3">{item.total}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                        
                    </Row>
                </div>: null}
                {checked5 ?
                <div>
                    <Row>
                        <span style={{marginTop: 30}}><strong>Pending Orders</strong></span>
                    </Row>
                    <Row>
                        <Col sm={4} md={2} style={{marginTop: 30}}>
                            <div className="card w_data_1" style={{background: '#bebee0', width: 180, height: 180, borderRadius: '100%'}}>
                                <div className="card-body" style={{background: '#bebee0', borderRadius: '100%', paddingTop: 50}}>
                                    <div style={{position: 'absolute', right: 20, top: 20 }}>
                                        <a href="/Sales/SalesApproval">
                                            <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                        </a>
                                    </div>
                                    <span style={{color: '#fff'}}><strong>Total Pending Order</strong></span>
                                    <h4 style={{color: '#fff'}} class="mt-3">{pendingorderstotal}</h4>
                                </div>
                            </div>
                        </Col>
                        {pendingorders?.map((item) => (
                            <Col sm={4} md={2} style={{marginTop: 30}}>
                                <div className="card w_data_1" style={{background: '#bebee0', width: 180, height: 180, borderRadius: '100%'}}>
                                    <div className="card-body" style={{background: '#bebee0', borderRadius: '100%', paddingTop: 50}}>
                                        <div style={{position: 'absolute', right: 20, top: 20 }}>
                                            <a href="/Sales/SalesApproval">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                        </div>
                                        <span style={{color: '#fff'}}><strong>{item.name}</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3">{item.total}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>:null}
                {checked6 ?
                <div>
                    <Row>
                        <span style={{marginTop: 30}}><strong>Cancel Orders</strong></span>
                    </Row>
                    <Row>
                        <Col sm={3} style={{marginTop: 30}}>
                                <div className="card w_data_1" style={{background: '#6f9a9b', height: 115}}>
                                    <div className="card-body" style={{background: '#6f9a9b'}}>
                                        <div style={{position: 'absolute', right: 0, top: 0, }}>
                                            <a href="/Reports/cancelorder">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                        </div>
                                        <span style={{color: '#fff', marginTop: 10}}><strong>Total Cancel Orders</strong></span>
                                        <h4 style={{color: '#fff', fontSize: 35}} class="mt-3 btn-shine">{cancelorderstotal}</h4>
                                    </div>
                                </div>
                            </Col>
                        {cancelorders.map((item) => (
                            <Col sm={3} style={{marginTop: 30}}>
                                <div className="card w_data_1" style={{background: '#6f9a9b', height: 115}}>
                                    <div className="card-body" style={{background: '#6f9a9b'}}>
                                        <div style={{position: 'absolute', right: 0, top: 0, }}>
                                            <a href="/Reports/cancelorder">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                        </div>
                                        <span style={{color: '#fff', marginTop: 10}}><strong>{item.name}</strong></span>
                                        <h4 style={{color: '#fff', fontSize: 35}} class="mt-3 btn-shine">{item.total}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>: null }
                <div>
                    <Row>
                        <span style={{marginTop: 30}}><strong>Sales</strong></span>
                    </Row>
                    <Row>
                        <Col sm={4} md={2} style={{marginTop: 30}}>
                            <div className="card w_data_1" style={{background: '#dcc8c3', width: 180, height: 180, borderRadius: '100%'}}>
                                <div className="card-body" style={{background: '#dcc8c3', borderRadius: '100%', paddingTop: 50}}>
                                    {/* <div style={{position: 'absolute', right: 20, top: 20 }}>
                                        <a href="/Sales/SalesApproval">
                                            <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                        </a>
                                    </div> */}
                                    <span style={{color: '#fff'}}><strong>Total Sales</strong></span>
                                    <h4 style={{color: '#fff'}} class="mt-3">{pendingorderstotal}</h4>
                                </div>
                            </div>
                        </Col>
                        {pendingorders?.map((item) => (
                            <Col sm={4} md={2} style={{marginTop: 30}}>
                                <div className="card w_data_1" style={{background: '#dcc8c3', width: 180, height: 180, borderRadius: '100%'}}>
                                    <div className="card-body" style={{background: '#dcc8c3', borderRadius: '100%', paddingTop: 50}}>
                                        {/* <div style={{position: 'absolute', right: 20, top: 20 }}>
                                            <a href="/Sales/SalesApproval">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                        </div> */}
                                        <span style={{color: '#fff'}}><strong>{item.name}</strong></span>
                                        <h4 style={{color: '#fff'}} class="mt-3">{item.total}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
                {/* {checked7?
                <div>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} >
                            <span><strong>Manage pdc</strong></span>
                        </Col>
                    </Row>
                    <Row>
                            <Col xs={12}>
                                <Table bordered responsive style={{height: 100}}>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Status</th>
                                            <th>Cheque No</th>
                                            <th>Entry date</th>
                                            <th>Post date</th>
                                            <th>Done date</th>
                                            <th>Client name</th>
                                            <th>Bank</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pdclist.map((i,k) => {
                                                return(
                                                    <tr>
                                                        <td>{k+1}</td>
                                                        <td>
                                                        {i.pdc_status == 1? <label>Paid</label> :i.pdc_status == 2? <label>Pending</label>:<label>Returned</label>  }
                                                        {
                                                            i.pdc_status == 1 || i.pdc_status == 3? null :
                                                                <FormControl sx={{  width: '100%', mt:3 }}>
                                                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Status</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-helper-label"
                                                                                id="demo-simple-select-helper"
                                                                                value={statusarray[15]}
                                                                                label="Age"
                                                                                onChange={(e) => statusChange(e,i.pdc_id)}
                                                                                size="small"
                                                                                
                                                                            >
                                                                        
                                                                            <MenuItem value="1">Paid</MenuItem>
                                                                            <MenuItem value="2">Pending</MenuItem>
                                                                            <MenuItem value="3">Returned</MenuItem>
                                                                            </Select>
                                                                    </FormControl>

                                                        }
                                                        
                                                        </td>
                                                        <td>{i.pdc_cheque}</td>
                                                        <td>{i.pdc_cheque_date}</td>
                                                        <td>{i.pdc_post_date}</td>
                                                        <td>{i.pdc_date}</td>
                                                        <td>{i.ledger_name}</td>
                                                        <td>{i.pdc_bank_name}</td>
                                                        <td>{i.pdc_amount}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </Table>
                                <div className="table-footer">
                                    <Row>
                                        <Col sm={3}>
                                            <Button variant="contained" >Save</Button>
                                        </Col>
                                        <Col sm={3}>
                                            <Button variant="contained" >Clear</Button>
                                        </Col>
                                        <Col sm={3}>
                                            <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                                </FormControl>
                                            </div>
                                            <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                                </FormControl>
                                            </div>
                                            <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                                </FormControl>
                                            </div>
                                        </Col>
                                        <Col sm={3}>
                                            <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                                </FormControl>
                                            </div>
                                            <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                                </FormControl>
                                            </div>
                                            <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                                </FormControl>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row> 
                    </div>: null} */}
              
                <div>
                    <Row> 
                    {checked8 ?   
                        <Col sm={8}>
                            <div style={{marginTop:40}}>
                                <span style={{fontSize: 14}}><strong>GROWTH RATE OF SALES AND PURCHASE</strong></span>
                                <div style={{marginTop: 30}}>
                                    
                                        <ResponsiveContainer width="100%" height={450}>
                                            <LineChart
                                            width={500}
                                            height={300}
                                            data={growthratedata}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                            style={{backgroundColor: '#fff', padding: 10}}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="sales" stroke="#73f440" activeDot={{ r: 7 }}/>
                                            <Line type="monotone" dataKey="purchase" stroke="#80159e" activeDot={{ r: 5 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                </div>
                            </div>
                        </Col>: null}
                        {/* {checked9 ?
                        <Col sm={4}>
                            <div style={{marginTop:40}}>
                                <span style={{fontSize: 14}}><strong>DAILY GRAPH - SALESORDER, SALES, PURCHASE, PAYMENT</strong></span>
                                <div style={{marginTop: 30, backgroundColor: '#fff', padding: 20}}>
                                    <Doughnut data={data1} style={{marginTop: 20}}/>
                                   
                                </div>
                            </div>
                        </Col>:null} */}
                    </Row>
                </div>
                {checked10?
                <div>
                    <Row style={{marginTop:40}}>
                        <Col xs={12} md={12}>
                            <span style={{fontSize: 14}}><strong>MONTHLY SALESORDER</strong></span>
                            <div style={{marginTop: 30, overflowX: 'auto', padding: 15, backgroundColor: '#fff'}}>
                                <BarChart width={800} height={300} data={monthlyorder}>
                                    <XAxis gridLine dataKey="name" stroke="#8884d8" />
                                    <YAxis gridLine={false} />
                                    <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#d3d3d3' }} />
                                    <Legend width={100} wrapperStyle={{ top: 10, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <Bar dataKey="SO" fill="#38B0DE" barSize={30} />
                                </BarChart>
                            </div>
                        </Col>
                    </Row>
                </div>:null}
                    
            </div>




{/* <Row>
    <Col xs={12} md={6}>
    <ReactFusioncharts
        type="multiaxisline"
        width="100%"
        height="100%"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </Col>
</Row> */}

    {/* <Row style={{marginTop:40}}>
        <Col xs={12} md={6}>
            <div style={{marginTop: 30, overflowX: 'auto', padding: 15, backgroundColor: '#fff'}}>
            <LineChart width={730} height={250} data={dataa}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#8884d8" />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            <Line type="monotone" dataKey="tv" stroke="#b842b6" />
        </LineChart>
            </div>
        </Col>
  
        <Col xs={12} md={6}>
            <div style={{marginTop: 30, overflowX: 'auto', padding: 15, backgroundColor: '#fff'}}>
            <RadialBarChart 
            width={730} 
            height={250} 
            innerRadius="20%" 
            outerRadius="100%" 
            data={data2} 
            startAngle={180} 
            endAngle={0}
            >
            <RadialBar minAngle={15} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey='uv' />
            <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />
            <Tooltip />
            </RadialBarChart>
            </div>
        </Col>
    </Row> */}
{/* 
    <Row>
        <Col>
        <div style={{marginTop: 30, overflowX: 'auto', padding: 15, backgroundColor: '#fff'}}>
        <div id="chart">
           <ReactApexChart 
           options={state.options} 
           series={state.series} 
           type="area" 
           height={350} />
        </div>
        </div>
        </Col>
    </Row> */}


    {/* <Row>
        <Col>
        <div style={{marginTop: 30, overflowX: 'auto', padding: 15, backgroundColor: '#fff'}}>
        <div id="chart">
  <div class="toolbar">
    <button id="one_month"
        
        onClick={()=> updateData('one_month')} className={ (states.selection==='one_month' ? 'active' : '')}>
      1M
    </button>
    &nbsp;
    <button id="six_months"
        
        onClick={()=> updateData('six_month')} className={ (states.selection==='six_months' ? 'active' : '')}>
      6M
    </button>
    &nbsp;
    <button id="one_year"
        
        
        onClick={()=> updateData('one_year')} className={ (states.selection==='one_year' ? 'active' : '')}>
      1Y
    </button>
    &nbsp;
    <button id="ytd"
        
        onClick={()=> updateData('ytd')}className={ (states.selection==='ytd' ? 'active' : '')}>
      YTD
    </button>
    &nbsp;
    <button id="all"
        
        onClick={()=> updateData('all')} className={ (states.selection==='all' ? 'active' : '')}>
      ALL
    </button>
  </div>

  <div id="chart-timeline">
  <ReactApexChart options={states.options} series={states.series} type="area" height={350} />
</div>
</div>
        </div>
        </Col>
    </Row> */}


            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12}>
                        <div style={{padding: 50}}>
                            <Row>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Sales Order Qar</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked1 == true ? true : false} onChange={e => handleChange1(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Sales</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked2 == true ? true : false} onChange={e => handleChange2(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Purchase</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked3 == true ? true : false} onChange={e => handleChange3(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Sales order</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked4 == true ? true : false} onChange={e => handleChange4(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Pending Order</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked5 == true ? true : false} onChange={e => handleChange5(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Cancel Order</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked6 == true ? true : false} onChange={e => handleChange6(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">PDC</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked7 == true ? true : false} onChange={e => handleChange7(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Growth Rate Of Client</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked8 == true ? true : false} onChange={e => handleChange8(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Sales Graph</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked9 == true ? true : false} onChange={e => handleChange9(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={4}>
                                    <p className="setting-para">Employee Statistics</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={ checked10 == true ? true : false} onChange={e => handleChange10(e)} />} label="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} style={{marginTop:30}}>
                      <Button style={{float: 'right'}} variant="contained">Save</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Close</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default Dashboard;
