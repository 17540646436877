import '../Tax-Settings/TaxSettings.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import './purchase.css'
import { useParams } from 'react-router-dom';
import Loader from '../../loader/loader';
import Loader2 from "react-loader-spinner";
import { Typeahead } from 'react-bootstrap-typeahead';


function Purchase(props) {
    const [btnloader, setBtnloader] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const {id} = useParams()
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [date2, setDate2] = React.useState(new Date);
    const [tableRow,setTableRow] = React.useState([{key:"",product_batch_id:"", code: "", productname: "", pricelevel: "", pd_qty: "0", unit: "", price: "", calcprice: 0, discount: "", pd_id: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true }])
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [supplierList, setSupplierList] = React.useState([])
    const [supplier, setSupplier] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState(0);
    const [advance, setAdvance] = React.useState('');
    const [amount, setAmount] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);
    const [qtytotal, setQtytotal] = React.useState(0);
    const [productlist, setProductlist] = React.useState([]);
    const [purchaseorderlist, setPurchaseorderlist] = React.useState([]);

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const [purchaselpoid, setPurchaselpoid] = useState("")

    const [payable, setPayable] = React.useState(0);
    const [roundoff, setRoundoff] = React.useState(0);
    const [paymentmode, setPaymentmode] = React.useState('bank');
    

    const [searchinvoice, setSearchinvoice] = React.useState('');
    const [searchsupplier, setSearchsupplier] = React.useState('');

    const [accountbal, setAccountbal] = React.useState('');
    const [landingCost, setLandingCost] = React.useState(0);    

    const [purchaseid, setPurchaseid] = React.useState('')
    const [landingHide, setLandinghide] = React.useState(false)

    const [ledgerName, setLedgerName] = React.useState("");
    const [ledgerList, setLedgerlist] = React.useState([]);
    const [selected, setSelected] = useState([]);
    const [selected2, setSelected2] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const [begindate, setBegindate] = React.useState("");
    const [productsid, setProductid] = React.useState("");

    useEffect(() => {
        beginDate()
      }, [])
    
    const beginDate = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
        "companyid": company
        }
        fetch(global.url + "beginDate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false){
                setBegindate(responseJson.data.company_begindate)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();

    const [lcsupplierid, setLcsupplierid] = React.useState("");
    const [lcsexpenseid, setLcexpenseid] = React.useState("");
    const [lcsuppliername, setLcsuppliername] = React.useState("");
    const [lcsexpensename, setLcexpensename] = React.useState("");
    const [ledgerhide1, setLedgerhide1] = React.useState(false);
    const [ledgerhide2, setLedgerhide2] = React.useState(false);
    const [supplierinv, setSupplierinv] = React.useState("");
    const [supinvdate, setSubinvdate] = React.useState(new Date);

    useEffect(()  => {
        if(selected.length == 0){
            setLcsupplierid("")
        } else {
            setLcsupplierid(selected[0].ledger_id)
        }
    }, [selected])

    useEffect(()  => {
        if(selected2.length == 0){
            setLcexpenseid("")
        } else {
            setLcexpenseid(selected2[0].ledger_id)
        }
    }, [selected2])

    const lcExpenseChange = (value) => {
        setLcexpenseid(value[0].ledger_id)
        setSelected2(value)
    }

  const  searchsupplierChange = (event) => {
    setSearchsupplier(event.target.value);
  };

  useEffect(() => {
    getLedger()
    seleteAccount()
  }, []);

const seleteAccount = () => {
    fetch(global.url + "SelectAccount",{
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'APPKEY': 'erp6129'
        },
    
    })
    .then((response) => response.json())
    .then((responseJson) => {
        if(responseJson.error == false){
            setFilteredData(responseJson.data)
        } else {
            setFilteredData([])
        }
    })
    .catch((error) => {
        console.log(error)
    })
}

  const getLedger = () => {
      fetch(global.url + "LedgerSelection", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
          if(responseJson.error == false){
            setLedgerlist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const colorChange = (event) => {
        var input=[...tableRow]
        input[selectedIndex].product_batch_id = event.target.value
        setTableRow(input)
        getProductbybatch1(event.target.value, selectedIndex)
    }

    const getProductbybatch1 = (id, index) => {
        var data = {
            "pb_id" : id,
            "products_id_master" : id,
            "products_id" : productsid
        }
    
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setDetails(id, index, responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        input[index].pb_stock = responseJson.pb_stock
        input[index].pd_qty = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name

        input[index].pd_purchase_price = responseJson.pb_purchaseprice
        input[index].discount = 0
        input[index].pd_total_amount = responseJson.pb_purchaseprice 
        setTableRow(input)
    }

    const addRow = () => {
        setTableRow([...tableRow, {key:"",product_batch_id:"", code: "", productname: "", pricelevel: "", pd_qty: "0", unit: "", price: "", calcprice: 0, discount: "", pd_id: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true}])
    }

    const supplierChange = (event) => {
        setSupplier(event.target.value);
        getSupplierbyid(event.target.value)
        getSupplierbalance(event.target.value)
    };

    const getSupplierbalance = (id) => {
        var data = {
            "supplier_id":  id
        }
        fetch(global.url + "viewSupplierBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {
                setBalance(responseJson.opening_balance)
                setLimitbalance(responseJson.remain_balance)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = event
        var result = amount - disc
        var grandtotal1  =  result + parseFloat(otherCharges) 
    }

    useEffect(() => {
        var gradtot = parseFloat(amount) - parseFloat(discount) + parseFloat(otherCharges) - parseFloat(roundoff)
        setGrandtotal(gradtot)
    }, [amount, discount, otherCharges, roundoff])

    useEffect(() => {
       var gradqty = 0;
        for( var i=0; i<tableRow.length; i++){
            gradqty += parseFloat(tableRow[i].pd_qty)
        }
        setQtytotal(gradqty)
    }, [tableRow])

    const getSupplierbyid = (id) => {
        var data = {
            "supplier_id":  id
        }
        fetch(global.url + "getSupplierbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setCreditlimit(responseJson.data.supplier_credit_limit)
            setAddress(responseJson.data.supplier_address1)
            setMobile(responseJson.data.supplier_mobile)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
    
        if ( isDown ) {
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
      }

      useEffect(() => {
        getTotalammount()
      }, [tableRow, discount, roundoff, otherCharges]);

      const getTotalammount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
          totalAmount += parseFloat(tableRow[i].pd_total_amount)
       }
       setAmount(totalAmount)
       var disc =  discount
       setPayable(parseFloat(totalAmount)-disc+parseFloat(otherCharges))
    }

      useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()
      }, []);

      useEffect(() => {
        getBilltype()
        if(id == "add") {
            getInvoiceno()
        }
        else{
           getDetailsbyid(id)
        }
        },[])

        const getDetailsbyid = (id) => {
            setLoader(true)
            var data = {
                'purchase_id' : id
              }
              fetch(global.url + "ViewPurchaseDetailInAction", {
                  method: "POST",
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data),
              })
              .then((response)=> response.json())
              .then((responseJson) => {
                  if(responseJson.error == false){
                    setLoader(false)
                    setBilltype(responseJson.data.billtype)
                    setInvoiceno(responseJson.data.invoice)
                    setRefno(responseJson.data.refno)
                    setSalesman(responseJson.data.salesman)
                    setDate(responseJson.data.date)
                    setLpono(responseJson.data.lpono)
                    setSupplier(responseJson.data.supplier)
                    setCreditlimit(responseJson.data.creditlimit)
                    setBalance(responseJson.data.balance)
                    setLimitbalance(responseJson.data.limitbalance)
                    setQuotation(responseJson.data.qtno)
                    setMobile(responseJson.data.mobno)
                    setLimitbalance(responseJson.data.qtno)
                    setAddress(responseJson.data.address)
                    setPurchaseid(responseJson.data.purchase_id)
                    if(responseJson.data.details){
                    setTableRow(responseJson.data.details)
                    }
                  }
              })
              .catch((error)=>{
                  console.log(error)
              })
        }

      useEffect(() => {
        getSupplier()
      }, []);

      const getInvoiceno = () => {
        fetch(global.url + "Purchaseinvoiceno", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
                setInvoiceno(responseJson.data.invoiceno)
                setInvoNu(responseJson.data.invoiceno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)

        var data = {
          "userid":  userdata.user_id,
          "companyid": company,
          "branchid": branch,
          "type": userdata.user_type,
        }
        fetch(global.url + "viewuser", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
        }
        fetch(global.url + "viewBillSetting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setBilltypelist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getSupplier = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type":  userdata.user_type,
            "companyid": company
          }
        fetch(global.url + "viewSupplier", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.Error == "false") {
                setSupplierList(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      useEffect(() => {
        if ( hasResults ) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
          document.body.removeEventListener('keydown', onKeyDown1);
        }
      }, [hasResults, hasResultsProduct]);

      const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].pd_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].discount = discount
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"", code: "", productname: "", pricelevel: "", pd_qty: "0", unit: "", price: "", calcprice: 0, discount: "", pd_id: "", pd_total_amount: 0, colorsize: [], pd_purchase_price: 0, addnew: true }])
            setCode("")
      }

      const deleteRow = (index, id) => {
        var input = [...tableRow]
        input.splice(index, 1)
        setTableRow(input)
        // deleteFromApi(id)
      }

      const deleteFromApi = (id) => {
        if(window.confirm('Are you Want to delete this ? It will be deleted from your database')){
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        const data = {
            "pd_id" : id,
            "userid": userdata.user_id,
            "companyid": company
        }
        fetch(global.url + "deletePurchasedetailinEdit", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }else{
        window.location.reload()
    }
}


      const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const landingcostCalc = () => {
        var totalqty = 0;
        for (var i =0 ; i<tableRow.length ; i++) {
            var input = [...tableRow]
            totalqty += parseFloat(input[i].pd_qty)
        }
        for (var i =0 ; i<tableRow.length ; i++) {
                var input = [...tableRow]
                // input[i].calcprice = parseFloat(landingCost) / parseFloat(payable) * parseFloat(input[i].pd_total_amount) / parseFloat(input[i].pd_qty)  
                input[i].calcprice = (parseFloat(landingCost) / parseFloat(totalqty))*parseFloat(input[i].pd_qty)
        }
        setTableRow(input)
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        var totalprice = textArray1[index].pd_purchase_price * event.target.value
        textArray1[index].pd_qty = event.target.value
        textArray1[index].pd_total_amount = totalprice
        landingcostCalc()
        // var totalcalc = (parseFloat(landingCost) / parseFloat(payable)) * (parseFloat(textArray1[index].pd_total_amount) / event.target.value)
        // console.log("totalcalc", totalcalc)
        // textArray1[index].calcprice = totalcalc
        setTableRow(textArray1)
    }

    const priceChange = (event, index) => {
        var textArray1 = [...tableRow]
        textArray1[index].pd_purchase_price = event.target.value

        // textArray1[index].calcprice = event.target.value * textArray1[index].pd_qty
        textArray1[index].pd_total_amount = event.target.value * textArray1[index].pd_qty

        setTableRow(textArray1)
    }

    const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        
        fetch(global.url + "getProductbySearch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setProductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();
  
          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          
  
          if(type == "time") {
            var formattedDate = timeString12hr;
          }
          else{
            var formattedDate =  year+"-"+month+"-"+day
          }
          return formattedDate; //03/02/2021
    }

    const onSave = () => {
        if(supplier == ""){
            alert("Please select Supplier")
        }else{
            setBtnloader(true)
            const exampleArray = []
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].product_batch_id == "") {
                }
                else{
                    exampleArray.push(tableRow[i])
                } 
            }
            var start = formatDate(date)
            var end = formatDate(supinvdate)
            var data = {
                "purchase_id" : purchaseid,
                "purchase_user_id" : userId,
                "purchase_bill_type":  billType,
                "purchase_ref_no":  refno,
                "purchase_salesman":  salesman,
                "purchase_invoice":  invoiceno,
                "purchase_date":  start,
                "purchase_lpo_no":  lpono,
                // "purchase_lpo_date":  lpodate1,
                "purchase_customer":  supplier,
                "purchase_del_no":  delno,
                "purchase_order_no":  orderno,
                "purchase_address":  address,
                "purchase_mob_no":  mobile,
                "purchase_qt_no":  quotation,
                "purchase_crdt_limit":  creditLimit,
                "purchase_balance":  balance,
                "purchase_limit_balance":  limitbalance,
                "purchase_total":  grandtotal,
                "purchase_discount":  discount,
                "purchase_other_charges":  otherCharges,
                "purchase_advance":  advance,
                "purchase_details": exampleArray,
                "purchase_branchid" : userbranchid,
                "purchase_companyid" : usercompanyid,
                "purchase_lpo_id": purchaselpoid,
                "payable": amount,
                "mode": paymentmode,
                "purchase_landingcost" : landingCost,
                "purchase_roundof" : roundoff,
                "lcsupplier" : lcsupplierid,
                "lcexpense" : lcsexpenseid,
                "purchase_supplier_invoice" : supplierinv,
                "purchase_supplier_date" : end
            }
            // console.log("data save ", data)
            fetch(global.url + "updatePurchase", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                if(responseJson.error == true) {
                    alert(responseJson.message)
                    setBtnloader(false)
                }
                else{
                    alert(responseJson.message)
                    window.open('/purchaseprint/'+ responseJson.data, "_blank");
                    window.location.reload();
                    setBtnloader(false)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const onPopup = () => {
       setModal(true) 
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewPurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.status == "true") {
                setPurchaseorderlist([])
            }
            else{
                setPurchaseorderlist(responseJson.data)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const purchaseOrderclick = (id) => {

        var data = {
            "purchaseorder_id": id,
        }
        fetch(global.url + "purchaseOrderFill", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setModal(false)
            setRefno(responseJson.data.purchase_ref_no)
            setSalesman(responseJson.data.purchase_salesman)
            setLpono(responseJson.data.purchase_invoice)
            setCreditlimit(responseJson.data.purchase_crdt_limit)
            setBalance(responseJson.data.purchase_balance)
            setLimitbalance(responseJson.data.purchase_limit_balance)
            setSupplier(responseJson.data.purchase_customer)
            setMobile(responseJson.data.purchase_mob_no)
            setAddress(responseJson.data.purchase_address)
            setQuotation(responseJson.data.purchase_qt_no)
            setTableRow(responseJson.data.purchaseorder_details)
            setPurchaselpoid(id)

        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

    const roundOffchange = (event) => {
        
        setRoundoff(event)
        var round = amount - event
        var grandtotal1  =  round
    }

    const landingcostChange = (event) => {
        setLandinghide(true)
        setLandingCost(event)
    }



    const listClickrefcode = (index, productid, productrefcode,productname,products, iscombo) => {
        setProductid(productid)
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, {key:"",product_batch_id:"", product_id: "", code: "",colorsize: [], productname: "", pricelevel: "", pd_qty: "0", unit: "", price: "", calcprice: 0, discount: "", pd_id: "", pd_total_amount: 0, pb_stock: "", sales_iscombo: "",pd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        setCode("")
    }

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "invoice" : searchinvoice,
          "supplier": searchsupplier
        }
        fetch(global.url + "viewPurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.status == "true") {
                setPurchaseorderlist([])
            }
            else{
                setPurchaseorderlist(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        
      }, [searchinvoice, searchsupplier]);

    const upClick = () => {
        setInvoiceno(preCount => preCount + 1)
    }

    const downClick = () => {
        if(invoiceno > 1){
            setInvoiceno(preCount => preCount - 1)
        }
    }



    const purchaseDetails = () => {
        setLoader(true)
        var data = {
            'invoice_no' : invoiceno
          }
          fetch(global.url + "PurchasedetailByInvoiceno", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson) => {
              setLoader(false)
              if(responseJson.error == false){
                setLedgerhide1(true)
                setLedgerhide2(true)
                setPurchaseid(responseJson.data.purchase_id)
                setBilltype(responseJson.data.billtype)
                setRefno(responseJson.data.refno)
                setSalesman(responseJson.data.salesman)
                setDate(responseJson.data.date)
                setLpono(responseJson.data.lpono)
                setSupplier(responseJson.data.supplier)
                setCreditlimit(responseJson.data.creditlimit)
                setBalance(responseJson.data.balance)
                setLimitbalance(responseJson.data.limitbalance)
                setQuotation(responseJson.data.qtno)
                setMobile(responseJson.data.mobno)
                setLimitbalance(responseJson.data.qtno)
                setAddress(responseJson.data.address)
                setLandingCost(responseJson.data.landingcost)
                setDiscount(responseJson.data.discount)
                setRoundoff(responseJson.data.roundoff)
                setLcsuppliername(responseJson.data.purchase_lcsupplier_name)
                setLcexpensename(responseJson.data.purchase_lcexpense_name)
                setLcsupplierid(responseJson.data.purchase_lcsupplier)
                setLcexpenseid(responseJson.data.purchase_lcexpense) 
                setSupplierinv(responseJson.data.purchase_supplier_invoice) 
                setSubinvdate(responseJson.data.purchase_supplier_date) 
                if(responseJson.data.details){
                setTableRow(responseJson.data.details)
                }
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
                <Row>
                    <Col xs={12} md={2}>
                        <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Purchase</p>
                    </Col>
                    <Col xs={12} md={2}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField label="Invoice" value={invoiceno} onChange={(e) => setInvoiceno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col md={1}>
                        <div style={{marginTop: -8}}>
                        {invoNum == invoiceno ?
                            <Button  style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>:
                            <Button onClick={upClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>}
                            <Button onClick={downClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleDown  color="White" fontSize={15} /></Button>
                        </div>
                    </Col>
                    <Col md={2}>
                        <Button onClick={purchaseDetails} style={{backgroundColor: '#6d37b9', border: 'none', color: '#fff', height: 30}} variant="outlined">Find</Button>
                    </Col>
                    <Col md={5}>
                        <div style={{float: 'right'}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date"
                                    value={date}
                                    minDate={new Date(begindate)}
                                    onChange={(newValue) => {
                                    setDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div> 
                    </Col>
                </Row>
               {loader ? <Loader /> :
               <div>   
                    <Row >
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }} onClick={onPopup}>
                                <TextField label="LPO No." className="cursor-pointer" disabled autoComplete="off" onChange={(e) => setLpono(e.target.value)} value={lpono} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Ref No." autoComplete="off" onChange={(e) => setRefno(e.target.value)} value={refno} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={supplier}
                                    label="Age"
                                    onChange={supplierChange}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {supplierList.map((row, index) => {
                                    return (
                                    <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                )})}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Supplier Inv" onChange={(e) => setSupplierinv(e.target.value)} value={supplierinv} autoComplete="off"  id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                label="Supplier Inv Date"
                                    value={supinvdate}
                                    onChange={(newValue) => {
                                        setSubinvdate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="QT No." autoComplete="off"  onChange={(e) => setQuotation(e.target.value)} value={quotation} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                           {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Address" onChange={(e) => setAddress(e.target.value)} value={address} autoComplete="off" id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col> */}
                    </Row>   
                        <Row>
                            {/* <Col xs={12} md={4} style={{marginTop: 20}}>
                                <TextareaAutosize
                                    label="Address"
                                    autoComplete="off"
                                    onChange={(e) => setAddress(e.target.value)} value={address}
                                    aria-label="minimum height"
                                    minRows={3}
                                    style={{ width: '100%' }}
                                />
                            </Col> */}
                         
                            {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Mobile" autoComplete="off" onChange={(e) => setMobile(e.target.value)} value={mobile} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Crdt Limit:" value={creditLimit} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Balance" value={balance} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Limit Balance" value={limitbalance} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col> */}
                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                <div style={{textAlign: 'center'}}>Lc Supplier :</div>
                            </Col>
                            <Col xs={12} md={3} style={{marginTop: 20}}>
                                {/* <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Ledger</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={ledgerName}
                                        label="Age"
                                        onChange={ledgerChange}
                                        size="small"
                                    >
                                    
                                    {ledgerList.map((row, index) => {
                                        return (
                                        <MenuItem value={row.ledger_id}>{row.ledger_name}</MenuItem>
                                    )})}
                                    </Select>
                                </FormControl> */}
                                {ledgerhide1 == true ?
                                    <FormControl component="fieldset" onClick={() => setLedgerhide1(false)}>
                                        <TextField autoComplete='off' disabled value={lcsuppliername} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                                    </FormControl> :
                                   <Typeahead
                                        style={{width: 220, boxShadow: 'none'}}
                                        id="basic-example"
                                        onChange={setSelected}
                                        options={filteredData}
                                        placeholder="LC Supplier"
                                        selected={selected}
                                        /> }
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 30}}>
                                <div style={{textAlign: 'center'}}>Lc Expense :</div>
                            </Col>
                            <Col xs={12} md={3} style={{marginTop: 20}}>
                                {ledgerhide2 == true ?
                                    <FormControl component="fieldset" onClick={() => setLedgerhide2(false)}>
                                        <TextField autoComplete='off' disabled value={lcsexpensename} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                                    </FormControl> :
                                   <Typeahead
                                        style={{width: 220, boxShadow: 'none'}}
                                        id="basic-example"
                                        onChange={setSelected2}
                                        options={filteredData}
                                        placeholder="LC Expense"
                                        selected={selected2}
                                     />}
                            </Col>
                        </Row>
                   
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th>Code</th>
                                        <th>Color Size</th>
                                        <th>Product name</th>
                                        <th>Qty</th>
                                        <th>Image</th>
                                        <th>Unit</th>
                                        <th>Price</th>
                                        <th>Landingcost/Unit</th>
                                        {/* <th>Discount</th> */}
                                        <th>Amount</th>
                                        <th>Close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr>
                                                <td >{index+1}</td>
                                                {/* <td >
                                                    <TextField autoComplete="off" onChange={(event, value) => this.inputHandler(event.target.value, index)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                                                    {this.state.selectedIndex == index && this.state.textLength.length >= 1  ? 
                                                    <div ref={this.myRef} className="develop">sacdscsd</div>: null
                                                    }
                                                    </td> */}
                                                <td>
                                                <input autocomplete="off" style={{width: 100,}} ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                
                                                {hasResults && selectedIndex == index && (
                                                                <div className="autocomplete">
                                                                    <ul ref={resultsRef} className="people scrolling-div">
                                                                        {productlist.map(item => {
                                                                            return (
                                                                                item.product_fetchiscombo == 0 ? 
                                                                                item.products.map(result => {
                                                                                    return (
                                                                                    // <li key={result.url}>
                                                                                    //     <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                    //         index, 
                                                                                    //         result.pb_id,
                                                                                    //         result.product_refcode,
                                                                                    //         result.product_name,
                                                                                    //         result.pricelevel_name,
                                                                                    //         "1",
                                                                                    //         result.unit_name,
                                                                                    //         result.pb_salesrate,
                                                                                    //         result.pb_salesrate,
                                                                                    //         0,
                                                                                    //         result.pb_salesrate,
                                                                                    //         result.pricelevel_percentage,
                                                                                    //         result.pb_stock,
                                                                                    //         0,
                                                                                    //         result.color_name,
                                                                                    //         result.size_name,
                                                                                    //         result
                                                                                    //         )}>
                                                                                    //     {result.product_refcode} - { result.product_name } - {result.color_name} - {result.size_name}
                                                                                    //     </a>
                                                                                    // </li>
                                                                                    <li key={item.url}>
                                                                                        <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                            index, 
                                                                                            item.products_id,
                                                                                            item.product_refcode,
                                                                                            item.product_name,
                                                                                            item.products,
                                                                                            0
                                                                                            )}>
                                                                                        {item.product_refcode} - { item.product_name }
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                                }) : 
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                        index, 
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.pricelevel_name,
                                                                                        "1",
                                                                                        item.unit_name,
                                                                                        item.product_comboprice,
                                                                                        item.product_comboprice,
                                                                                        0,
                                                                                        item.product_comboprice,
                                                                                        item.pricelevel_percentage,
                                                                                        item.product_combostock,
                                                                                        1
                                                                                        )}>
                                                                                    {item.product_refcode} - { item.product_name }
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                    </td>
                                                    {
                                                        item.addnew == false? 
                                                        <td>
                                                            {item.color} - {item.size}
                                                        </td>:
                                                        <td>
                                                            <FormControl sx={{width: '100%' }}>
                                                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-helper-label"
                                                                        id="demo-simple-select-helper"
                                                                        value={item.product_batch_id}
                                                                        label="Age"
                                                                        onChange={colorChange}
                                                                        size="small"
                                                                    >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    {item.colorsize.map((row, index) => {
                                                                        return (
                                                                        <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                    )})}
                                                                    </Select>
                                                            </FormControl>
                                                        </td>
                                                    }
                                                    <td style={{width: 300}}>
                                                {item.productname}
                                                </td>
                                                
                                                <td>
                                                <input autocomplete="off" style={{width: 70}} type="number" name="query" value={item.pd_qty} onChange={(event) => qtyChange(event, index)} />
                                                </td>
                                                <td><img src={global.images + 'ecommerce_photos/' + item.pei_image} style={{width: 60, marginLeft: 10}}/></td>
                                                <td>{item.unit}</td>
                                                <td>
                                                    <input autocomplete="off" style={{width: 100}} type="number" name="query" value={item.pd_purchase_price} onChange={(event) => priceChange(event, index)} />
                                                </td>
                                                
                                                <td>{item.productname == '' ? "0" : parseFloat(item.calcprice)?.toFixed(2)}</td>
                                               
                                                {/* <td><input autocomplete="off"  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} /></td> */}
                                                <td>{parseFloat(item.pd_total_amount)?.toFixed(2)}</td>
                                                <td><Button onClick={() => deleteRow(index, item.pd_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button></td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody>
                            </Table>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <FormControl style={{marginTop: 20}}>
                                    <TextField disabled label="Total Qty" value={qtytotal} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                                <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                            </div>
                        </Col>
                    </Row> 
                    <Row>
                        <Col xs ={12} md={1} style={{marginTop:30}}>
                        {
                            btnloader ? 
                            <Loader2
                                type="Oval"
                                color="#38B0DE"
                                height={30}
                                width={30}
                                style={{float: 'left'}}
                            />:
                            <Button onClick={onSave} style={{backgroundColor: '#6d37b9', borderRadius: 20}} variant="contained">Save</Button>}
                            {/* <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><RiDeleteBinLine  color="White" fontSize={20} style={{marginRight: 10}} />Delete</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><MdClearAll  color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button>
                            <Button onClick={purchaseDetails} style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><GiCash  color="White" fontSize={20} style={{marginRight: 10}} />Find</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{marginRight: 10}} />Close</Button> */}
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Purchase A/C" disabled value={amount.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Discount" autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        {/* <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Other Charges" autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} value={otherCharges} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Roundoff" 
                                  inputProps={{ 
                                    min: '9:00', 
                                    max: '16:00',
                                  }}
                                value={roundoff} onChange={(e) => roundOffchange(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField disabled label="Supplier Account"  value={grandtotal.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                            {/* <p style={{color: 'red'}}>Account Bal: {accountbal}</p> */}
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Landing Cost"  onChange={(e) => landingcostChange(e.target.value)} value={landingCost} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={4} style={{marginTop: 10}}>
                            <div>
                                <Button onClick={landingcostCalc} style={{backgroundColor: '#6d37b9', borderRadius: 5,}} variant="contained">Add Landing Cost</Button>
                            </div>
                        </Col>
                    </Row> 
                    
                </div>
                }
            </div>
          </Paper> 
          <Modal
            size="lg"
            show={modal}
            onHide={() => setModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
               Select Purchase Order
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={4}>
                        <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={searchsupplier}
                                    label="Age"
                                    onChange={searchsupplierChange}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {supplierList.map((row, index) => {
                                    return (
                                    <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                )})}
                                </Select>
                        </FormControl>    
                    </Col>
                    <Col xs={4}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField  onChange={(e) => setSearchinvoice(e.target.value)} value={searchinvoice} placeholder='Invoice Number' id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>

                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <Table hover responsive bordered className="section-to-print">
                            <thead>
                                <tr>
                                    <th>Request Invoice</th>
                                    <th>Date</th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                            {purchaseorderlist.map(result => {
                                return (
                                    <tr style={{cursor: "pointer"}}  onClick={() => purchaseOrderclick(result.purchaseorder_id,
                                        result.purchaseorder_invoice, result.purchaseorder_date)}>
                                        <td>{result.purchaseorder_invoice}</td>
                                        <td>{result.purchaseorder_date}</td>
                                        <td>{result.supplier_name}</td>
                                    </tr>
                                )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
      </div>
    )
}
export default Purchase;