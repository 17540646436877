import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
  { id: '1', label: 'Display Zone', minWidth: 170,span: false },
  { id: '2', label: 'AG code', minWidth: 170,span: false },
  { id: '2', label: 'Description', minWidth: 170,span: false },
  { id: '4', label: 'Blocked', minWidth: 170,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    span: true
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  {
    country: 'Lorem Ipsum',
  },
  {
    country: 'Lorem Ipsum',
  },
];

function Locationdisplay(props) {
  const [btnloader, setBtnloader] = React.useState(false);
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  
  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');

  const [loader, setLoader] = React.useState(false);
  const [userId, setUserid] = React.useState("");
  const [source, setSource] = React.useState([]);

  const [dlocationid, setDlocationid] = React.useState("");
  const [dlocationname, setDlocationname] = React.useState("");
  const [dlocationdescription, setDlocationdescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);

  const [segment, setSegment] = React.useState("");
  const [segmentlist, setSegmentlist] = React.useState([]);

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData()
  }, []);

  const editClick = (
    locationid, 
    locationname,
    locationdescription,
    blocked,
    ) => {
      setModal(true)
      setDlocationid(locationid)
      setDlocationname(locationname)
      setDlocationdescription(locationdescription)
      setBlocked(blocked)
      getSegment()
     
  }

  const onAdd = () => {
    setModal(true)
    setDlocationid("")
    setDlocationname("")
    setDlocationdescription("")
    setBlocked(0)
    getSegment()
  }


  const getSegment = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "dropdownSegment", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setSegmentlist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const blockChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }


  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewDisplayloc", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.status == "false") {
            setSource(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const deleteClick = (id) =>  {
    var data = {
      "size_id": id,
    }
    fetch(global.url + "deleteSize", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onSave = () => {
    setBtnloader(true)
    var data = {
      "disloc_id": dlocationid,
      "disloc_name": dlocationname,
      "disloc_description": dlocationdescription,
      "disloc_blocked": blocked,
      "disloc_userid": userId,
      "disloc_branchid": userbranchid,
      "disloc_companyid": usercompanyid,
    }
        fetch(global.url + "updateDisplayloc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {
                getData()
                setModal(false)
                alert(responseJson.message)
                setBtnloader(false)
            }else {
                alert(responseJson.message)
                setBtnloader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const segmentChange = (event) => {
    setSegment(event.target.value)
  }

  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Display Zone</p>
               {
                  usertype == "superadmin" ? 
                  null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell >
                                  {row.disloc_name}
                              </TableCell>
                              <TableCell >
                                  {row.disloc_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.disloc_description}
                              </TableCell>
                              <TableCell >
                                  {row.disloc_blocked == 0 ? "No" : "Yes"}
                              </TableCell>
                              <TableCell >
                              {
                                    usertype == "superadmin" ? 
                                    null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                      editClick(row.disloc_id, 
                                        row.disloc_name,
                                        row.disloc_description,
                                        row.disloc_blocked,
                                       
                                      )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.size_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Display Zone
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setDlocationname(e.target.value)} value={dlocationname} id="outlined-basic" label="Display Zone" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setDlocationdescription(e.target.value)} value={dlocationdescription} id="outlined-basic" label="Display Zone Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => blockChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>

                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button  onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      {/* <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button> */}
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default Locationdisplay;
