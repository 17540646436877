import React, {Component, useEffect, useState, useRef} from 'react';
import './InvoiceBill.css';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

function Purchaserequestinvoice(){
    const { id } = useParams();
    const [date, setDate] = React.useState("")
    const [voucherno, setVoucherno] = React.useState("")
    const [details, setDetails] = React.useState([])
    const [salestotal, setTotal] = React.useState("")
    const [othercharges, setOthercharges] = React.useState("")
    const [customer, setCustomer] = React.useState("")
    const [building, setBuilding] = React.useState("")

    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [customername, setCustomername] = useState('')
    const [area, setArea] = useState('')
    const [remarks, setRemarks] = useState('')
    const [ordertype, setOrdertype] = useState('')
    const [street, setStreet] = useState('')

    const [zone, setZone] = useState('')
    const [mobile, setMobile] = useState('')
    const [lpo, setLpono] = useState('')
    const [tableRow, setTableRow] = useState([])
    const [reference, setReference] = useState('')
    const [salesman, setSalesman] = useState('')

    const [totalamount, setTotalamount] = useState('')
    const [roundoff, setRoundoff] = useState('')
    const [subtotal, setSubtotal] = useState('')

    const [variationamount, setVariationamount] = useState('')
    const [deliverycharge, setDeliverycharge] = useState('')
    const [replacereturn, setReplacereturn] = useState('')
    const [tabledata, setTabledata]=useState([])
    const [tabledetails, setTabledetails]=useState({})

    useEffect(() => {
        getInvoice()
    },[])

    const getInvoice = () => {
        var data={
            "purchaserequest_id" : 2
        }
        fetch(global.url + 'PurchaserequestPrint', {
            method:"POST",
            headers:{
                'Content-Type':'application/json',
                'APPKEY':'erp6129'
            },
            body:JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            // console.log('respon',responseJson )
            if(responseJson.error == false){
                setTabledata(responseJson.data.details)
                setTabledetails(responseJson.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)

        var data = {
            "companyid": company,
            "sales_id": id,
            "type": userdata.user_type
        }
        
        fetch(global.url + "salesPrint", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("print details", responseJson)
            setCustomername(responseJson.data.customer_name)
            setBuilding(responseJson.data.customer_building)
            setArea(responseJson.data.customer_area)
            setRemarks(responseJson.data.customer_remarks)
            setDate(responseJson.data.sales_date)
            setOrdertype(responseJson.data.customer_whatsapp)
            setStreet(responseJson.data.customer_street)
            setZone(responseJson.data.sales_zone)
            setMobile(responseJson.data.customer_mobile)
            setLpono(responseJson.data.sales_invoice)
            setTableRow(responseJson.data.details)
            setReference(responseJson.data.sales_reference)
            setSalesman(responseJson.data.sales_salesman)
            setOthercharges(responseJson.data.sales_other_charges)
            setRoundoff(responseJson.data.sales_roundoff)
            setSubtotal(responseJson.data.sales_subtotal)
            setTotalamount(responseJson.data.sales_total)

            if(responseJson.data.replacereturn == 1){
                setReplacereturn(responseJson.data.replacereturn)
                setVariationamount(responseJson.data.variation_amount)
                setDeliverycharge(responseJson.data.delivery_charge)
                var data = responseJson.data.variation_amount + responseJson.data.delivery_charge
                setTotalamount(data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }, []);

    // useEffect(() => {
    //     var totalAmount = 0;
    //     for (var i = 0; i < tableRow.length; i++) {
    //         totalAmount += tableRow[i].sd_total_amount
    //      }
    //      setTotalamount(totalAmount+10)
    // }, [tableRow]);

    const print = () => {
        window.print()
    }

    return(
        <div>
            <Container fluid >
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        <Button onClick={print} variant="primary">Print</Button>
                        <div className="invoice-box printing">
                            <div className="invoice-inside">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>{global.proname}</p>
                                <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Purchase Request Invoice</p>
                                <Row>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>معاملة المورد</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Supplier: {tabledetails.purchaserequest_supplier}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رقم الفاتورة</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Invoice NO: {tabledetails.purchaserequest_invoice}</p>
                                </Col>
                               
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عميل</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Customer: {customername}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Mob No: {mobile}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رقم القسيمة</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Voucher No: {lpo}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Building: {building}</p>
                                </Col>


                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Street: {street}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Zone: {zone}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Area: {area}</p>
                                </Col>

                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Remarks: {remarks}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Reference: {reference}</p>
                                </Col> */}

                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Date: {tabledetails.purchaserequest_date}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Mode/Term of Payment: Cash</p>
                                </Col>
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Other Reference(s): Cash</p>
                                </Col> */}
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>من خلال إيفاد</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Despatch through: Qatar</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>شروط التوصيل</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Terms of Delivery: Qatar</p>
                                </Col>
                                </Row>    
                                <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div>
                                <div>
                                    <Table bordered className='invoicetable'>
                                        <thead>
                                            <tr>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>SLNo.</p>
                                            </th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Product</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Qty</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Price</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Total</p></th>
                                            </tr>
                                        </thead>
                                      
                                            
                                                
                                
                                                <tbody>
                                                    {
                                                            tabledata.map((item, index) => {
                                                            return(
                                                                <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{item.productname}</td>
                                                                    <td>{item.qty}</td>
                                                                    <td>QAR {item.price}</td>
                                                                    <td>QAR {item.pqd_total_amount}</td>
                                                                </tr>
                                                            )
                                                    
                                                        })
                                                    }
                                                     <tr>
                                                            <td colSpan={3}></td>
                                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Sub Total</p></th>
                                                            <td>{tabledetails.subtotal}</td>
                                                    </tr> 
                                                     <tr>
                                                            <td colSpan={3}></td>
                                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رسوم التوصيل الإضافية</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Extra Delivery Charges</p></th>
                                                            <td>QAR {tabledetails.othercharges}</td>
                                                    </tr>
                                                    {/* <tr>
                                                            <td colSpan={3}></td>
                                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Variationamount</p></th>
                                                            <td>QAR {variationamount}</td>
                                                    </tr> */}
                                                    <tr>
                                                            <td colSpan={3}></td>
                                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المبلغ الإجمالي</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Total Amount</p></th>
                                                            <td>QAR {tabledetails.total}</td>
                                                    </tr>
                                                </tbody> 
                                               
                                            
                                       
                                    </Table>
                                </div>
                                <div style={{marginTop: 30}} className="invoicebill">
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>{global.proname}</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Doha, Qatar</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}


export default Purchaserequestinvoice;