import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'Bank Name', minWidth: 50,span: false },
    { id: '1', label: 'AG code', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
  
  
  const rows = [
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
  const [btnloader, setBtnloader] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [userId, setUserid] = React.useState('');
    const [bank, setBankname] = React.useState('');
    const [bankId, setBankid] = React.useState('');

    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData()
  }, []);

  const editClick = (
    bankid, 
    bankname,
    ) => {
      setModal(true)
      setBankid(bankid)
      setBankname(bankname)
  }

  const onAdd = () => {
    setModal(true)
    setBankid("")
    setBankname("")
  }


  const getData = () => {
      setLoader(true)
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      var data = {
        "usertype": userdata.user_type,
        "companyid": company
      }
      fetch(global.url + "viewBank", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.status == "false"){
              setLoader(false)
              setSource(responseJson.data)
            }
            else{
              setLoader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () => {
      setBtnloader(true)
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      const data = {
        "bank_id": bankId,
        "bank_userid": userdata.user_id,
        "bank_name": bank,
        "bank_branchid" : branch,
        "bank_companyid" : company
      }
      fetch(global.url + "updateBank", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
            setBtnloader(false)
          } else {
            alert(responseJson.message)
            setBtnloader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const deleteClick = (id) =>  {
      var data = {
        "bank_id": id,
      }
      fetch(global.url + "deleteBank", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.message)
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Banks</p>
               {
                    usertype == "superadmin" ? 
                    null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.bank_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row. bank_agcode }
                              </TableCell>
                              <TableCell >
                              {
                                  usertype == "superadmin" ? 
                                  null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                      editClick(row.bank_id, 
                                        row.bank_name, 
                                      )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                                }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.bank_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Bank Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setBankname(e.target.value)} value={bank} id="outlined-basic" label="Bank" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      {/* <Button style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button> */}
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
         
      </div>
    )
}
export default State;