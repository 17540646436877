import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import BtnLoader from '../../components/loader/btnLoader';
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import Loader from "react-loader-spinner";

function StockAdjustment(props) {
    const [btnloader, setBtnloader] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")
    const [bulkfile, setBulkfile] = React.useState();
    const [preview, setPreview] = React.useState(false);
    const [bulkpreview , setBulkpreview] = React.useState([]);

    const [tableRow,setTableRow] = React.useState([{key:"",sa_productbatch_fk:"",product_id: "", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, sd_id: "",color: "", size: "",colorsize: [], fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}] }])
    const inputRef = useRef();
    const resultsRef = useRef();
    const [code, setCode] = useState("");
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [productlist, setProductlist] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState('');
    const [otherCharges, setOthercharge] = React.useState(10);
    const [advance, setAdvance] = React.useState('');
    const [bulkloader, setBulkloader] = React.useState(false);

    const [docno, setDocno] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [refno, setRefno] = React.useState('');
    const [stockhide, setStockhide] = React.useState(false);
    const [narration, setNarration] = React.useState('')
    const [begindate, setBegindate] = React.useState("");
    const [formtype, setFormtype] = React.useState("");
    const [productsid, setProductid] = React.useState("");
    const [username, setUsername] = React.useState("");

    useEffect(() => {
        beginDate()
      }, [])
    
    const beginDate = () => {
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid": company
        }
        fetch(global.url + "beginDate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false){
            setBegindate(responseJson.data.company_begindate)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const hasResults = code.length > 0;

    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const listClickrefcode = (index, productid, productrefcode,productname,products, iscombo) => {
        setProductid(productid)
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, {key:"",sa_productbatch_fk:"",product_id: "", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        setCode("")
    }

    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].sa_productbatch_fk = batchid
        input[index].code = code
        input[index].pb_stock = stock
        input[index].productname = name
        input[index].sales_iscombo = iscombo
        input[index].sa_quantity = qty
        input[index].unit = unit
        input[index].color = color
        input[index].size = size
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        if(discount) {
        
            var totalcalc = (calcprice/100) * discount
        }
        else {
        
            var totalcalc = 0
        }
        input[index].pricelevel = pricelevel
        input[index].sd_total_amount = calcprice - totalcalc
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, {key:"",sa_productbatch_fk:"",product_id: "", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        setCode("")
        var total1 = total + totalcalc
        setTotal(total1)
  }

  const changeBulkfile =(e) => {
    setBulkfile(e.target.files[0])
  }

  useEffect(() => {
    getDocno()
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
  }, []);

  const getDocno = () => {
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid" : company
      }
    fetch(global.url + "getStockAdjustmentVoucherno", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.error == false) {
            setDocno(responseJson.data)
          } 
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        fetch(global.url + "getProductbySearch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setProductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
        
    }

    const colorChange = (event) => {
        var input=[...tableRow]
        input[selectedIndex].sa_productbatch_fk = event.target.value
        setTableRow(input)
        getProductbybatch1(event.target.value, selectedIndex)
    }

    const getProductbybatch1 = (id, index) => {
   
        var data = {
            "pb_id" : id,
            "products_id_master" : id,
            "products_id" : productsid
        }
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
  
            setDetails(id, index, responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    const setDetails = (id, index, responseJson) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].sa_productbatch_fk = id
        input[index].pb_stock = responseJson.pb_stock
        input[index].sa_quantity = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0
        input[index].sd_total_amount = responseJson.pb_salesrate 
        setTableRow(input)
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        var totalprice = textArray1[index].price * event.target.value
        textArray1[index].sa_quantity = event.target.value
        textArray1[index].calcprice = totalprice
        textArray1[index].sd_total_amount = totalprice
        setTableRow(textArray1)
    }

    const addRow = () => {
        setTableRow([...tableRow, {key:"",sa_productbatch_fk:"", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: "", pb_stock: "", sales_iscombo: "", sd_id: "",color: "", size: "",colorsize: [], fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}]}])
    }

    const deleteRow = (index, price) => {
        setTotal(total-price)
        var input = [...tableRow]
        input.splice(index, 1)
        setTableRow(input)
      }

    // const disountChange = (event, index) => {
    //     var input = [...tableRow]
    //     input[index].discount = event.currentTarget.value
    //     var disc = (input[index].calcprice/100) * input[index].discount
    //     input[index].sd_total_amount = input[index].calcprice - disc
    //     setTableRow(input)
    // }

    const onSubmit = (type) => {
        if(type == "addstock") {
            const exampleArray = []
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].sa_productbatch_fk == "" || tableRow[i].sales_isedit == 1) {
                    // tableRow.splice(i, 1)
                }
                else{
                    exampleArray.push(tableRow[i])
                } 
            }
            setBtnloader(true)
            var data = {
                "type" : "add",
                "companyid":  usercompanyid,
                "branchid":  userbranchid,
                "userid":  userId,
                "narration":  narration,
                "bill_type":  "",
                "doc_date":  date,
                "ref_no":  refno,
                "doc_no":  docno,
                "adjustment_table":  exampleArray,
            }
            fetch(global.url + "stockAdjust", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                if(responseJson.error == true) {
                    alert(responseJson.data)
                    setBtnloader(false)
                }
                else{
                    alert(responseJson.message)
                    window.location.reload()
                    setBtnloader(false)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else{
            const exampleArray = []
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].sa_productbatch_fk == "" || tableRow[i].sales_isedit == 1) {
                    // tableRow.splice(i, 1)
                }
                else{
                    exampleArray.push(tableRow[i])
                } 
            }
            var data = {
                "type" : "minus",
                "companyid":  usercompanyid,
                "branchid":  userbranchid,
                "userid":  userId,
                "narration":  narration,
                "bill_type":  "",
                "doc_date":  date,
                "ref_no":  refno,
                "doc_no":  docno,
                "adjustment_table":  exampleArray,
            }
            fetch(global.url + "stockAdjust", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                if(responseJson.error == true) {
                    alert(responseJson.data)
                }
                else{
                    alert(responseJson.message)
                    window.location.reload()
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }

    const onBulkupload = () => {
        setBulkloader(true)
        const formData = new FormData();
        formData.append('upload_file', bulkfile);
        formData.append('user_id', userId);
        formData.append('branch_id', userbranchid);
        formData.append('company_id', usercompanyid);
        
        fetch(global.url + "excelImportCountedStock", {
          method: "POST",
          body: formData,
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            if(responseJson.error == false) {
              setBulkloader(false)
              alert(responseJson.message)
              window.location.reload()
            }
            else{
              setBulkloader(false)
              alert(responseJson.message)
            }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const onBulksubmit = () => {
        setBulkloader(true)
        const formData = new FormData();
        formData.append('upload_file', bulkfile);
        formData.append('user_id', userId);
        formData.append('branch_id', userbranchid);
        formData.append('company_id', usercompanyid);
        
        fetch(global.url + "stockdummyuploading", {
          method: "POST",
          body: formData,
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            if(responseJson.error == false) {
              setBulkloader(false)
              viewstockdummyuploading()
              setPreview(true)
            }
            else{
              setBulkloader(false)
              alert(responseJson.message)
            }
          })
          .catch((error)=>{
              console.log(error)
          })
      }
     

      const viewstockdummyuploading = () => {
        var data = {
            branch_id : userbranchid,
            company_id : usercompanyid
        }
        fetch(global.url + "viewstockdummyuploading", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
            })
            .then((response)=> response.json())
         .then((responseJson)=>{
          if(responseJson.error == false) {
            setBulkpreview(responseJson.data)
          }
         })
         .catch((error)=>{
             console.log(error)
           })   
          
      }

      const getStockDetails = (id) => {
        setStockhide(true)
        var data = {
            "company_id":  usercompanyid,
            "branch_id":  userbranchid,
            "doc_no":  docno
          }
          fetch(global.url + "stockadjustmentdetails", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson) => {
        //  console.log("respo find", responseJson)
              if(responseJson.error == false){
                setRefno(responseJson.data.reference_no)
                setDate(responseJson.data.date)
                setFormtype(responseJson.data.formtype)
                setUsername(responseJson.data.user_name)
              if(responseJson.data.details){
                setTableRow(responseJson.data.details)
            }
        }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Stock Adjustment</p>
               
               <Row>
                    <Col xs={12} >
                            <label style={{width: '100%'}}>Select File</label>
                            <input type="file" onChange={(e) => changeBulkfile(e)}  style={{marginTop: 10}}/>
                            {bulkloader ? 
                                <BtnLoader />:
                                <Button onClick={() => onBulksubmit()}  style={{float: 'right'}} variant="contained">Submit</Button>
                            }
                    </Col>
                </Row>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={docno} label="Doc No." onChange={(e) => setDocno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                            {formtype == 2 ? <p style={{color: 'red', fontSize: 13}}>Stock Adjustment</p> : formtype == 3 ? <p style={{color: 'red', fontSize: 13}}>Damage Entry</p> : null} 
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <Button onClick={getStockDetails} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={refno} label="Ref No." onChange={(e) => setRefno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        size="small"
                                        value={date}
                                        minDate={new Date(begindate)}
                                        onChange={(newValue) => {
                                        setDate(newValue);
                                        }}
                                        label="Doc Date"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                    </Row>
                    {stockhide == false ? null : 
                    <Row>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={username} label="User" disabled id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>}
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>SlNo</th>
                                <th>Code</th>
                                <th>color-size</th>
                                <th>Product name</th>
                                <th>Qty</th>
                                <th>Unit</th>
                                <th>Price</th>
                                {/* <th>Calc Price</th> */}
                                {/* <th>Discount(%)</th> */}
                                <th>Amount</th>
                                <th>Close</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((item, index) => {
                                return (
                                    <tr>
                                        <td >{index+1}</td>
                                            <td>
                                            {
                                                item.sales_isedit == 1 ?
                                                <input autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                            }
                                        
                                            {hasResults && selectedIndex == index && (
                                                <div className="autocomplete">
                                                    <ul ref={resultsRef} className="people scrolling-div">
                                                        {productlist.map(item => {
                                                            return (
                                                                item.product_fetchiscombo == 0 ? 
                                                                item.products.map(result => {
                                                                    return (
                                                                    <li key={item.url}>
                                                                        <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                            index, 
                                                                            item.products_id,
                                                                            item.product_refcode,
                                                                            item.product_name,
                                                                            item.products,
                                                                            0
                                                                            )}>
                                                                        {item.product_refcode} - { item.product_name }
                                                                        </a>
                                                                    </li>
                                                                )
                                                                }) : 
                                                                <li key={item.url}>
                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                        index, 
                                                                        item.products_id,
                                                                        item.product_refcode,
                                                                        item.product_name,
                                                                        item.pricelevel_name,
                                                                        "1",
                                                                        item.unit_name,
                                                                        item.product_comboprice,
                                                                        item.product_comboprice,
                                                                        0,
                                                                        item.product_comboprice,
                                                                        item.pricelevel_percentage,
                                                                        item.product_combostock,
                                                                        1
                                                                        )}>
                                                                    {item.product_refcode} - { item.product_name }
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                            </td>
                                            {stockhide ? 
                                            <td>
                                                {item.color} - {item.size}
                                            </td> :
                                            <td>
                                                <FormControl sx={{width: '100%' }}>
                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={item.sa_productbatch_fk}
                                                            label="Age"
                                                            onChange={colorChange}
                                                            size="small"
                                                        >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {item.colorsize.map((row, index) => {
                                                            return (
                                                            <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                        )})}
                                                        </Select>
                                                </FormControl>
                                        </td>}
                                        <td style={{fontSize: 10}}>

                                            {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                <p> {item.productname} - {item.color} - {item.size} </p> :
                                                null
                                                }
                                        </td>
                                        <td>
                                            {item.sales_iscombo == 0 ||  item.sales_iscombo == null   ?
                                                <div>  
                                                    {item.sales_isedit == 1 ?
                                                    <input min="1" disabled  autocomplete="off" type="number" name="query" value={item.sa_quantity} onChange={(event) => qtyChange(event, index)} />: 
                                                    <input min="1"  autocomplete="off" type="number" name="query" value={item.sa_quantity} onChange={(event) => qtyChange(event, index)} />
                                                    }
                                                    {   
                                                      stockhide == true ? null:  <label style={{fontSize: 12, fontWeight: 'bold', color: 'red'}}>stock: {item.pb_stock}</label>
                                                    }
                                                </div>: 
                                                null
                                            }
                                        </td>
                                        <td>{item.unit}</td>
                                        <td>{item.price}</td>
                                        {/* <td>{item.calcprice}</td> */}
                                        
                                        {/* <td>
                                        {item.sales_isedit == 1 ?
                                        <input autocomplete="off" disabled  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                        :<input autocomplete="off"  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                        }
                                        </td> */}
                                        <td>{item.sd_total_amount}</td>
                                        <td>
                                        {item.sales_isedit == 1 ? null: <Button onClick={() => deleteRow(index, item.amount)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}</td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </Table>
                    {stockhide == true ? null : 
                    <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>}
                </Col>
            </Row>
            <div style={{marginTop: 30}}>
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <TextareaAutosize 
                            onChange={(e) => setNarration(e.target.value)} 
                            value={narration}
                            minRows={3}
                            placeholder="Narration"
                            style={{background: 'transparent', width: '100%'}}
                        />
                    </Col>
                </Row>
                
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                        <ul className="list-inline">
                            { btnloader ? 
                                <Loader
                                    type="Oval"
                                    color="#38B0DE"
                                    height={30}
                                    width={30}
                                    style={{float: 'left'}}
                                />:
                            <li><Button onClick={() => onSubmit("addstock")} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Add Stock</Button></li>}
                             {/* <li><Button onClick={getStockDetails} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li> */}
                            {/* <li><Button onClick={() => onSubmit("minusstock")} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Minus Stock</Button></li> */}
                            {/*
                            <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                            <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                            <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li> */}
                        </ul>
                    </Col>
                </Row>
            </div>
          </Paper> 

          <Modal
              size="xl"
              show={preview}
              onHide={() => setPreview(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Preview
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Row>
              <Col xs={12}>
                 
                  <Table responsive id="table-to-xls" bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>Ref Code</th>
                                <th>BarCode</th>
                                <th>BatchCode</th>
                                <th>Product Name</th>
                                <th>Color</th>
                                <th>Size</th>
                                <th>Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                        {bulkpreview.map(item => {
                            return (
                              
                              <tr style={item.d_duplicate == 1 ? {backgroundColor: 'red'} : null}>
                                <td>
                                  {item.refcode}
                                </td>
                                <td>
                                  {item.barcode}
                                </td>
                                <td>
                                  {item.batchcode}
                                </td>
                                <td>
                                  {item.productname}
                                </td>
                                <td>
                                  {item.color}
                                </td>
                                <td>
                                {item.size}
                                </td>
                                <td>
                                  {item.stock}
                                </td>
                              </tr>
                            )
                            })}
                        </tbody>
                    </Table>
                
                  </Col>
                
                 
                    <Col xs={12}>
                    {bulkloader ? 
                         <BtnLoader />:
                         <Button onClick={() => onBulkupload()}  style={{float: 'right'}} variant="contained">Upload</Button>
                       }
                    
                   </Col>
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default StockAdjustment;