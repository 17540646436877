import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col,Modal,Button  } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useHistory, useParams } from "react-router-dom";

function Userlog(props) {
    let history = useHistory();
    // let { id } = useParams();
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [source, setSource] = useState([])
    const [modal, setModal] = React.useState(false);
    const [date, setDate] = React.useState(new Date);

    const [frominvoice, setFrominvoice] = useState("0")
    const [toinvoice, setToinvoice] = useState("0")

    const [executiveid, setExecutiveid] = useState("")
    const [executiveList, setExecutivelist] = useState([])


    const [mobile, setMobile] = useState("")
    const [invoice, setInvoice] = useState("")

    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])

    const [billDetails, setBilldetails] = useState([])

    const productlist = [{id: 1},{id: 2},{id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}]
    const [blocked, setBlocked] = React.useState(0);
    const [array, setArray] = React.useState([])

    const [searchinvoice, setSearchsoinvoice] = useState("")

    const userChange = (event) => {
        setSalesmanid(event.target.value)
        getData(event.target.value)
    }

    useEffect(() => {
        getData()
    }, [])

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }

      useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
    }, [])
      
    useEffect(() => {
        document.removeEventListener('scroll', trackScrolling);
    }, [])
      
      
     const trackScrolling = () => {
        const wrappedElement = document.getElementById('header');
        if (isBottom(wrappedElement)) {
          document.removeEventListener('scroll', trackScrolling);
        }
      };

    const getData = () => {
        fetch(global.url + "userLogs", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {
            // console.log("userLogs", responseJson)
          if(responseJson.error == false){
            setSource(responseJson.data)
          }
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const getdata2 = (search)=> {
        setSearchsoinvoice(search)
        var data = {
            "logid": search
          }
          fetch(global.url + "userLogs", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                // console.log("userLogs", responseJson)
                if(responseJson.error == false){
                    setSource(responseJson.data)
                  }
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>   
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>User Logs</p> 
            </div>
            <Row>
                <Col md={3}>
                    <FormControl sx={{width: '100%', mt: 2 }}>
                        <TextField onChange={(e) => getdata2(e.target.value)} value={searchinvoice} placeholder="Log Id" id="outlined-basic" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                    <Table id="header table-to-xls" responsive  bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Operation</th>
                                <th>Date&Time</th>
                            </tr>
                        </thead>
                        <tbody>
                        {source.map(result => {
                            return (
                            <tr>
                                <td>{result.logs_username}</td>
                                <td>{result.logs_operation}</td>
                                <td>{result.logs_time}</td>                               
                            </tr>
                            )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
           
          </Paper> 
      </div>
    )
}
export default Userlog;