import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'Tax name', minWidth: 50,span: false },
    { id: '1', label: 'AG code', minWidth: 50,span: false },
    { id: '3', label: 'Blocked', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
    const [btnloader, setBtnloader] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [taxid, setTaxid] = React.useState("");
    const [blocked, setBlocked] = React.useState(0);
    const [taxName, setTaxname] = React.useState("");
    const [userId, setUserid] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    
    
    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const checkChange = (e) => {
      let isChecked = e.target.checked;
      if(isChecked == true) {
        setBlocked(1)
      }
      else {
        setBlocked(0)
      }
      // do whatever you want with isChecked value
    }

    useEffect (() => {
      getData()
    },[]) 

    const getData = () => {
      setLoader(true)
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company
      }
      fetch(global.url + "viewTaxmaster", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((response)=> response.json())
      .then((responseJson)=>{
        setLoader(false)
        if(responseJson.status == "false"){
          setSource(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const deleteClick = (id) => {
      var data = {
        "taxmaster_id": id
      }
      fetch(global.url + "deleteTaxmaster", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.message)
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
    }
    const editClick = (
      taxid, 
      taxName,
      blocked,
      
      ) => {
        setModal(true)
        setTaxid(taxid)
        setTaxname(taxName)
        setBlocked(blocked)
    }

    const onAdd = () => {
      setModal(true)
      setTaxid("")
      setTaxname("")
      setBlocked(0)
  }


    const onSave = () => {
      setBtnloader(true)
      var data = {
        "taxmaster_id": taxid,
        "taxmaster_name": taxName,
        "taxmaster_blocked": blocked,
        "taxmaster_userid": userId,
        "taxmaster_branchid" : userbranchid,
        "taxmaster_companyid" : usercompanyid
    }
      fetch(global.url + "updateTaxmaster", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
            setBtnloader(false)
          } else {
            alert(responseJson.message)
            setBtnloader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Tax Master listing</p>
               {
                      usertype == "superadmin" ? 
                      null : 
               <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.taxmaster_name}
                              </TableCell>
                              <TableCell>
                                  {row.taxmaster_agcode}
                              </TableCell>
                              <TableCell >
                              {row.taxmaster_blocked == "0" ? "No" : "Yes"}
                              </TableCell>
                              <TableCell >
                                {
                                    usertype == "superadmin" ? 
                                    null : 
                                    <a href="javascript:void(0)" onClick={() => editClick(row.taxmaster_id,
                                      row.taxmaster_name,
                                      row.taxmaster_blocked)}>
                                      <FaIcons.FaEdit fontSize={20} color="blue" />
                               
                                    </a>
                                }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.taxmaster_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Tax Master Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setTaxname(e.target.value)} value={taxName} id="outlined-basic" label="Tax name" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                              <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;