import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl, { getFormControlUtilityClasses } from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import { Route } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'Area name', minWidth: 50,span: false },
    { id: '1', label: 'AG code', minWidth: 50,span: false },
    { id: '3', label: 'Route', minWidth: 50,span: false },
    { id: '5', label: 'Blocked', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const rows = [
    {
      company: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      address: 'Lorem Ipsum',
      gst: 'Lorem Ipsum',
      mobile: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      address: 'Lorem Ipsum',
      gst: 'Lorem Ipsum',
      mobile: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      address: 'Lorem Ipsum',
      gst: 'Lorem Ipsum',
      mobile: 'Lorem Ipsum',
    },
  ];

function Area(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [btnloader, setBtnloader] = React.useState(false);
  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');
  const [source, setSource] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [areaId, setAreaid] = React.useState(0);
  const [areaName, setAreaname] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);
  const [route, setRoute] = React.useState("");
  const [routeList, setRoutelist] = React.useState([]);

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
  }

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    setLoader(true)
    fetch(global.url + "viewArea", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
        if(responseJson.status == "false"){
          setLoader(false)
          setSource(responseJson.data)
        }  
        else{
          setLoader(false)
        }      
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const onAdd = () => {
      setModal(true)
      setAreaid("")
      setAreaname("")
      setBlocked(0)
      setRoute("")
      getRoute()
    }

    const getRoute = () => {
      fetch(global.url + "viewRoute", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
            
          },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          if(responseJson.status == "false"){
            setRoutelist(responseJson.data)
          }else{
            setRoutelist([])
          }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const routeChange = (event) => {
      setRoute(event.target.value);
    };

    const editClick = (
      areaid, 
      areaname,
      blocked,
      route
      ) => {
        setModal(true)
        setAreaid(areaid)
        setAreaname(areaname)
        setBlocked(blocked)
        setRoute(route)
        getRoute()
    }

    const onSave = () => {
      console.log("areaId", areaId)
      console.log("areaName", areaName)
      console.log("blocked", blocked)
      console.log("route", route)
      setBtnloader(true)
      var data = {
        "area_id": areaId,
        "area_name": areaName,
        "area_route": route,
        "area_blocked": blocked,
        "area_branchid" : userbranchid,
        "area_companyid" : usercompanyid,
        "user_id": userId
      }
      console.log("area form data", data)
      fetch(global.url + "updateArea", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("responsejson", responseJson);
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
            setBtnloader(false)
          } else {
            alert(responseJson.message)
            setBtnloader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const deleteClick = (id) =>  {
      var data = {
        "area_id": id,
        "user_id": userId
      }
      fetch(global.url + "deleteArea", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.message)
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Area List</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.area_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.area_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.route_name}
                              </TableCell>
                              <TableCell >
                                  {row.area_blocked == "0" ? "No" : "Yes"}
                              </TableCell>
                              <TableCell >
                                    <a href="javascript:void(0)" onClick={() => 
                                    editClick(row.area_id, 
                                      row.area_name,
                                      row.area_blocked, 
                                      row.area_route,
                                    )}>
                                      <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.area_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Area Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>

                  <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setAreaname(e.target.value)} value={areaName} id="outlined-basic" label="Area Name" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>
                
                    <Col xs={12} >
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Route</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={route}
                                  label="Age"
                                  onChange={routeChange}
                                  size="small"
                                  
                                >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {routeList.map((item, index) => (
                                    <MenuItem value={item.route_id}>{item.route_name}</MenuItem>
                                  ))}
                              </Select>
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                              <FormControlLabel control={
                              <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                              } label="Blocked" />
                          </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Area;