import './Invoice.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import { Container, Row, Col, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

function Invoice(){
    const { id } = useParams();
    const [date, setDate] = React.useState("")
    const [voucherno, setVoucherno] = React.useState("")
    const [details, setDetails] = React.useState([])
    const [salestotal, setTotal] = React.useState("")

    useEffect(() => {
        var data = {
            "sales_id" : id,
        }
        
        fetch(global.url + "salesPrint", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setDetails(responseJson.data.details)
            setTotal(responseJson.data.sales_total)
            setVoucherno(responseJson.data.sales_invoice)
            setDate(responseJson.data.sales_date)
        })
        .catch((error)=>{
            console.log(error)
        })
    }, []);


    return(
        <div>
            <Container fluid>
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        <div className="invoice-box">
                            <div className="invoice-inside">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>Fetch</p>
                                <Row>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>المورد</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Supplier: </p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>رقم القسيمة</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Voucher No: {voucherno}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع المورد / رقم الطلب</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Supplier's Ref/Order No: {voucherno}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Date: {date}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Mode/Term of Payment: Cash</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Other Reference(s): Cash</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>من خلال إيفاد</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Despatch through: Qatar</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>شروط التوصيل</p>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Terms of Delivery: Qatar</p>
                                </Col>
                                </Row>    
                                <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div>
                                <div>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>SLNo.</p>
                                            </th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Product</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Qty</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Price</p></th>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Total</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                details.map((item, index) => {
                                                    return(
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>{item.productname}</td>
                                                            <td>{item.sd_qty}</td>
                                                            <td>QAR {item.price}</td>
                                                            <td>QAR {item.sd_total_amount}</td>
                                                        </tr>
                                                    )
                                               
                                                })
                                            }
                                            
                                            <tr>
                                                <td colSpan={3}></td>
                                                <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                <p style={{fontSize: 14, fontWeight: 'bold'}}>Sub Total</p></th>
                                                <td>QAR {salestotal}</td>
                                            </tr>
                                            <tr>
                                            <td colSpan={3}></td>
                                            <th><p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 14, fontWeight: 'bold'}}>Total</p></th>
                                            <td>QAR {salestotal}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div style={{marginTop: 30}}>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Fetch</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Doha, Qatar</p>
                                    {/* <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>procurement@alawadi.qa</p> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Invoice;