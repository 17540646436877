import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size', minWidth: 50,span: false },  
    { id: '11', label: 'By', minWidth: 50,span: false },  
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
  ];

  const source = [
      {},{}
  ]

  function DeliveryBoy(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")
    const [source, setSource] = React.useState([]);
    const [total, setTotal] = React.useState()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dateFrom, setDatefrom] = React.useState(new Date);
    const [dateTo, setDateTo] = React.useState(new Date);
    const [starttime, setStarttime] = React.useState(new Date);
    const [enddtime, setEndtime] = React.useState(new Date);
    const [loader, setLoader] = React.useState(false);
    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])
    const [combo, setCombo] = useState([])
    const [comboview, setComboview] = useState(false)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    useEffect(() => {
        getData()
    }, [])
    const userChange = (event) => {
        setSalesmanid(event.target.value)   
    }
    const startChange = (newValue) => {
        setStarttime(newValue);
      };
    
      const endChange = (newValue) => {
        setEndtime(newValue);
      };
 

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log('user', userdata)
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        setLoader(true)
        var data = {
            "type" :  userdata.user_type,
            "companyid" : company,
            "branchid" : branch,
            "deliver_boy" : userdata.user_id,
            "orderfrom" : dateFrom.toISOString().split('T')[0],
            "orderto" : dateTo.toISOString().split('T')[0],
            "salesorder_fromtime": new Date(starttime.toISOString()).toLocaleTimeString('en',
                              { timeStyle: 'short', hour12: false, }),
            "salesorder_totime": new Date(enddtime.toISOString()).toLocaleTimeString('en',
                            { timeStyle: 'short', hour12: false, })
        }

        console.log('data', data)
        fetch(global.url + "DeliveryBoyReportUpdate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response ledger", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                  console.log('respon', responseJson.data.notcombo)
                setSource(responseJson.data)
                setComboview(true)
              }else{
                setSource([])
                setComboview(false)
                setCombo([])
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const filter = () => {
        getData();
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{margin: 20,}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Delivery Boy Report</p>
                    <Row>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="from"
                                    value={dateFrom}
                                    onChange={(newValue) => {
                                        setDatefrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={dateTo}
                                    onChange={(newValue) => {
                                        setDateTo(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                  label="Start Time Shift"
                                  value={starttime}
                                  onChange={startChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                          </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="End Time Shift"
                                value={enddtime}
                                onChange={endChange}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <Button onClick={filter}  style={{width: '50%', height: 45}} variant="contained">Filter</Button>
                        </Col>
                    </Row>
                </div>
                <div style={{margin: 20,}}>
                {loader ? <Loader /> :
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                        colSpan={column.span == true ? 2: 1}
                                    >
                                        {column.label} 
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source.map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {index+1}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.date}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.location}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.building}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.street}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.zone}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_mobile}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.salesorder_total}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.notcombo.map((i, k) => {
                                            return(
                                                <ul >
                                                    <li>
                                                    {i.product_refcode}-[{i.color_name} - {i.size_name}]
                                                    </li>
                                                    
                                                </ul>
                                            )
                                        })}
                                        {row.combo.map((i, k) => {
                                            return(
                                                <ul>
                                                    <li>
                                                    {i.product_refcode} - 
                                                    [
                                                    {
                                                        i.comboproducts.map((item, index) => {
                                                            return(
                                                                <span>
                                                                    {item[0].color_name} - {item[0].size_name}, 
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    ]
                                                    </li>
                                                    
                                                </ul>
                                            )
                                        })}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.by}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.remarks}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                       }
                </div>
            </Paper> 
        </div>
      );
  }

  export default DeliveryBoy;
