
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";
import TimePicker from '@mui/lab/TimePicker';


const columns = [
    { id: '2', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Log id', minWidth: 50,span: false },
    { id: '3', label: 'Invoice No.', minWidth: 50,span: false },
    { id: '3', label: 'Order Type', minWidth: 50,span: false },
    { id: '4', label: 'Customer', minWidth: 50,span: false },
    { id: '5', label: 'Mobile', minWidth: 50,span: false },
    { id: '5', label: 'Subtotal', minWidth: 50,span: false },
    { id: '5', label: 'Delivery Charge', minWidth: 50,span: false },
    { id: '5', label: 'Discount', minWidth: 50,span: false },
    { id: '7', label: 'Net Total', minWidth: 50,span: false },
    { id: '7', label: 'Product Hold', minWidth: 50,span: false },
    { id: '8', label: 'Action', minWidth: 100,span: true },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Salesorderreport(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [input, setInput] = useState(0);
    const [filt, setFilt] = useState(false)

    const [value, setValue] = React.useState(new Date);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");
    const [modal, setModal] = React.useState(false);

    const [billDetails, setBillsetails] = React.useState([]);
    const [starttime, setStarttime] = React.useState(new Date);
    const [enddtime, setEndtime] = React.useState(new Date);
    const [endvalue, setEndvalue] = React.useState(new Date);
    const [frominvoice, setFrominvoice] = useState("")
    const [toinvoice, setToinvoice] = useState("")
    const [mobile, setMobile] = useState("")

    const [totaldeli, setDeli] = useState("0")
    const [totdisc, setDisc] = useState("0")
    const [totnet, setTotnet] = useState("0")
    const [totsub, setTotsub] = useState("0")


  const startChange = (newValue) => {
    setStarttime(newValue);
  };

  const endChange = (newValue) => {
    setEndtime(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
      getData()
      salestotal()
  }, [input])

  // useEffect(() => {
  //   getMobile()
  // }, [mobile])

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "page" : input*10,
        "limit": 10
          
      }
    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
          //  console.log("responseJson", responseJson)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
      }
    })
  }

  const getMobile = (e) => {
    setLoader(true)
    setMobile(e.target.value)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "salesorder_mobile": e.target.value,
          
      }
    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      if(responseJson.status == "false"){
        // console.log("responseJson", responseJson)
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
    })
  }

  const decrement = () => {
    setInput(prevCount => prevCount - 1);
    
  }

 const increment = () => {
    setInput(prevCount => prevCount + 1);
  
  }

  const viewBillclick = (id) => {
    const win = window.open('/saleorderinvoice/' + id, "_blank")
    win.focus();
  }

  const cancelClick = (id) => {
    if(window.confirm('Are you Want to Cancel this order?')){
        var userdata = JSON.parse(localStorage.getItem("user"))
        var data = {
        "salesorderid":  id,
        "user_id":  userdata.user_id,
        }
        fetch(global.url + "salesOrderCancel", {
            method: 'POST',
            headers: {
            'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
        if(responseJson.error == false){
            getData()
            alert(responseJson.message)
        }
        })
    }
  }

  const holdClick = (id) => {
    const win = window.open('/saleorderinvoice/' + id, "_blank")
    win.focus();
  }

  const returnClick = (id , billno) => {
    if (window.confirm('Are you sure you want to return the bill ' + billno + " ?")) {
      var data = {
        "sales_id":  id,
        "branchid": userbranchid,
        "companyid": usercompanyid
      }
      fetch(global.url + "salesReturn", {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.error == false){
          getData()
          alert(responseJson.message)
        }
      })
    } 
  }


  const onfilterClick = () => {
    setFilt(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "salesorder_fromdate": value.toISOString().split('T')[0],
        // "salesorder_fromtime": new Date(starttime.toISOString()).toLocaleTimeString('en',
        // { timeStyle: 'short', hour12: false, timeZone: 'UTC' }),
        "salesorder_todate": endvalue.toISOString().split('T')[0],
        "excelexport" : true
        // "salesorder_totime": new Date(enddtime.toISOString()).toLocaleTimeString('en',
        // { timeStyle: 'short', hour12: false, timeZone: 'UTC' })
      }
    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("date filter",responseJson )
      setLoader(false)
      if(responseJson.status == "false"){
     
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
    })
  }

  const onfilterClick2 = () => {
    setFilt(true)
    setSource([])
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "invoice_number_from": frominvoice,
        "invoice_number_to": toinvoice,
        "excelexport" : true
      }
      
    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
    })
  }

  const salestotal = () => {
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company,
      }
      
    fetch(global.url + "salesordertotal", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("res", responseJson)
      if(responseJson.error == false){
        setDeli(responseJson.data.deliverycharge)
        setDisc(responseJson.data.discount)
        setTotnet(responseJson.data.nettotal)
        setTotsub(responseJson.data.subtotal)
      }
    })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, marginBottom: 0, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order Report</p>
            </div>
            <div style={{margin: 20, marginTop: 0}}>
                <Row>
                    <Col xs={12} md={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From"
                                value={value}
                                onChange={(newValue) => {
                                setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To"
                                value={endvalue}
                                onChange={(newValue) => {
                                setEndvalue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    {/* <Col sm={2} style={{marginTop: 20}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                  label="Start Shift"
                                  value={starttime}
                                  onChange={startChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                          </LocalizationProvider>
                            
                      </Col>
                      <Col sm={2}  style={{marginTop: 20}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="End Shift"
                                value={enddtime}
                                onChange={endChange}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                      </Col> */}
                    
                    <Col xs={12} md={2} style={{marginTop: 20}}>
                        <Button style={{width: '50%', height: 40}} onClick={() => onfilterClick()} variant="primary">Filter</Button>
                    </Col>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setFrominvoice(e.target.value)} value={frominvoice} placeholder='Invoice From' id="outlined-basic" variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>

                      <Col xs={12} md={2} style={{marginTop: 20}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setToinvoice(e.target.value)} value={toinvoice} id="outlined-basic" placeholder='Invoice To' variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>
                    
                    <Col xs={12} md={2} style={{marginTop: 20}}>
                        <Button style={{width: '50%', height: 40}} variant="primary" onClick={() => onfilterClick2()}>Filter</Button>
                    </Col>
                    <Col xs={12} md={3} style={{marginTop: 20}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => getMobile(e)} value={mobile} id="outlined-basic" placeholder='Mobile' variant="outlined"  size="small"/>
                          </FormControl>
                    </Col>
                </Row>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} >
                              
                              <TableCell >
                                  {row.salesorder_date}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_id}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_invoice}
                              </TableCell>
                              <TableCell>
                                  {row.ordertype_name}
                              </TableCell>
                              <TableCell>
                                  {row.customer_name}
                              </TableCell>
                              <TableCell >
                                  {row.customer_mobile}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_total}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_other_charges}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_discount}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_replacereturn == 1 || row.salesorder_replacereturn == 2 ? row.salesorder_balancereceivable : row.salesorder_cashledger}
                              </TableCell>
                              
                              {/* <TableCell>
                                  <Button onClick={() => returnClick(row.sales_id, row.sales_invoice)}  variant="primary" style={{padding: 5, fontSize: 10}}>Sales Return</Button>
                              </TableCell> */}
                              {/* {row.salesorder_iscancel == 0 ?
                              <TableCell>
                                  <Button onClick={() => cancelClick(row.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>Order Cancel</Button>
                              </TableCell>: null} */}
                              {/* {row.salesorder_isdeleted == 0 ? */}
                              <TableCell>
                                {row.phld_iscancel == null || row.phld_isused == null ?
                              <a href={'/transaction/ProductHold/' + row.salesorder_id + '/' + "hold"} >
                                  <Button variant="primary" style={{padding: 5, fontSize: 10, width: '100%'}}>Product Hold</Button>
                                </a>:
                                row.phld_iscancel == 0 || row.phld_isused == 0 ?
                                <a href={'/transaction/ProductHold/' + row.salesorder_invoice + '/' + "edit"} >
                                  <Button variant="primary" style={{padding: 5, fontSize: 10, width: '100%'}}>Edit</Button>
                                </a> : null}
                              </TableCell>
                              <TableCell>
                                  <Button onClick={() => cancelClick(row.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>Order Cancel</Button>
                              </TableCell>
                              <TableCell>
                                  <Button onClick={() => viewBillclick(row.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button>
                              </TableCell>
                              <TableCell >
                                  <a href={'/transaction/Salesorder/' + row.salesorder_id} >
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                      <TableRow>
                          <TableCell colSpan={6} style={{fontWeight: 'bold'}}>
                              Total
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totsub}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                              {totaldeli}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totdisc}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totnet}
                          </TableCell>
                      </TableRow>
                </TableBody>
              </Table>
              }
            </TableContainer>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>discount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.code}</td>
                                <td>{item.product}</td>
                                <td>{item.qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.discount}</td>
                                <td>{item.amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                </Modal.Body>
            </Modal>
            {/* <Row>
              <Col xs={12}>
                <div style={{float: 'right', marginRight: 130, marginTop: 20}}>
                    <p style={{fontWeight: "bold"}}>Total: </p>
                    <p  style={{fontWeight: "bold"}}> 0</p>
                  </div>
              </Col>
            </Row> */}
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper> 
          {filt == true ? null :
          <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
          }
      </div>
    )
}
export default Salesorderreport;