
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import { MultiSelect } from "react-multi-select-component";
import TimePicker from '@mui/lab/TimePicker';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'User Name', minWidth: 50,span: false },
    { id: '2', label: 'User Email', minWidth: 50,span: false },
    { id: '3', label: 'User Type', minWidth: 50,span: false },
    { id: '4', label: 'Branch', minWidth: 50,span: false },
    { id: '5', label: 'Company', minWidth: 50,span: false },
    { id: '6', label: '', minWidth: 50,span: false },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 100,
        span: true
      },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const rows = [
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];


  const options = [
    { label: "Grapes", value: "grapes", },
    { label: "Mango", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
  ];

function User(props) {
    const [btnloader, setBtnloader] = React.useState(false);
    const [btnloader2, setBtnloader2] = React.useState(false);
    const [btnloader3, setBtnloader3] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbrachid, setUserbranchid] = useState("")
    const [loginusertype, setLoginusertype] = useState("")
    const [loginuserid, setLoginuserid] = useState("")


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [modal, setModal] = useState(false);
    const [loader, setLoader] = React.useState(false);
    const [source, setSource] = React.useState([]);
    const [companyid, setCompanyid] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [useremail, setUseremail] = React.useState("");
    const [userpassword, setUserpassword] = React.useState("");
    const [usertype, setUsertype] = React.useState("");
    const [branchid, setBranchid] = React.useState("");
    const [branchlist, setBranchlist] = React.useState([]);
    const [counterlist, setCounterlist] = React.useState([]);
    const [userid, setUserid] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [companylist, setCompanylist] = React.useState([]);

    const [starttime, setStarttime] = React.useState(new Date);
    const [enddtime, setEndtime] = React.useState(new Date);

    const [passwordpopup, setPasswordpopup] = React.useState(false);


    const [editMode, setEditmode] = React.useState(false);

    const [selected, setSelected] = useState([]);
    const [counter, setCounter] = useState("");

    const [cpassword, setCpassword] = React.useState("");

    

  const typeChange = (event) => {
    setUsertype(event.target.value);
  };

  const companyChange =(event) => {
    setCompany(event.target.value)
    getBranch(event.target.value)
  }

  const branchChange = (event) => {
    setBranchid(event.target.value);
  };

  const counterChange = (event) => {
    setCounter(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect (() => {
      getData()
      getCompany()
      getCounter()
  }, [])

  const getCounter = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setLoginusertype(userdata.user_type)
        setLoginuserid(userdata.user_id)
       
        var data = {
          "companyid": company,
        }
        fetch(global.url + "dropdownCashCounter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((responseJson) => {
          
            if(responseJson.error == false){
                setCounterlist(responseJson.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
  }

  

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setLoginusertype(userdata.user_type)

    setLoader(true)
    var data = {
      "userid":  userdata.user_id,
      "companyid": company,
      "branchid": branch,
      "type": userdata.user_type,
    }
    fetch(global.url + "viewuser", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        setLoader(false)
        if(responseJson.status == 'false'){
            setSource(responseJson.data)
        }
    })
    .catch((error) => {
        console.log(error)
    })
  }

  const getBranch = (id) => {
    var data = {
      "company_id":  id,
    }
      fetch(global.url + "multipleBranch", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.error == false){
            setBranchlist(responseJson.data)
        }
        else{
          setBranchlist([])
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getCompany = () => {
        fetch(global.url + "viewAllCompany", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.error == false){
            setCompanylist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

  const onAdd = () => {
      setModal(true)
      setUserid(0)
      setUsername('')
      setUseremail('')
      setUserpassword('')
      setUsertype('')
      setBranchid('')
      setCompany("")
      setEditmode(false)
      setSelected([])
      getCompany()
  }

  const editClick = (
      userid,
      username,
      useremail,
      userpassword,
      usertype,
      usercompany,
      userbranch
  ) => {
      setModal(true)
      setEditmode(true)
      setUserid(userid)
      setUsername(username)
      setUseremail(useremail)
      setUserpassword("")
      setUsertype(usertype)
      setCompany(usercompany)
      getSelectedbranch(userid)
      // setSelected(userbranch)
      getBranch(usercompany)
  }

    const getSelectedbranch =(id) => {
          var data = {
            "id": id,
            "type": "user"
          }
          fetch(global.url + "multipleBranchSelect", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response) => response.json())
          .then((responseJson) => {
            if(responseJson.error == false) {
              setSelected(responseJson.data)
            }
          })
          .catch((error) => {
              console.log(error)
          })
    }

    const onSave = () => {
      setBtnloader(true)
      var data = {
          "user_name": username,
          "user_email": useremail,
          "user_password": userpassword,
          "user_company_id": company,
          "user_branch_id" : selected,
          "user_type" : usertype,
          "user_id" : userid,
          "user_cashcounter_fk": counter,
          "user_start_time": starttime,
          "user_end_time": enddtime
        }
        fetch(global.url + "updateuser", {
            method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
        }).then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.status == "false"){
                getData()
                setModal(false)
                alert(responseJson.data)
                setBtnloader(false)
            }
            if(responseJson.status == "true"){
                alert(responseJson.data)
                setBtnloader(false)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const deleteClick = (id) => {
      var data = {
          "user_id": id,
        }
        fetch(global.url + "deleteuser", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((responseJson) => {
            alert(responseJson.data)
            getData()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const startChange = (newValue) => {
      setStarttime(newValue);
    };

    const endChange = (newValue) => {
      setEndtime(newValue);
    };

    const onPasswordsave = () => {
      var userid = JSON.parse(localStorage.getItem("user"))
        var data = {
          "userid": userid.user_id,
          "password": cpassword
        }
        fetch(global.url + "changePassword", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.error == false){
              alert(responseJson.message)
              setPasswordpopup(false)
            }
            else{
              alert("Please Type another Password")
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const onChange = () => {
      setCpassword("")
      setPasswordpopup(true)
    } 

    const activate = (id) => {
      setBtnloader3(true)
        var data = {
          "user_id": id,
          "value": 0
        }
        fetch(global.url + "Deliveryboystatus", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((responseJson) => {
            getData()
            setBtnloader3(false)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const deactivate = (id) => {
      setBtnloader2(true)
      var data = {
        "user_id": id,
        "value": 1
      }
      fetch(global.url + "Deliveryboystatus", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((responseJson) => {
          getData()
          setBtnloader2(false)
      })
      .catch((error) => {
          console.log(error)
      })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>User Management</p>
               {loginusertype != 1 ? 
               <Button onClick={onAdd} variant="primary">Add</Button>: 
               null
               }
               
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
                {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell>
                                  {row.user_name}
                              </TableCell>
                              <TableCell >
                                  {row.user_email}
                              </TableCell>
                              <TableCell >
                                  {row.user_type == 0 ? 'Admin' : 'User'}
                              </TableCell>
                              <TableCell >
                                  {row.branch_name}
                              </TableCell>
                              <TableCell >
                                  {row.company_name}
                              </TableCell>
                              <TableCell >
                                {row.user_deliveryboy_status == 0 ?
                                <div>
                                  {btnloader2 ? 
                                    <Loader2
                                      type="Oval"
                                      color="#38B0DE"
                                      height={30}
                                      width={30}
                                    
                                  />:
                                    <Button onClick={() => deactivate(row.user_id)} variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>Deactive</Button>}
                                </div>:
                                <div>
                                  {btnloader3 ? 
                                    <Loader2
                                      type="Oval"
                                      color="#38B0DE"
                                      height={30}
                                      width={30}
                                   
                                  />:
                                    <Button onClick={() => activate(row.user_id)} variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>Activate</Button>}
                                </div>} 
                              </TableCell>
                              {loginusertype != 1 ? 
                              <div>
                              <TableCell >
                                    <a href="javascript:void(0)" onClick={() => 
                                    editClick(row.user_id, 
                                        row.user_name,
                                        row.user_email,
                                        row.user_password,
                                        row.user_type,
                                        row.user_company_id,
                                        row.user_branch_id                                  
                                    )}>
                                      <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.user_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                              {row.user_id == loginuserid && loginusertype!= "superadmin"  ? 
                              <TableCell >
                                    <Button onClick={() => onChange()} variant="primary">Change Password</Button>
                              </TableCell> : null
                              }
                             </div>
                              : row.user_id == loginuserid ? 
                              <TableCell >
                                    <Button onClick={() => onChange()} variant="primary">Change Password</Button>
                              </TableCell> : null
                              }
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 User Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setUsername(e.target.value)} value={username} label="User Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setUseremail(e.target.value)} value={useremail} label="User Email" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    {editMode == false ?   
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setUserpassword(e.target.value)} value={userpassword} label="User Password" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col> : null
                    }
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={usertype}
                                        label="Branch"
                                        onChange={typeChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                      <MenuItem value={0}>Admin</MenuItem>
                                      <MenuItem value={1}>Sales Executive</MenuItem>
                                      <MenuItem value={2}>Frontend user</MenuItem>
                                      <MenuItem value={3}>Deliveryboy</MenuItem>
                                      <MenuItem value={4}>Hr</MenuItem>
                                      <MenuItem value={5}>Stock manager</MenuItem>
                                      <MenuItem value={6}>Accountant</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Company</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={company}
                                        label="Branch"
                                        onChange={companyChange}
                                        size="small"
                                    >
                                      <MenuItem value="">
                                          <em>None</em>
                                      </MenuItem>
                                        {companylist.map((item, index) => (
                                          <MenuItem value={item.company_id}>{item.company_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </FormControl>
                    </Col>
                    <Col xs={12} style={{marginTop: 20}}>
                    {/* <pre>{JSON.stringify(selected)}</pre> */}
                    <p>Branches</p>
                      <MultiSelect
                        options={branchlist}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                      />
                    </Col>

                    <Col xs={12} style={{marginTop: 20}}>

                    {/* <p>Counter</p>
                    <Col xs={12}>
                          <FormControl sx={{ mt: 4, width: '100%' }}>
                            <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={counter}
                                            label="Branch"
                                            onChange={counterChange}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {counterlist.map((item, index) => (
                                          <MenuItem value={item.cc_id}>{item.cc_name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </FormControl>
                      </Col> */}
                      {/* <Row style={{marginTop: 50}}>
                        <Col sm={6} >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="Start Shift"
                                    value={starttime}
                                    onChange={startChange}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                            </LocalizationProvider>
                            
                        </Col>
                        <Col sm={6} >
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="End Shift"
                                    value={enddtime}
                                    onChange={endChange}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                        </Col>
                      </Row> */}
                    </Col>
                    {/* <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField disabled value={companyid}  label="Company" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col> */}
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                      btnloader ? 
                      <Loader2
                          type="Oval"
                          color="#38B0DE"
                          height={30}
                          width={30}
                          style={{float: 'right'}}
                      />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="primary">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>

           <Modal
              size="sm"
              show={passwordpopup}
              onHide={() => setPasswordpopup(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Change Password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setCpassword(e.target.value)} value={cpassword} type="password" label="Password" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onPasswordsave} style={{float: 'right'}} variant="primary">Submit</Button>
                      <Button onClick={() => setPasswordpopup(false)} style={{marginRight: 40, float: 'right'}} variant="danger" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default User;