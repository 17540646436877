
import React, {Component, useEffect, useState, useRef} from 'react';
import { Row, Col, Modal, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MultiSelect } from "react-multi-select-component";
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function Fetchpage(props) {
    const inputRef = useRef();
    const resultsRef = useRef();
    const [modal, setModal] = useState(false);

    const [probtn, setProbtn] = useState(true);
    const [lookupproductlist, setLookupproductlist] = React.useState([]);
    const [combolist, setCombolist] = React.useState([]);


    useEffect(() => {
        if (props.hasResults) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown)
        }
      }, [props.hasResults]);

    function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      useEffect(() => {
        getCombo()
      } ,[])

      const getCombo = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
          "usertype": userdata.user_type,
          "companyid": company,
          "branchid" : branch
        }
        fetch(global.url + "ViewFetchCombo", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("ViewFetchCombo", responseJson)
              if(responseJson.error == false) {
                setCombolist(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const searchProduct = (text) => {
      
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
          "usertype": userdata.user_type,
          "companyid": company,
          "refcode": text,
          "branchid" : branch
        }
        fetch(global.url + "ViewFetchCombo", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.error == false) {
                setCombolist(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const lookupClick = () => {
        props.setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
          "usertype": userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewAllproductbatchforItemlookup", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("item look up", responseJson)
              setLookupproductlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const onfilter = (text, type) => {
      console.log("type")
      var company = JSON.parse(localStorage.getItem("company"))
      if(type == "intercode"){
          var data = {
              "type" : 'superadmin',
              "companyid" : company,
              "barcode" : text,
              "productname" : ""
          }
              }else{
          var data = {
              "type" : 'superadmin',
              "companyid" : company,
              "barcode" : "",
              "productname" : text
          }
      }
         
      console.log("console data", data)
          fetch(global.url + "viewAllproductbatchforItemlookup", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response combo", responseJson)
              if(responseJson.status == 'false'){
                  setLookupproductlist(responseJson.data)
              }else{
                  setLookupproductlist([])
                  
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      
  }

   
  return (
        <div>
            <Row>
                <Col sm={4} >
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="productcode"
                        value={props.campaigncode}
                        autoComplete="off" disabled id="outlined-basic" label="Online Campaign Code" variant="outlined"  size="small"/>
                    </FormControl>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Online Campaign Number</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.camnumber}
                                label="Age"
                                onChange={props.camnumChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.camnumberlist.map((item, index) => (
                                  <MenuItem value={item.cd_id}>{item.cd_number}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="comboprice"
                          value={props.camprice}
                          onChange={(e) => props.setCamprice(e.target.value)}
                         autoComplete="off" id="outlined-basic" type="number" label="Campaign price" variant="outlined"  size="small"/>
                    </FormControl>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                          <FormControlLabel control={
                          <Checkbox checked={props.blocked == 0? false : true} onChange={e => props.checkChange(e)} />
                          } label="Blocked" />
                      </FormControl>
                  </Col>
                  <Col sm={4}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="refcode"
                        value={props.referalcode}
                        onChange={(e) => props.setReferalcode(e.target.value)}
                         autoComplete="off" id="outlined-basic" label="Reference code" variant="outlined"  size="small"/>
                    </FormControl>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="description"
                          value={props.description}
                          onChange={(e) => props.setDescription(e.target.value)}
                       autoComplete="off" id="outlined-basic" label="Description" variant="outlined"  size="small"/>
                    </FormControl>
                    <Col xs={12} style={{marginTop: 20}}>
                          {/* <pre>{JSON.stringify(selected)}</pre> */}
                          <p>Branches</p>
                            <MultiSelect
                              options={props.branchlist}
                              value={props.selected}
                              onChange={props.setSelected}
                              labelledBy="Select"
                            />
                    </Col>                
                </Col>
                <Col sm={4}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Online Campaign Name</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.master}
                                label="Age"
                                onChange={props.masterChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.masterlist.map((item, index) => (
                                  <MenuItem value={item.campaign_id}>{item.campaign_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <div style={{marginTop: 40}}>
                      <LocalizationProvider  dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="Campaign Start Date"
                              value={props.dateFrom}
                              onChange={(newValue) => {
                                  props.setDatefrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                    </div>
                    <div style={{marginTop: 40}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="Campaign Start Date"
                              value={props.dateTo}
                              onChange={(newValue) => {
                                  props.setDateTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                    </div>
                </Col>
            </Row>

            <div>
              <Row>
                <Col md={2}>
                  <Button onClick={() => lookupClick()} className="retail-btn" style={{backgroundColor: '#2076d2', borderRadius: 8, marginTop: 20, width: '100%', marginLeft: 15, marginLeft: 15,  fontWeight: 'bold',}} variant="contained">Item Lookup</Button>
                </Col>
              </Row>
            </div>

            <Row >
                <Col md={5} className="productbatchborder">
                    <label style={{fontWeight: 'bold'}}>Products</label>
                    {
                    props.combolistloader ? 
                    <Loader /> :
                    props.tableRow.map((item, index) => {
                            return (
                                <Row style={{marginTop: 10}}>
                                    <Col sm={3}>
                                    <input disabled  onFocus={() => props.indexChange(index)} style={{width: '100%'}} placeholder="Reference code" autocomplete="off" ref={inputRef} type="text" name="query" value={props.refcodenew} onChange={(event) => props.handleOnChange(event, index, "code")} />
                                        {/* {props.hasResults && props.selectedIndex == index && (
                                            <div className="autocomplete">
                                                <ul ref={resultsRef} className="people scrolling-div">
                                                    {props.productlist.map(item => {
                                                    return (
                                                      
                                                        <li key={item.url}>
                                                            <a href="javascript:void(0)" onClick={() => props.listClickcode(
                                                                index, 
                                                                item.products_id,
                                                                item.product_code,
                                                                item.product_name,
                                                                item.product_refcode
                                                                )}>
                                                                {item.product_refcode} - { item.product_name }
                                                            </a>
                                                        </li>
                                                     ) })}
                                                </ul>
                                            </div>
                                        )} */}
                                    </Col>
                                    {/* <Col sm={4}>
                                        <input style={{width: '100%'}} placeholder="Qty" type="text" autocomplete="off" ref={inputRef}  name="query" value={item.product_quantity} onChange={(event) => props.stockChange(event, index, "code")} />
                                    </Col>
                                    <Col sm={3}>
                                        <label style={{fontSize: 10,}} >{item.product_name}</label>
                                    </Col> */}
                                    {/* <Col sm={2}>
                                        <a href="javascript:void(0)" onClick={() => props.deleteCombo(index)}>
                                            <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                        </a> 
                                    </Col> */}
                                    
                                </Row>
                            )
                            })
                        }
                        {/* <Row style={{marginTop:10}}>
                            <Col sm={2}>
                                    <Button onClick={props.addtablerow} style={{float: 'right', marginBottom: 20}}  variant="contained">Add</Button>
                              </Col>
                        </Row> */}
                </Col>
            </Row>

            {/* lookup */}
           <Modal
              size="lg"
              show={props.productmodal}
              onHide={() => props.setProductModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Product List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                <Button onClick={() => setProbtn(true)} variant="contained">Product List</Button>
                <Button onClick={() => setProbtn(false)} style={{marginLeft:30}} variant="contained">Combo List</Button>
                </div>
                {probtn == true ?
                <div>
                  <p style={{fontSize: 20, fontWeight: 'bold', marginTop: 20}}>Product List</p>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={5}>
                                <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined"  size="small" label="Barcode"/>
                        </Col>
                        <Col xs={12} md={5}>
                                <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined"  size="small" label="Product Name"/>
                        </Col>
                        {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} >
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th>Ref Code</th>
                                    <th>Barcode</th>
                                    <th>Batch Code</th>
                                    <th>Product Name</th>
                                    <th>Stock</th>
                                    <th>Sales Price</th>
                                </tr>
                            </thead>
                            {/* {productlistitem.length == 0 ? */}
                                <tbody>
                                    {lookupproductlist?.map((column) => (
                                        <tr>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>{column.product_refcode}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>{column.pb_inter_barcode}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>{column.pb_batch}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>{column.product_name}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>{column.pb_stock}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>{column.pb_salesrate}</td>
                                        </tr>
                                        ))
                                    } 
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>: 
                <div>
                  <p style={{fontSize: 20, fontWeight: 'bold', marginTop: 20}}>Combo List</p>
                  <Row>
                    <Col md={3}>
                      <FormControl sx={{ width: '100%' }}>
                          <TextField name="Zone"
                          onChange={(e) => searchProduct(e.target.value)}
                          autoComplete="off" id="outlined-basic" label="Referal code" variant="outlined"  size="small"/>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row style={{marginTop: 20}}>
                        <Col xs={12} >
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th>Ref Code</th>
                                    <th>Product Name</th>
                                </tr>
                            </thead>
                            {/* {productlistitem.length == 0 ? */}
                                <tbody>
                                    {combolist?.map((column) => (
                                        <tr>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>{column.product_refcode}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode, column.products_id)}>
                                              <>
                                              {column.products.map((item) => (
                                                <tr>
                                                  <td>{item.product_name}</td>
                                                </tr>
                                              ))}
                                              
                                              </>
                                            </td>
                                        </tr>
                                        ))
                                    } 
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>}
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default Fetchpage;
