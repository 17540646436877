import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import Button from '@mui/material/Button';
import WizardFormFirstPage from './FirstPage';
import WizardFormSecondPage from './SecondPage'
import Loader from '../../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '1', label: 'AG code', minWidth: 50,span: false },
    { id: '3', label: 'Ledger Name', minWidth: 50,span: false },
    { id: '4', label: 'Ledger Group', minWidth: 50,span: false },
    { id: '8', label: 'Opening Balance', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];

function State(props) {
  const [btnloader, setBtnloader] = React.useState(false);
    const [btnloader2, setBtnloader2] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState('1');

    const [ledgerId, setLedgerid] = React.useState('');
    const [ledgerCodenw, setLedgercodenw] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [form, setForm] = useState({
        ledgerCode: "",
        ledgerAccount: "",
        ledgerName: "",
        address1: "",
        address2: "",
        address3: "",
        gstin: "",
        mobile: "",
        email: "",
        website: "",
        whatsapp: "",
        openBalnce: "",
        creditLimit: "",
        originalopbalance: ""
      })
    const [blocked, setBlocked] = useState(0)
    const [enableBill, setEnablebill] = useState(0)
    const [count, setCount] = useState(1)

    const [accountId, setAccountid] = useState(0)

    const [source, setSource] = React.useState([]);
    const [userId, setUserid] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [state, setState] = React.useState("");
    const [stateList, setStatelist] = React.useState([]);
    const [district, setDistrict] = React.useState("");
    const [districtList, setDistrictlist] = React.useState([]);
    const [group, setGroup] = React.useState("");
    const [groupList, setGrouplist] = React.useState([]);
    const [country, setCountry] = React.useState('');
    const [countryList, setCountryList] = React.useState([]);
    const [opeditable, setopEditable] = React.useState(true);

    const [decr, setDecr] = React.useState(0);
    const [date1, setDate1] = React.useState(null);
    const [date, setDate] = React.useState(null);
    const [billNo, setBillno] = React.useState("");
    const [recievable, setRecievable] = React.useState("");
    const [payable, setPayable] = React.useState("");
    const [tableRow, setTableRow] = React.useState([]);

    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const radioChange = (event) => {
    if(event.target.value == "credit") {
       setDecr(1)
    }
    else {
      setDecr(0)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const updateForm = (e) => {
    setForm({
    ...form,
    [e.target.name]: e.target.value,
    })
  }

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
        setBlocked(1)
    }
    else {
        setBlocked(0)
    }
  }

  const enableBillchange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
        setEnablebill(1)
    }
    else {
        setEnablebill(0)
    }
  }

  useEffect(() => {
    getData()
    getLedgerCode()
    getCountry()
    getGroup()
  }, []);
  

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    setLoader(true)

    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewLedger", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.error == "false") {
            setSource(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getLedgerCode = () => {
    fetch(global.url + "getLedgercode", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.error == false){
        setLedgercodenw(responseJson.data.ledgercode)
      }
    })
  }

    const countryChange = (event) => {
      setCountry(event.target.value);
      getState(event.target.value)
    };

    const stateChange = (event) => {
      setState(event.target.value);
      getDistrict(event.target.value)
    };

    const districtChange = (event) => {
      setDistrict(event.target.value);
    };

    const groupChange =(event) => {
      setGroup(event.target.value)
    }

    const getDistrict = (id) => {
      var data = {
        "countryid": id
      }
      fetch(global.url + "viewDistrict", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          setDistrictlist(responseJson.data)
        }
        
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const getState = (id) => {
      var data = {
        "countryid": id
      }
      fetch(global.url + "viewState", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setStatelist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

  const getGroup = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)

    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewGroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          setGrouplist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getCountry = () => {
    fetch(global.url + "viewCountry", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      if(responseJson.status == "false") {
        setCountryList(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onNext = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
      setBtnloader(true)
        var data = {
          "ledger_id": ledgerId,
          "ledger_user_id": userId,
          "ledger_code": form.ledgerCode,
          // "ledger_account": form.ledgerAccount,
          "ledger_name": form.ledgerName,
          "ledger_group": group,
          "ledger_address1": form.address1,
          "ledger_address2": form.address2,
          "ledger_address3": form.address3,
          "ledger_country": country,
          "ledger_state": state,
          "ledger_district": district,
          "ledger_gstin": form.gstin,
          "ledger_mobile": form.mobile,
          "ledger_email": form.email,
          "ledger_website": form.website,
          "ledger_whatsapp": form.whatsapp,
          "ledger_open_balance": form.originalopbalance,
          "ledger_original_opbalance": form.originalopbalance,
          "ledger_credit_limit": form.creditLimit,
          "ledger_isbillwise_entry": enableBill,
          "ledger_decr": decr,
          "ledger_isactive": blocked,
          "ledger_branchid" : branch,
          "ledger_companyid" : company,
          "ledger_open_balance_date" : date1
        }
        fetch(global.url + "updateLedger", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            // console.log("responseJson", responseJson)
            if(responseJson.error == false) {
              const newValues = { ...form }
              alert("Success")
              setLedgerid(responseJson.data) 
              setModal(false)
              getData()
              setBtnloader(false)
            }
            if(responseJson.error == true) {
              alert(responseJson.message)
              setBtnloader(false)
              setModal(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const onLedgersave = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setBtnloader2(true)
    setModal(false)
    var data = {
      "ledger_id": ledgerId,
      "ledger_user_id": userId,
      "ledger_code": form.ledgerCode,
      // "ledger_account": form.ledgerAccount,
      "ledger_name": form.ledgerName,
      "ledger_group": group,
      "ledger_address1": form.address1,
      "ledger_address2": form.address2,
      "ledger_address3": form.address3,
      "ledger_country": country,
      "ledger_state": state,
      "ledger_district": district,
      "ledger_gstin": form.gstin,
      "ledger_mobile": form.mobile,
      "ledger_email": form.email,
      "ledger_website": form.website,
      "ledger_whatsapp": form.whatsapp,
      "ledger_open_balance": form.originalopbalance,
      "ledger_original_opbalance": form.originalopbalance,
      "ledger_credit_limit": form.creditLimit,
      "ledger_isbillwise_entry": enableBill,
      "ledger_decr": decr,
      "ledger_isactive": blocked,
      "ledger_branchid" : branch,
      "ledger_companyid" : company,
      "ledger_open_balance_date" : date1
    }
    fetch(global.url + "updateLedger", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          alert(responseJson.data)
          getData()
          setBtnloader2(false)
        }else{
          alert(responseJson.message)
          setBtnloader2(false)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const editClick = (
    ledgerid, 
    ledgercode,
    ledgeraccount,
    ledgername,
    ledgergroup,
    ledgeraddress1,
    ledgeraddress2,
    ledgeraddress3,
    ledgercountry,
    ledgerstate,
    ledgerdistrict,
    ledgergst,
    ledgermobile,
    ledgeremail,
    ledgerwebsite,
    ledgerwhatsap,
    ledgeropenbalance,
    ledgercreditlimit,
    ledgerbillwise,
    ledgerisactive,
    ledgerdecr,
    ledger_original_opbalance,
    ledgerdate
  ) => {
    setModal(true)
    setLedgerid(ledgerid)
    const newValues = { ...form }

    // setAccountid(accountid)
    newValues['ledgerCode']= ledgercode
    // newValues['ledgerAccount']= ledgeraccount
    newValues['ledgerName']= ledgername
    newValues['address1']= ledgeraddress1
    newValues['address2']= ledgeraddress2
    newValues['address3']= ledgeraddress3
    newValues['gstin']= ledgergst
    newValues['mobile']= ledgermobile
    newValues['email']= ledgeremail
    newValues['website']= ledgerwebsite
    newValues['whatsapp']= ledgerwhatsap
    newValues['openBalnce']= ledgeropenbalance
    newValues['creditLimit']= ledgercreditlimit
    newValues['originalopbalance']= ledger_original_opbalance
    
    getState(ledgercountry)
    getGroup()
    getDistrict(state)
    setForm(newValues)
    setEditmode(true)
    setCountry(ledgercountry)
    setState(ledgerstate)
    setDistrict(ledgerdistrict)
    setDate1(ledgerdate)
    setGroup(ledgergroup)
    setEnablebill(ledgerbillwise)
    setDecr(ledgerdecr)
    setBlocked(ledgerisactive)
    getLedgerbill(ledgerid)
    opCheck(ledgerid)
  }

  const opCheck = (id) => {
        var data = {
          "ledger_id":  id
        }
        fetch(global.url + "ledgerisEditable", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.error == false){
                setopEditable(true)
              }
              else{
                setopEditable(false)
              }
             
          })
          .catch((error)=>{
              console.log(error)
          })
  }

  const onAdd = () => {
      setModal(true)
      const newValues = { ...form }
      newValues['ledgerCode']= ""
      newValues['ledgerAccount']= ""
      newValues['ledgerName']= ""
      newValues['address1']= ""
      newValues['address2']= ""
      newValues['address3']= ""
      newValues['gstin']= ""
      newValues['mobile']= ""
      newValues['email']= ""
      newValues['website']= ""
      newValues['whatsapp']= ""
      newValues['openBalnce']= 0
      newValues['creditLimit']= 0
      newValues['originalopbalance']= 0
      getGroup()
      setForm(newValues)
      setEditmode(false)
      setLedgerid("")
      setGroup("")
      setCountry("")
      setState("")
      setDistrict("")
      setDate1('')
      setBlocked(0)
      setEnablebill(0)
      setDecr(0)
  }

  const getLedgerbill = (id) => {
      var data = {
        "ledgerid":  id
      }
      fetch(global.url + "viewLedgerbill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == "false") {
              setTableRow(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

 

  const onhide = () => {
    setEditmode(false)
    setModal(false)
    setCount(1)
    setTableRow([])
  }

  const deleteClick = (id) =>  {
    var data = {
      "ledger_id": id,
    }
    fetch(global.url + "deleteLedger", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const deleteBill = (id) => {
      var data = {
        "ledgerbill_id": id,
      }
      fetch(global.url + "deleteLedgerbill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.data)
          getLedgerbill(ledgerId)
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    return(
        <div className="main-content">
          {modal == false ? 
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Ledger Lists</p>
               {
                    usertype == "superadmin" ? 
                    null : 
                  <Button variant="contained"  onClick={onAdd}>Add</Button>
              }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.ledger_code}
                              </TableCell>
                              <TableCell >
                                  {row.ledger_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.ledger_name}
                              </TableCell>
                              <TableCell >

                              </TableCell>
                              <TableCell >
                                  {parseFloat(row.ledger_open_balance)?.toFixed(2)}
                              </TableCell>
                              <TableCell >
                              {
                                usertype == "superadmin" ? 
                                null : 
                                <a href="javascript:void(0)" onClick={() => 
                                    editClick(
                                        row.ledger_id, 
                                        row.ledger_code, 
                                        row.ledger_account, 
                                        row.ledger_name, 
                                        row.ledger_group,
                                        row.ledger_address1, 
                                        row.ledger_address2,
                                        row.ledger_address3,
                                        row.ledger_country,
                                        row.ledger_state,
                                        row.ledger_district,
                                        row.ledger_gstin,
                                        row.ledger_mobile,
                                        row.ledger_email,
                                        row.ledger_website,
                                        row.ledger_whatsapp,
                                        row.ledger_open_balance,
                                        row.ledger_credit_limit,
                                        row.ledger_isbillwise_entry,
                                        row.ledger_isactive,
                                        row.ledger_decr,
                                        row.ledger_original_opbalance,
                                        row.ledger_open_balance_date
                                    )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.ledger_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> :

            <div>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Ledger Creation</p>
                <Button variant="contained"  onClick={onhide}>Back</Button>
            </div>
              <form
                className="col-12 form"
                onSubmit={() =>
                  alert(
                    `Submitted Email: ${form.email} Name: ${form.name} Password: ${form.password}`
                  )
                }
              >
              {count === 1 ? (
                  <WizardFormFirstPage 
                        form={form} 
                        ledgerCodenw={ledgerCodenw}
                        blocked = {blocked} 
                        enableBill = {enableBill}
                        enableBillchange = {enableBillchange}
                        countryChange = {countryChange}
                        stateChange ={stateChange}
                        districtChange ={districtChange}
                        groupChange ={groupChange}
                        state ={state}
                        stateList= {stateList}
                        district = {district}
                        districtList= {districtList}
                        group = {group}
                        groupList ={groupList}
                        country ={country}
                        countryList ={countryList}
                        updateform = {updateForm} 
                        checkchange = {checkChange} 
                        date1 = {date1}
                        setDate1 ={setDate1}
                        decr = {decr}
                        radioChange = {radioChange}
                        opeditable={opeditable}
                  />
                ) : null}
                {/* {count === 2 ? (
                  <WizardFormSecondPage 
                        date={date} 
                        billNo = {billNo} 
                        recievable = {recievable} 
                        payable = {payable} 
                        tableRow = {tableRow} 
                        setDate ={setDate}
                        setBillno= {setBillno}
                        setRecievable= {setRecievable}
                        setPayable={setPayable}
                        setTableRow={setTableRow}
                        addRow = {addRow}
                        deleteBill = {deleteBill}
                  />
                ) : null} */}
              </form>
              {/* <Col xs ={12} style={{marginTop:30}}>
                  { count == 1 && editMode == false ? 
                  <div>
                    {
                      btnloader ? 
                      <Loader2
                          type="Oval"
                          color="#38B0DE"
                          height={30}
                          width={30}
                          style={{float: 'right'}}
                      />:
                      <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Save</Button> }
                  </div>:
                    
                    count == 1 && editMode == true ?
                    <div>
                      <div>
                      {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                          <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Save</Button>}
                      </div>
                      <div>
                      {
                        btnloader2 ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                          <Button onClick={onLedgersave}  style={{float: 'right', marginRight: 20}} variant="contained">Save</Button>}
                      </div>
                    </div>
                    : null
                  }
            </Col> */}
            <Col xs ={12} style={{marginTop:30}}>
                  <div>
                    {
                      btnloader ? 
                      <Loader2
                          type="Oval"
                          color="#38B0DE"
                          height={30}
                          width={30}
                          style={{float: 'right'}}
                      />:
                      <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Save</Button> }
                  </div>
            </Col>
        </div>}
      </div>
    )
}
export default State;