
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";
import TimePicker from '@mui/lab/TimePicker';

const columns = [
    { id: '1', label: 'Sl#', minWidth: 50,span: false },
    { id: '1', label: 'Log Id', minWidth: 50,span: false },
    { id: '2', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Sales Executive', minWidth: 50,span: false },
    { id: '2', label: 'Deivery Boy', minWidth: 50,span: false },
    { id: '3', label: 'Invoice No.', minWidth: 50,span: false },
    { id: '3', label: 'Order Type', minWidth: 50,span: false },
    { id: '4', label: 'Customer', minWidth: 50,span: false },
    { id: '5', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Subtotal', minWidth: 50,span: false },
    { id: '8', label: 'Delivery Charge', minWidth: 50,span: false },
    { id: '8', label: 'Discount', minWidth: 50,span: false },
    { id: '9', label: 'Net Total', minWidth: 50,span: false },
  ];

function Salesorderreport(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [value, setValue] = React.useState(new Date);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");
    const [modal, setModal] = React.useState(false);
    const [billDetails, setBillsetails] = React.useState([]);
    const [starttime, setStarttime] = React.useState(new Date);
    const [enddtime, setEndtime] = React.useState(new Date);
    const [endvalue, setEndvalue] = React.useState(new Date);
    const [userlist, setUserlist] = useState([])
    const [salesmanid, setSalesmanid] = useState("")
    const [frominvoice, setFrominvoice] = useState("")
    const [toinvoice, setToinvoice] = useState("")
    const [executiveid, setExecutiveid] = useState("")
    const [executiveList, setExecutivelist] = useState([])
    const [mobile, setMobile] = useState("")
    const [totaldeli, setDeli] = useState("0")
    const [totdisc, setDisc] = useState("0")
    const [totnet, setTotnet] = useState("0")
    const [totsub, setTotsub] = useState("0")
    const [date, setDate] = React.useState(new Date);
    const [input, setInput] = useState(0);
    const [filt, setFilt] = useState(false)

  const executiveChange = (event) => {
    setExecutiveid(event.target.value)
  }

  const userChange = (event) => {
    setSalesmanid(event.target.value)
}

  const startChange = (newValue) => {
    setStarttime(newValue);
  };

  const endChange = (newValue) => {
    setEndtime(newValue);
  };

  useEffect(() => {
      getData()
  }, [input])


  const salestotal = () => {
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company,
      }
      
    fetch(global.url + "salesordertotal", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("res", responseJson)
      if(responseJson.error == false){
        setDeli(responseJson.data.deliverycharge)
        setDisc(responseJson.data.discount)
        setTotnet(responseJson.data.nettotal)
        setTotsub(responseJson.data.subtotal)
      }
    })
  }

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "page" : input*10,
        "limit": 10
      }
    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      // console.log("responseJson", responseJson)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
    })
  }

  const viewBillclick = (id) => {
    setModal(true)

    var data = {
      "sales_id":  id
    }
    fetch(global.url + "viewSalesDetail", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.status == "false"){
        setBillsetails(responseJson.data)
      }
       
    })
  }

  const returnClick = (id , billno) => {
    if (window.confirm('Are you sure you want to return the bill ' + billno + " ?")) {
      var data = {
        "sales_id":  id,
        "branchid": userbranchid,
        "companyid": usercompanyid
      }
      fetch(global.url + "salesReturn", {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.error == false){
          getData()
          alert(responseJson.message)
        }
      })
    }
  }

  const cancelClick = (id) => {
    var data = {
      "salesorderid":  id,
    }
    fetch(global.url + "salesOrderCancel", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.error == false){
        getData()
        alert(responseJson.message)
      }
    })
  }

  useEffect(() => {
    getUser()
    getSalesexecutive()
  }, []);

  const getSalesexecutive = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    var data = {
      "companyid": company,
      "branchid" : branch
    }
    fetch(global.url + "viewDeliveryboy", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setUserlist(responseJson.data)
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getUser = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)

    
    var data = {
      "companyid": company,
      "type": userdata.user_type,
      "branchid" : branch
    }
    fetch(global.url + "viewSalesExicutive", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setExecutivelist(responseJson.data)
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onfilterClick = () => {
    setFilt(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "sales_user_id": executiveid,
        "salesorder_fromdate": value.toISOString().split('T')[0],
        "salesorder_fromtime": new Date(starttime.toISOString()).toLocaleTimeString('en',
                              { timeStyle: 'short', hour12: false, }),
        "salesorder_todate": endvalue.toISOString().split('T')[0],
        "excelexport": true,
        "salesorder_totime": new Date(enddtime.toISOString()).toLocaleTimeString('en',
                              { timeStyle: 'short', hour12: false, })
      }

    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      // console.log("responseJson", responseJson)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
      else{
        setSource([])
      }
    })
  }

  const onfilterClick3 = () => {
    setFilt(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "sales_user_id": executiveid,
        "user_id": salesmanid,
        "salesorder_mobile" : mobile,
        "salesorder_date" : date.toISOString().split('T')[0],
        "excelexport": true,
      }

    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      // console.log("responseJson3", responseJson)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
      else{
        setSource([])
      }
    })
  }

  const onfilterClick2 = () => {
    setFilt(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "sales_user_id": executiveid,
        "invoice_number_from": frominvoice,
        "invoice_number_to": toinvoice,
        "excelexport": true
      }
    fetch(global.url + "salesOrderReport", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      setLoader(false)
      // console.log("responseJson2", responseJson)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
        setSource(responseJson.data)
        setDeli(responseJson.total.deliverycharge)
        setDisc(responseJson.total.discount)
        setTotnet(responseJson.total.nettotal)
        setTotsub(responseJson.total.subtotal)
      }
      else{
        setSource([])
      }
    })
  }

  const onPrint = () => {
      window.print()
  }

  const decrement = () => {
    setInput(prevCount => prevCount - 1);
    
  }

 const increment = () => {
    setInput(prevCount => prevCount + 1);
  
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, marginBottom: 0, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>User wise Sales Order Report</p>
               <Button onClick={() => onPrint()} variant="primary" style={{padding: 5, fontSize: 15}}>Print</Button>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Row>
                    <Col sm={2} style={{marginTop:20}}>
                          <FormControl sx={{width: '100%' }}>
                                  <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Sales Executive</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={executiveid}
                                      label="Age"
                                      onChange={executiveChange}
                                      size="small"
                                  >
                                  <MenuItem value="">
                                      <em>All</em>
                                  </MenuItem>
                                  {executiveList.map((row, index) => {
                                      return (
                                      <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                  )})}
                                  </Select>
                          </FormControl>
                    </Col>
                    <Col sm={2} style={{marginTop:20}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesmanid}
                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {userlist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                            </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 20}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setMobile(e.target.value)} value={mobile} id="outlined-basic" placeholder='Mobile' variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="Date"
                                  value={date}
                                  onChange={(newValue) => {
                                  setDate(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={2} lg={3} style={{marginTop: 20}}>
                          <Button onClick={() => onfilterClick3()} style={{width: '50%', height: 40}} variant="primary">Filter</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="From"
                                  value={value}
                                  onChange={(newValue) => {
                                  setValue(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="To"
                                  value={endvalue}
                                  onChange={(newValue) => {
                                  setEndvalue(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col sm={2} style={{marginTop: 20}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="Start Shift"
                                    value={starttime}
                                    onChange={startChange}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                            </LocalizationProvider>
                              
                        </Col>
                        <Col sm={2}  style={{marginTop: 20}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                  label="End Shift"
                                  value={enddtime}
                                  onChange={endChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                        </Col>

                        <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                          <Button onClick={() => onfilterClick()} style={{width: '50%', height: 40}} variant="primary">Filter</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField type="number" autoComplete='off' onChange={(e) => setFrominvoice(e.target.value)} value={frominvoice} placeholder='Invoice From' id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField type="number" autoComplete='off' onChange={(e) => setToinvoice(e.target.value)} value={toinvoice} id="outlined-basic" placeholder='Invoice To' variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                      
                      <Col xs={12} md={2} lg={3} style={{marginTop: 20}}>
                          <Button onClick={() => onfilterClick2()} style={{width: '50%', height: 40}} variant="primary">Filter</Button>
                      </Col>
                    </Row>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }} >
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table" className="section-to-print">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} >

                              <TableCell >
                                  {index+1}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_id}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_date}
                              </TableCell>
                              
                              <TableCell >
                                  {row.user_name2}
                              </TableCell>

                              <TableCell >
                                  {row.user_name}
                              </TableCell>

                              <TableCell >
                                  {row.salesorder_invoice}
                              </TableCell>
                              <TableCell>
                                  {row.ordertype_name}
                              </TableCell>
                              <TableCell >
                                  {row.customer_name}
                              </TableCell>
                              <TableCell >
                                  {row.customer_mobile}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_total}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_other_charges}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_discount}
                              </TableCell>
                              <TableCell >
                                  {row.salesorder_replacereturn == 1 || row.salesorder_replacereturn == 2 ? row.salesorder_balancereceivable :row.salesorder_cashledger}
                              </TableCell>
                             
                        </TableRow>
                      );
                    })}
                      <TableRow>
                          <TableCell colSpan={9} style={{fontWeight: 'bold'}}>
                              Total
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totsub}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                              {totaldeli}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totdisc}
                          </TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>
                          {totnet}
                          </TableCell>
                      </TableRow>
                </TableBody>
              </Table>
              }
            </TableContainer>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>discount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{item.user_name}</td>
                                <td>{item.code}</td>
                                <td>{item.product}</td>
                                <td>{item.qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.discount}</td>
                                <td>{item.amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                </Modal.Body>
            </Modal>
            
          </Paper> 
          {filt == true ? null :
          <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
          }
      </div>
    )
}
export default Salesorderreport;