import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import LoaderBtn from "react-loader-spinner";


const columns = [
    { id: '1', label: 'Batch Code', minWidth: 50,span: false, },
    { id: '1', label: 'Ref Code', minWidth: 50,span: false },
    { id: '1', label: 'Barcode', minWidth: 50,span: false },
    { id: '2', label: 'Product Name, Colour, Size', minWidth: 50,span: false },
    // { id: '3', label: 'Size', minWidth: 50,span: false },
    // { id: '4', label: 'Colour', minWidth: 50,span: false },
    // { id: '5', label: 'Item Cost', minWidth: 50,span: false },
    { id: '5', label: 'Opening', minWidth: 50,span: false,  },
    { id: '5', label: 'In', minWidth: 50,span: false },
    { id: '8', label: 'Out', minWidth: 50,span: false },
    { id: '5', label: 'Closing', minWidth: 50,span: false },
    { id: '5', label: 'Item Cost', minWidth: 50,span: false },
    { id: '8', label: 'Stock Value', minWidth: 50,span: false },
    // { id: '9', label: 'Closing', minWidth: 50,span: false },
    // { id: '10', label: 'Stock Value', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  
function Detailedstock(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');
  const [input, setInput] = useState(0);
  const [filt, setFilt] = useState(false)
  const [source, setSource] = React.useState([]);
  const [tname, setTname] = React.useState([])

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);
  const [batchcode, setBatchcode] = React.useState("");
  const [dateFrom, setDatefrom] = React.useState(new Date)
  const [dateTo, setDateTo] = React.useState(new Date)

  const [limit, setLimit] = React.useState(50);
  const [stockvalueSum, setStockvalueSum] = React.useState(0);
  const [stockload, setStockload] = React.useState(false);

  function setData(childData) {
    setToggle(childData)
  }

  const limitChange = (event) => {
    setLimit(event.target.value)
    if(event.target.value == "all"){
      getData()
    }
    setLimit(event.target.value)
    
  }

  useEffect(() => {
    filter()
  }, [limit])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
      
      filter()
    }, [input]);

    const getData = () => {
        setFilt(true)
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "branchid" : branch,  
          "from_date":  dateFrom.toISOString().split('T')[0],
          "to_date": dateTo.toISOString().split('T')[0],
     
        }
        fetch(global.url + "stockSummaryReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.error == false){
                setLoader(false)
                setSource(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }
    const decrement = () => {
      setInput(prevCount => prevCount - 1);
      
    }

   const increment = () => {
      setInput(prevCount => prevCount + 1);
    }

    const filter = () => {
      setFilt(false)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "branchid" : branch,
          "refcode": batchcode,
          "from_date":  dateFrom.toISOString().split('T')[0],
          "to_date": dateTo.toISOString().split('T')[0],
          "page" : input * limit,
          "limit": limit,
        }
        fetch(global.url + "stockSummaryReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setLoader(false)
              if(responseJson.error == false){
                setSource(responseJson.data)
                // console.log("responseJson", responseJson)
            
              }else{
                alert(responseJson.message)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const getStockValue = () => {
      setStockload(true)
      var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company,
        "fromdate":  dateFrom.toISOString().split('T')[0],
        "todate": dateTo.toISOString().split('T')[0],
      }
      fetch(global.url + "totalstockvalue",{
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((responseJson) => {
          // console.log("totalstockvalue", responseJson)
          setStockload(false)
          if(responseJson.error == false){
            setStockvalueSum(responseJson.data)
          }
      })
      .catch((error) => {
        console.log(error)
      })
    }

    const TabClick = (id, open, close, item, stock) => {
     window.open('/detailsofstocks/' + id + '/' + open + '/' + close + '/' + item + '/' + stock + '/' + dateFrom.toISOString().split('T')[0] + '/' +  dateTo.toISOString().split('T')[0],"_blank")
    }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Detailed Summary</p>
            </div>

            <Row style={{margin: 20}}>
                  <Col xs={12} md={3} style={{marginTop: 20}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="From"
                              value={dateFrom}
                              onChange={(newValue) => {
                                  setDatefrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 20}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="To"
                              value={dateTo}
                              onChange={(newValue) => {
                                  setDateTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>

                  <Col xs={12} md={3} style={{marginTop: 20}}>
                        <FormControl sx={{ width: '100%'}}>
                            <TextField name="Zone"
                            onChange={(e) => setBatchcode(e.target.value)}
                            autoComplete="off" id="outlined-basic" label="Ref code" variant="outlined"  size="small"/>
                        </FormControl>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 20}}>
                    <Button onClick={filter}  style={{width: '50%', height: 40, }} variant="contained">Filter</Button>
                  </Col>
            </Row>
            <Row style={{margin: 20}}>
              <Col md={2}>
                  <FormControl sx={{ mt: 1, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Limit</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={limit}
                            label="Age"
                            onChange={limitChange}
                            size="small"
                            
                        >
                            <MenuItem value={"50"}>50</MenuItem>
                            <MenuItem value={"250"}>250</MenuItem>
                            <MenuItem value={"500"}>500</MenuItem>
                            <MenuItem value={"1000"}>1000</MenuItem>
                            <MenuItem value={"all"}>All</MenuItem>
                        </Select>
                </FormControl>
              </Col>
              <Col md={3}>
                  <div>
                    <p style={{fontSize: 15, fontWeight: 'bold', marginTop: 15, float: 'right'}}>Stock Value : {stockvalueSum} </p>
                  </div>
              </Col>
              <Col md={3}>
                {stockload ?
                  <LoaderBtn
                      type="Oval"
                      color="#38B0DE"
                      height={30}
                      width={30}
                      style={{marginTop: 12, marginLeft: 10}}
                      
                  />:
                <Button onClick={getStockValue} style={{marginTop: 15, height: 25, fontSize: 11}} variant="contained">Stock Value</Button>}
              </Col>
            </Row>
            {/* <Row style={{margin: 20}}>
              
            </Row> */}
            <TableContainer sx={{ maxHeight: 440, marginTop: 5 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                     <TableCell style={{fontWeight: 'bold', fontSize: 16}}>
                      Batch Code
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15}}>
                      Ref Code
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15}}>
                      Barcode
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15}}>
                      Product Name, Colour, Size
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15,  textAlign: 'right'}}>
                      Opening
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15, textAlign: 'right'}}>
                      In
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15, textAlign: 'right'}}>
                      Out
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15, textAlign: 'right'}}>
                      Closing
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15, textAlign: 'right'}}>
                      Item Cost
                      </TableCell>
                      <TableCell style={{fontWeight: 'bold', fontSize: 15, textAlign: 'right'}}>
                      Stock Value
                      </TableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {source.map((item, index) => {
                      return (
                        <TableRow onClick={ () => TabClick(item.batchcode, item.opening, item.closing, item.itemcost, item.stockvalue)} style={item.stockvalue == 0 ? {cursor: 'pointer', backgroundColor: '#f2f25f'} : {cursor: 'pointer'} } tabIndex={-1} key={item.code}>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                              {item.batchcode}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {item.product_refcode}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {item.pb_inter_barcode}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {item.product_name}
                              </TableCell>
                            
                              {/* <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.size_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.color_name}
                              </TableCell> */}
                              {/* <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sr_cost}
                              </TableCell> */}
                              <TableCell style={{fontWeight: 'bold', fontSize: 12, textAlign: 'right'}}>
                                  {parseFloat(item.opening)?.toFixed(2)}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12, textAlign: 'right'}}>
                                  {parseFloat(item.total_in)?.toFixed(2)}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12, textAlign: 'right'}}>
                                  {parseFloat(item.total_out)?.toFixed(2)}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12, textAlign: 'right'}}>
                                  {parseFloat(item.closing)?.toFixed(2)}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12, textAlign: 'right'}}>
                                  {parseFloat(item.itemcost)?.toFixed(2)}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12, textAlign: 'right'}}>
                                  {item.stockvalue}
                              </TableCell>

                              {/* <TableCell style={{fontWeight: 'bold'}}>
                              </TableCell> */}
                              {/* <TableCell style={{fontWeight: 'bold'}}>
                                {row.sr_value}
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
  }
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[50, 250, 500]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper> 
          {/* <Modal
            size="xl"
            show={modal}
            onHide={() => setModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Detailed Stock
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Voucher Date</th>
                  <th>Voucher No</th>
                  <th>Voucher Type</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Colour</th>
                  <th>Size</th> 
                  <th>Unit</th>
                  <th>Opening</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>Cost</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                  {tname.map((row, index) => (
                    <tr style={{marginTop: 20}}>
                      <td style={{fontSize: 13}}><span style={{fontSize: 13, marginTop: 20}}>{row.sr_date}</span></td>
                      <td style={{fontSize: 13}}>{row.sr_id}</td>
                      <td style={{fontSize: 13}}>{row.tran_name}</td>
                      <td style={{fontSize: 13}}>{row.sr_code}</td>
                      <td style={{fontSize: 13}}>{row.sr_product_name}</td>
                      <td style={{fontSize: 13}}>{row.sr_color}</td>
                      <td style={{fontSize: 13}}>{row.sr_size}</td> 
                      <td style={{fontSize: 13}}>{row.sr_unit}</td>
                      <td style={{fontSize: 13}}>{row.sr_opening}</td>
                      <td style={{fontSize: 13}}>{row.sr_opening}</td>
                      <td style={{fontSize: 13}}>{row.sr_in}</td>
                      <td style={{fontSize: 13}}>{row.sr_out}</td>
                      <td style={{fontSize: 13}}>{row.sr_value}</td>
                  </tr>
                  ))}
              </tbody>
            </Table>
            </Modal.Body>
        </Modal> */}
        {filt == true ? null :
        <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
            }
      </div>
    )
}
export default Detailedstock;