import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { useHistory, useParams } from "react-router-dom";
import Loader from '../loader/loader';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import './login.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function DeveloperLogin() {
    let history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loader, setLoader] = useState(false);

    const onSubmit = () => {
        setLoader(true)
        var data = {
            "user_name": username,
            "user_password": password,
        }
        fetch(global.url + "developerlogin", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY' : 'erp6129'
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            if(responseJson.error == true) {
                alert(responseJson.message)
            }
            else{
                localStorage.setItem("isAuthenticated", true);
                localStorage.setItem("developer", true);
                localStorage.setItem("user", JSON.stringify(responseJson.data))
                    history.push('/companycreation')
            }
        })
        .catch((error)=>{
            console.log(error)
        })

    }

  return (
    <div className="App">
      <header className="Login-component">
            <Container fluid>
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        <div className="login-box">
                            <div className="box-inside">
                                <div className="login-title">
                                    <strong>NASSCRIPT
                                        
                                    </strong>
                                </div>
                                <div class="frm-title">
                                    Welcome back! Please login to continue
                                </div>
                                <div>
                                    <p style={{fontSize: 20, fontWeight: 'bold', marginBottom: 'unset', color: '#8a4cff'}}>Developer Mode Login</p>
                                </div>
                                <Form>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField className="login-textfield" value={username} onChange={(e) => setUsername(e.target.value)} id="outlined-basic" label="Username" variant="outlined"  size="small"/>
                                        {/* <BsPersonFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/> */}
                                    </FormControl>

                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField className="login-textfield" value={password} onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label="Password" variant="outlined" type="password"  size="small"/>
                                        {/* <BsFillLockFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/> */}
                                    </FormControl>

                                    <div >
                                        <div style={{paddingBottom: 20}}>
                                            {loader ? <Loader /> :
                                                <a href="javascript:void(0)" onClick={() => {
                                                        onSubmit()
                                                    }}  variant="primary" type="submit" style={{marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 100, padding: 5, borderRadius: 5}}>
                                                    Login
                                                    <FaArrowCircleRight color="#fff" size={12} className="lefticon"/>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </Form>
                                <div class="frm-footer"><span className="nastext">NASScript</span> © 2021.</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
      </header>
    </div>
  );
}

export default DeveloperLogin;
