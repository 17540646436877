import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '2', label: 'Representative', minWidth: 50,span: false },
    { id: '2', label: 'AG code', minWidth: 50,span: false },
    { id: '4', label: 'Address', minWidth: 50,span: false },
    { id: '5', label: 'Country', minWidth: 50,span: false },
    { id: '6', label: 'State', minWidth: 50,span: false },
    { id: '7', label: 'District', minWidth: 50,span: false },
    { id: '10', label: 'Mobile', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    

function Promoter(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [btnloader, setBtnloader] = React.useState(false);
    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);


    const [promoter, setPromoter] = React.useState("");
    const [promoterId, setPromoterid] = React.useState("");
    const [ledger, setLedger] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [state, setState] = React.useState("");
    const [gstIn, setGstin] = React.useState("");
    const [mobile, setMobile] = React.useState("");
   
    const [userId, setUserid] = React.useState("");
    const [district, setDistrict] = React.useState("");
    const [balance, setBalance] = React.useState("");

    const [countryList, setCountrylist] = React.useState([])
    const [stateList, setStatelist] = React.useState([])
    const [districtList, setDistrictlist] = React.useState([])
    
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getCountry()
  }, []);

  useEffect(() => {
    getData()
  }, []);

  

  const getCountry = () => {
    fetch(global.url + "viewCountry", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        setCountrylist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const countryChange = (event) => {
    setCountry(event.target.value);
    getState(event.target.value)
  };

  const stateChange = (event) => {
    setState(event.target.value);
    getDistrict(event.target.value)
  };

  const districtChange = (event) => {
    setDistrict(event.target.value);
  };

  const getDistrict = (id) => {
    var data = {
      "countryid": id
    }
    fetch(global.url + "viewDistrict", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      if(responseJson.status == "false") {
        setDistrictlist(responseJson.data)
      }
      
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onAdd = () => {
      setModal(true)
      setPromoterid("")
      setPromoter("")
      setAddress("")
      setCountry("")
      setState("")
      setDistrict("")
      setMobile("")
      getCountry()
  }


  const getState = (id) => {
    var data = {
      "countryid": id
    }
    fetch(global.url + "viewState", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      if(responseJson.status == "false") {
        setStatelist(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const editClick = (
        repid, 
        promoter,
        rep_address,
        rep_country,
        rep_state,
        rep_district,
        rep_mobile,
      ) => {
        setModal(true)
        setPromoterid(repid)
        setPromoter(promoter)
        setAddress(rep_address)
        setCountry(rep_country)
        setState(rep_state)
        setDistrict(rep_district)
        setMobile(rep_mobile)
        getCountry()
        getState(rep_country)
        getDistrict(rep_state)
    }

    const onSave = () =>  {
      setBtnloader(true)
      var data = {
        "promoter_id": promoterId,
        "promoter_user_id": userId,
        "promoter_name": promoter,
        "promoter_address": address,
        "promoter_country": country,
        "promoter_state": state,
        "promoter_district": district,
        "promoter_mobile": mobile,
        "promoter_branchid" : userbranchid,
        "promoter_companyid" : usercompanyid
      }
      fetch(global.url + "updatePromoter", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            getData()
            setModal(false)
            alert(responseJson.data)
            setBtnloader(false)
          } else {
            alert(responseJson.message)
            setBtnloader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    
  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setLoader(true)
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewPromoter", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setLoader(false)
            setSource(responseJson.data)
          }
          else{
            setLoader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const deleteClick = (id) =>  {
    var data = {
      "promoter_id": id,
    }
    fetch(global.url + "deletePromoter", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Promoter List</p>
               {
                    usertype == "superadmin" ? 
                    null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.promoter_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.promoter_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.promoter_address}
                              </TableCell>
                              <TableCell >
                                  {row.promoter_country}
                              </TableCell>
                              <TableCell >
                                  {row.promoter_state}
                              </TableCell>
                              <TableCell >
                                  {row.promoter_district}
                              </TableCell>
                              <TableCell >
                                  {row.promoter_mobile}
                              </TableCell>
                              <TableCell >
                              {
                                usertype == "superadmin" ? 
                                null : 
                                  <a href="javascript:void(0)" onClick={() => 
                                      editClick(
                                        row.promoter_id, 
                                        row.promoter_name, 
                                        row.promoter_address, 
                                        row.promoter_country, 
                                        row.promoter_state,
                                        row.promoter_district, 
                                        row.promoter_mobile,
                                      )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                                }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.promoter_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Promoter Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setPromoter(e.target.value)} value={promoter}  label="Promotor name" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>

                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setAddress(e.target.value)} value={address}  label="Addess 1" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={country}
                                label="Age"
                                onChange={countryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {countryList.map((item, index) => (
                                  <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    </Col>
                    <Col xs={12}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={state}
                                    label="Age"
                                    onChange={stateChange}
                                    size="small"
                                    
                                  >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {stateList.map((item, index) => (
                                      <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">District</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={district}
                                    label="Age"
                                    onChange={districtChange}
                                    size="small"
                                    
                                  >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {districtList.map((item, index) => (
                                      <MenuItem value={item.district_id}>{item.district_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setMobile(e.target.value)} value={mobile}  label="Mobile" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Promoter;