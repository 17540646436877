import './App.css';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppNavigator from './router';
import './fonts/BAHNSCHRIFT 10.TTF';

function App() {


  // global.url = "https://erp.fetch.qa/erp/public/api/";    //live url
  // global.images = "https://erp.fetch.qa/erp/public/";
  // global.excell = "https://erp.fetch.qa/erp/storage/app/";

  // global.url = "http://erpdemo.fetch.qa/erp/public/api/";
  // global.images = "http://erpdemo.fetch.qa/erp/public/";
  // global.excell = "http://erpdemo.fetch.qa/erp/storage/app/";

  global.url = "https://ovine.nasscript.com/erp/public/api/";
  global.login = "https://ovine.nasscript.com/erp/public/";
  global.images = "https://ovine.nasscript.com/erp/public/";
  global.excell = "https://ovine.nasscript.com/erp/storage/app/";

  // global.url = "https://erpfetch.nasscript.in/erp/public/api/";
  // global.login = "https://erpfetch.nasscript.in/erp/public/";
  // global.images = "http://erpfetch.nasscript.in/erp/public/";
  // global.excell = "http://erpfetch.nasscript.in/erp/storage/app/";

  // global.url = "https://uitestingerpfetch.nasscript.in/erp/public/api/";
  // global.login = "https://uitestingerpfetch.nasscript.in/erp/public/";
  // global.images = "https://uitestingerpfetch.nasscript.in/erp/public/";
  // global.excell = "https://uitestingerpfetch.nasscript.in/erp/storage/app/";
 
  // global.url = "https://erp.nasshopy.com/erp/public/api/";
  // global.images = "https://erp.nasshopy.com/erp/public/";
  // global.excell = "https://erp.nasshopy.com/erp/storage/app/";


  // global.type = userType
  // global.branch = branchId
  // global.company = comapanyId
  // global.userid = userId

  global.proname = "Fetch"
  global.website = "www.fetch.qa"
  // global.proname = "Nasshopy"
  // global.website = "www.nasshopy.com"
  

  return (
      <AppNavigator />
  );
}

export default App;
