import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Loader from '../../loader/loader'

const columns = [
    { id: '1', label: 'Accounts', minWidth: 50,span: false },
    { id: '2', label: 'Credit', minWidth: 50,span: false },
    { id: '3', label: 'Debit', minWidth: 50,span: false },
    
  ];

function Trialbalance(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');
  const [source, setSource] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [blocked, setBlocked] = React.useState(0)
  const [creditsum, setCreditsum] = React.useState("")
  const [debitsum, setDebitsum] = React.useState("")
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
      getData()
    }, []);

    const getData = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
      var data = {
        "type":  userdata.user_type,
        "companyid": company
      }
      fetch(global.url + "trialbalancenew", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          setLoader(false)
            if(responseJson.error == false){
              setSource(responseJson.data)
              setDebitsum(responseJson.total.totaldebit)
              setCreditsum(responseJson.total.totalcredit)
            }
            else{
              setSource([])
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Trial Balance</p>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {
                  source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.ledger_name}
                              </TableCell>
                              <TableCell >
                                  {row.credit}
                              </TableCell>
                              <TableCell >
                                  {row.debit}
                              </TableCell>

                              
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableBody>
                        <TableRow  tabIndex={-1} >
                              <TableCell style={{fontWeight: 'bold'}}>
                                  
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                 {creditsum}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {debitsum}
                              </TableCell>
                        </TableRow>
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
        
      </div>
    )
}
export default Trialbalance;