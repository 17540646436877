import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
  { id: 'AG code', label: 'AG code', minWidth: 170,span: false },
  { id: 'name', label: 'District', minWidth: 170,span: false },
  { id: 'code', label: 'State', minWidth: 170,span: false },
  { id: 'Country', label: 'Country', minWidth: 170,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    span: true
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  {
    district: 'palakkad',
    state: 'Kerala',
    country: 'India',
  }
];

function State() {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [btnloader, setBtnloader] = React.useState(false);
  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [distlist, setDistlist] = React.useState([]);
  const [countrylist, setCountryList] = React.useState([]);
  const [countryId, setCountryId] = React.useState("")
  const [statelist, setStateList] = React.useState([]);
  const [district, setDistrict] = React.useState("");
  const [districtId, setDistrictId] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");

  function setData(childData) {
    setToggle(childData)
  }
  useEffect(() => {
    getData()
    getCountry()
  }, [])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const countryChange = (event) => {
    setCountry(event.target.value);
    getState(event.target.value);
  };
  const stateChange = (event) => {
    setState(event.target.value);
  };
  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)

    setLoader(true)
    fetch(global.url + "viewAllDistrict", {
      method: "GET",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      if(responseJson.status == "false") {
        setDistlist(responseJson.data)
        setLoader(false)
      }
      else{
        setLoader(false)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}

const getCountry = () => {
  fetch(global.url + "viewCountry", {
    method: "GET",
    headers: {
        'Content-Type': 'application/json',
    },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      var data = responseJson.data
      if(responseJson.status == "false") {
        setCountryList(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getState = (id) => {
  var data = {
   'countryid' : id
  }
  fetch(global.url + "viewState", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      if(responseJson.status == "false") {
        setStateList(responseJson.data)
      }
      else{
        setStateList([])
      }
    })
    .catch((error)=>{
        console.log(error)
    })
}

const onAdd = () => {
  setModal(true)
  setCountry("")
  setState("")
  setDistrict("")
  setDistrictId("")
}

const onSave = () =>{
  setBtnloader(true)
  var data = {
    "state_id" :  state,
    "country_id" : country,
    "district_name" : district,
    "district_id" : districtId,
    "district_branchid" : userbranchid,
    "district_companyid" : usercompanyid,
    "user_id": userId
  }
  fetch(global.url + "updateDistrict", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      if(responseJson.status == "false") {
        getData()
        setModal(false)
        alert(responseJson.data)
        setBtnloader(false)
      }else {
        alert(responseJson.message)
        setBtnloader(false)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}

const editClick = (
  districtid,
  districtname,
  stateid,
  countryname
) => {
  setModal(true)
  setDistrictId(districtid)
  setDistrict(districtname)
  getState(countryname)
  setState(stateid)
  setCountry(countryname)
}

const deleteClick = (id) =>  {
  var data = {
    'district_id' : id,
    "user_id": userId
  }
    fetch(global.url + "deleteDistrict", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert("Deleted")
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>District List</p>
               <Button onClick={onAdd} variant="contained">Add</Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {distlist
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                          <TableCell >
                                  {row.district_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.district_name}
                              </TableCell>
                              <TableCell >
                                  {row.state_name}
                              </TableCell>
                              <TableCell >
                                  {row.country_name}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => editClick(
                                    row.district_id,
                                    row.district_name,
                                    row.district_state_id_fk,
                                    row.district_country_id_fk
                                  )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.district_id)}>
                                      <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={distlist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>


          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 District Add
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Country *</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={country}
                                label="Age"
                                onChange={countryChange}
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {countrylist.map((item, index) => (
                                  <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">State *</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={state}
                                label="Age"
                                onChange={stateChange}
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {statelist.map((item, index) => (
                                  <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField onChange={(e) => setDistrict(e.target.value)} value={district} id="outlined-basic" label="District Name *" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default State;
