
import React, {Component, useEffect, useState, useRef} from 'react';
import { Row, Col, Modal, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MultiSelect } from "react-multi-select-component";

import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";

function Fetchpage(props) {
    const inputRef = useRef();
    const resultsRef = useRef();
    const [modal, setModal] = useState(false)
  
    const [lookupproductlist, setLookupproductlist] = React.useState([]);

    useEffect(() => {
        if (props.hasResults) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown)
        }
      }, [props.hasResults]);

    function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {

          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
 
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      const lookupClick = () => {
        props.setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        var data = {
          "usertype": userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewAllproductbatchforItemlookup", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
      
              if(responseJson.status == "false"){
                setLookupproductlist(responseJson.data)
              }else{
                setLookupproductlist([])
              }
            
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const onfilter = (text, type) => {

      var company = JSON.parse(localStorage.getItem("company"))
      if(type == "intercode"){
          var data = {
              "type" : 'superadmin',
              "companyid" : company,
              "barcode" : text,
              "productname" : ""
          }
              }else{
          var data = {
              "type" : 'superadmin',
              "companyid" : company,
              "barcode" : "",
              "productname" : text
          }
      }
         

          fetch(global.url + "viewAllproductbatchforItemlookup", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
  
              if(responseJson.status == 'false'){
                  setLookupproductlist(responseJson.data)
              }else{
                  setLookupproductlist([])
                  
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      
  }

    const getbarcodename = (id) => {
  

    }
   
  return (
        <div>
            <Row>
                <Col sm={4} >
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="productcode"
                        onChange={props.updateform}
                        value={props.form.productcode} autoComplete="off" disabled id="outlined-basic" label="Product Combo Code" variant="outlined"  size="small"/>
                    </FormControl>


                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="refcode"
                        onChange={props.updateform}
                        value={props.form.refcode} autoComplete="off" id="outlined-basic" label="Reference code" variant="outlined"  size="small"/>
                    </FormControl>


                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="productname"
                        onChange={props.updateform}
                        value={props.form.productname} autoComplete="off" id="outlined-basic" label="Product Combo name" variant="outlined"  size="small"/>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="othername"
                        onChange={props.updateform}
                        value={props.form.othername} autoComplete="off" id="outlined-basic" label="Local Name" variant="outlined"  size="small"/>
                    </FormControl>

                    
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="printname"
                        onChange={props.updateform}
                        value={props.form.printname} autoComplete="off" id="outlined-basic" label="Print name" variant="outlined"  size="small"/>
                    </FormControl>


                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="comboprice"
                        onChange={props.updateform}
                        value={props.form.comboprice} autoComplete="off" id="outlined-basic" type="number" label="Combo price" variant="outlined"  size="small"/>
                    </FormControl>

                  </Col>
                  <Col sm={4}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="description"
                        onChange={props.updateform}
                        value={props.form.description} autoComplete="off" id="outlined-basic" label="Description" variant="outlined"  size="small"/>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">HSN</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.hsn}
                                label="Age"
                                onChange={props.hsnChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.hsnList.map((item, index) => (
                                  <MenuItem value={item.hsn_id}>{item.hsn_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">TAX</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.tax}
                                label="Age"
                                onChange={props.taxChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.taxList.map((item, index) => (
                                  <MenuItem value={item.taxmaster_id}>{item.taxmaster_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.itemGroup}
                                label="Age"
                                onChange={props.itemgroupChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.itemgroupList.map((item, index) => (
                                  <MenuItem value={item.itemgroup_id}>{item.itemgroup_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.brand}
                                label="Age"
                                onChange={props.brandChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.brandList.map((item, index) => (
                                  <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl> */}

                    
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="warranty"
                        onChange={props.updateform}
                        value={props.form.warranty} autoComplete="off" id="outlined-basic" label="Warranty" variant="outlined"  size="small"/>
                    </FormControl>

                    <Col xs={12} style={{marginTop: 20}}>
                          {/* <pre>{JSON.stringify(selected)}</pre> */}
                          <p>Branches</p>
                            <MultiSelect
                              options={props.branchlist}
                              value={props.selected}
                              onChange={props.setSelected}
                              labelledBy="Select"
                            />
                    </Col>
                    {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="stock"
                        onChange={props.updateform}
                        value={props.form.stock} type="number" autoComplete="off" id="outlined-basic" label="Combo stock" variant="outlined"  size="small"/>
                    </FormControl> */}
                  
                </Col>
                <Col sm={4}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.category}
                                label="Age"
                                onChange={props.categoryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.categoryList.map((item, index) => (
                                  <MenuItem value={item.category_id}>{item.category_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sub Categeory</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.subCategory}
                                label="Age"
                                onChange={props.subcategoryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.subcatlist.map((item, index) => (
                                  <MenuItem value={item.subCategory_id}>{item.subCategory_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Base unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.baseUnit}
                                label="Age"
                                onChange={props.baseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Purchase unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.purchaseUnit}
                                label="Age"
                                onChange={props.purchaseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sale unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.salesUnit}
                                label="Age"
                                onChange={props.salesunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Price level</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.defaultPrice}
                                label="Age"
                                onChange={props.defaultpriceChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.priceList.map((item, index) => (
                                  <MenuItem value={item.pricelevel_id}>{item.pricelevel_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                </Col>
            </Row>

            <div>
              <Row>
                <Col md={2}>
                  <Button onClick={() => lookupClick()} className="retail-btn" style={{backgroundColor: '#2076d2', borderRadius: 8, marginTop: 20, width: '100%', marginLeft: 15, marginLeft: 15,  fontWeight: 'bold',}} variant="contained">Item Lookup</Button>
                </Col>
              </Row>
            </div>

            <Row >
                <Col sm={5} className="productbatchborder">
                    <label style={{fontWeight: 'bold'}}>Combo Products</label>
                    {
                    props.combolistloader ? 
                    <Loader /> :
                    props.tableRow.map((item, index) => {
                            return (
                                <Row style={{marginTop: 10}}>
                                    <Col md={5}>
                                    <input  onFocus={() => props.indexChange(index)} style={{width: '100%'}} placeholder="Reference code" autocomplete="off" ref={inputRef} type="text" name="query" value={item.product_code} onChange={(event) => props.handleOnChange(event, index, "code")} />
                                        {props.hasResults && props.selectedIndex == index && (
                                            <div className="autocomplete">
                                                <ul ref={resultsRef} className="people scrolling-div">
                                                    {props.productlist.map(item => {
                                                    return (
                                                      
                                                        <li key={item.url}>
                                                            <a href="javascript:void(0)" onClick={() => props.listClickcode(
                                                                index, 
                                                                item.products_id,
                                                                item.product_code,
                                                                item.product_name,
                                                                item.product_refcode
                                                                )}>
                                                                {item.product_refcode} - { item.product_name }
                                                            </a>
                                                        </li>
                                                     ) })}
                                                </ul>
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={2}>
                                        <input style={{width: '100%'}} placeholder="Qty" type="text" autocomplete="off" ref={inputRef} name="query" value={item.product_quantity} onChange={(event) => props.stockChange(event, index, "code")} />
                                    </Col>
                                    <Col md={3}>
                                        <label style={{fontSize: 10,}} >{item.product_name}</label>
                                    </Col>
                                    <Col md={2}>
                                        <a href="javascript:void(0)" onClick={() => props.deleteCombo(index)}>
                                            <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                        </a> 
                                    </Col>
                                    
                                </Row>
                            )
                            })
                        }
                        <Row style={{marginTop:10}}>
                            <Col sm={2}>
                                    <Button onClick={props.addtablerow} style={{float: 'right', marginBottom: 20}}  variant="contained">Add</Button>
                              </Col>
                        </Row>
                </Col>
                <Col sm={3} style={{marginTop: 30}}>
                               
                </Col>
            </Row>

            {/* lookup */}
           <Modal
              size="lg"
              show={props.productmodal}
              onHide={() => props.setProductModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Product List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                      <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined"  size="small" label="Barcode"/>
                      </Col>
                      <Col xs={12} md={5}>
                            <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined"  size="small" label="Product Name"/>
                      </Col>
                      {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                  </Row>
                  <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Ref Code</th>
                                <th>Barcode</th>
                                <th>Batch Code</th>
                                <th>Product Name</th>
                                <th>Stock</th>
                                <th>Sales Price</th>
                            </tr>
                        </thead>
                        {/* {productlistitem.length == 0 ? */}
                            <tbody>
                                {lookupproductlist?.map((column) => (
                                    <tr>
                                        <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode)}>{column.product_refcode}</td>
                                        <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode)}>{column.pb_inter_barcode}</td>
                                         <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode)}>{column.pb_batch}</td>
                                        <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode)}>{column.product_name}</td>
                                        <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode)}>{column.pb_stock}</td>
                                        <td style={{cursor: 'pointer'}} onClick={() => props.getbarcodename(column.product_refcode)}>{column.pb_salesrate}</td>
                                    </tr>
                                    ))
                                } 
                            </tbody>
                        </Table>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default Fetchpage;
