
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

function WizardFormFirstPage(props) {
  const [bank, setBank] = useState("")

   
  return (
        <div>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bank</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.bank}
                                    label="Age"
                                    onChange={props.bankChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.bankList.map((item, index) => (
                                    <MenuItem value={item.bank_id}>{item.bank_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                        <Col md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField onChange={(e) => props.setBankcode(e.target.value)} value={props.bankCode} id="outlined-basic" label="Bank Code" variant="outlined"  size="small"/>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                        <Col md={6} >
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Group</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.group}
                                    label="Age"
                                    onChange={props.groupChange}
                                    size="small"
                                    
                                    >
                                    <MenuItem value="">
                                    <em>None</em>
                                    </MenuItem>
                                    {props.groupList.map((item, index) => (
                                        <MenuItem value={item.group_id}>{item.group_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                        
                        <Col md={6} >
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField type="number" onChange={(e) => props.setOpening(e.target.value)} value={props.opening} id="outlined-basic" label="Opening" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={12}> 
                            <p style={{fontSize: 20, fontWeight: 500}}>Bank Account Details</p>
                        </Col>
                        <Col md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField onChange={(e) => props.setAccountholder(e.target.value)} value={props.accountHolder} id="outlined-basic" label="A/c Holder Name" variant="outlined"  size="small"/>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                        <Col md={6} >
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField onChange={(e) => props.setAccountno(e.target.value)} value={props.accountNo} id="outlined-basic" label="Account No" variant="outlined"  size="small"/>
                            </FormControl>
                         
                        </Col>
                        <Col md={6} >
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField onChange={(e) => props.setIfsc(e.target.value)} value={props.ifsc} id="outlined-basic" label="IFSC" variant="outlined"  size="small"/>
                            </FormControl>
                       
                        </Col>
                        <Col md={6} >
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField onChange={(e) => props.setBranchname(e.target.value)} value={props.branchName} id="outlined-basic" label="Branch Name" variant="outlined"  size="small"/>
                            </FormControl>
                            <label style={{fontSize: 10}}>Required</label>
                        </Col>
                        <Col md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Account Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={props.accountType}
                                        label="Age"
                                        onChange={props.typeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="Savings">Savings</MenuItem>
                                    <MenuItem value="Current">Current</MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col xs={6} >
                            <FormControl sx={{ mt: 1}} component="fieldset">
                                <RadioGroup
                                    defaultValue="credit"
                                    name="radio-buttons-group"
                                    onClick={props.radioChange}
                                >
                                    <FormControlLabel value="credit"  control={<Radio checked={props.crdr == 0?  false: true}/>} label="Credit" color = '#999'  />
                                    <FormControlLabel value="debit" color= '#999' control={<Radio checked={props.crdr == 0?  true: false}/>} label="Debit" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
                    <Col md={6}>
                        <p style={{fontSize: 20, fontWeight: 500}}>Mailing Details</p>
                        <Row>
                            <Col  md={6}>
                                <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <TextField name="suppliername"
                                        id="outlined-basic" label="Name" variant="outlined"  size="small"/>
                                    </FormControl>
                                    <label style={{fontSize: 10}}>Required</label>
                                </Col>
                                <Col  md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField name="mobile"
                                        onChange={(e) => props.setContact(e.target.value)} value={props.contact}
                                     id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                                    </FormControl>
                                    <label style={{fontSize: 10}}>Required</label>
                                </Col>
                                <Col md={12}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <TextareaAutosize 
                                        minRows={3}
                                        placeholder="Adddress"
                                        style={{background: 'transparent', width: '100%'}}
                                        onChange={(e) => props.setddress(e.target.value)} 
                                        value={props.address}
                                    />
                                    </FormControl>
                                    <label style={{fontSize: 10}}>Required</label>
                                </Col>
                                <Col xs={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Country</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={props.country}
                                                label="Age"
                                                onChange={props.countryChange}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props.countryList.map((item, index) => (
                                                <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                                ))}
                                            </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={props.state}
                                                label="Age"
                                                onChange={props.stateChange}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props.stateList.map((item, index) => (
                                                <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                                ))}
                                            </Select>
                                    </FormControl>
                                </Col>
                                <Col md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                                    </FormControl>
                                    <label style={{fontSize: 10}}>Required</label>
                                </Col>
                                <Col md={6} >
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField id="outlined-basic" label="Pincode" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                        </Row>
                    </Col>                  
            </Row>
        </div>
  );
}

export default WizardFormFirstPage;
